import { Popover, Transition } from "@headlessui/react";
import Prices from "components/Prices";
// import { Product } from "data/data";
import { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { RootState } from "components/rtk/store";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "components/rtk/slices/CartSlice";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { useTranslation } from "react-i18next";
import "./customsvg.css";
export default function CartDropdown() {
  const dispatch = useDispatch();

  const cartItems: any = useSelector((state: RootState) => state.cart);
  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();
  const renderProduct = (item: any, index: number, close: () => void) => {
    const { totalPrice, product_id, quantity } = item;
    // const colorImage = item.colorLab?.image;

    // const colorImage = item?.optionsSelectd?.find((item:any) => item.image)?.image;

    // const productName = item?.product?.name ?? "";
    // const processorBrand = item?.processor?.brand ?? "";
    // const processorArchitecture = item?.processor?.architecture ?? "";
    // const thermalSolution = item?.processor?.thermal_solution ?? "";
    // const ramType = `${item?.ram_type?.name ?? ""} ${
    //   item?.ram_type?.capacity ?? ""
    // }GB`;
    // const color = item?.color?.name ?? "";
    // const displaySize = item?.display_size?.display_size ?? "";
    // const hardDriveCapacity = `${item?.hard_drive?.capacity ?? ""}GB`;
    // const resolution = `${item?.resolution?.resolution_type ?? ""} ${
    //   item?.resolution?.resolution_width ?? ""
    // }*${item?.resolution?.resolution_height ?? ""}`;
    // const name = `${productName}, ${processorBrand}, ${processorArchitecture}, ${thermalSolution}, ${ramType}, ${color}, ${displaySize}, ${hardDriveCapacity}, ${resolution}`;

    const name = item?.data?.name;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    let optionName = "";
    item?.optionsSelectd?.forEach((item: any) => {
      optionName += "," + item.name + ": " + item.value || "";
    });

    return (
      <div key={index} className="flex py-5 last:pb-0 gap-2">
        <div className="relative h-14 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={
              item?.image && `https://sellxa.com/backend/${item?.image[0]?.url}`
            }
            alt={"productImage"}
            className="h-full w-full object-contain object-center"
          />
          <Link
            onClick={close}
            className="absolute inset-0"
            to={`/${lang}/${item?.data?.main_category?.name}/${item?.data?.link}`}
          />
        </div>

        <div className="flex flex-1 flex-col gap-2">
          {/* <div> */}
          <div className="flex justify-between ">
            <div className="w-[70%]">
              <h3 className="text-[12px] leading-5 font-semibold ">
                <Link
                  onClick={close}
                  to={`/${lang}/${item?.data?.main_category?.name}/${item?.data?.link}`}
                >
                  {name}
                </Link>
              </h3>
              {/* <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>{`Natural`}</span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{"15.6 inch"}</span>
                </p> */}
            </div>
            <div className="flex-1">
              <Prices price={totalPrice} className="mt-0.5" />
            </div>
          </div>
          {/* </div> */}
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400">{`${t(
              "qty"
            )} ${quantity}`}</p>

            <div className="flex">
              <button
                type="button"
                className="font-medium text-primary-6000 dark:text-primary-500 "
                onClick={() => dispatch(cartActions.deleteItem(item))}
              >
                {t("remove")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`
                ${open ? "" : "text-opacity-90"}
                 group w-10 h-10 sm:w-12 sm:h-12 dark:hover:bg-slate-800 rounded-full inline-flex items-center justify-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
          >
            <div className="w-4 h-4 md:w-4 md:h-4 flex items-center justify-center bg-black absolute md:top-[.4rem] top-0 md:right-1 -right-1.5 rounded-full text-[12px] md:text-[10px] leading-none text-white font-medium">
              {/* <div className="w-6 h-6 md:w-3 md:h-3 flex items-center justify-center bg-black rounded-full text-[12px] md:text-[10px] text-white font-medium"> */}
              <span className="mt-[1px]">{cartItems?.cartItems?.length}</span>
              {/* </div> */}
            </div>
            {/* <svg
              className="w-6 h-6"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 2H3.74001C4.82001 2 5.67 2.93 5.58 4L4.75 13.96C4.61 15.59 5.89999 16.99 7.53999 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82001"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16.25 22C16.9404 22 17.5 21.4404 17.5 20.75C17.5 20.0596 16.9404 19.5 16.25 19.5C15.5596 19.5 15 20.0596 15 20.75C15 21.4404 15.5596 22 16.25 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.25 22C8.94036 22 9.5 21.4404 9.5 20.75C9.5 20.0596 8.94036 19.5 8.25 19.5C7.55964 19.5 7 20.0596 7 20.75C7 21.4404 7.55964 22 8.25 22Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 8H21"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg> */}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 6L9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7V6" stroke="#141718" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M15.6115 3H8.38848C6.43313 3 4.76436 4.41365 4.44291 6.3424L2.77624 16.3424C2.36988 18.7805 4.25006 21 6.72182 21H17.2781C19.7499 21 21.6301 18.7805 21.2237 16.3424L19.557 6.3424C19.2356 4.41365 17.5668 3 15.6115 3Z" stroke="#141718" stroke-width="1.5" stroke-linejoin="round" />
            </svg>

            {/* <svg
              className="custom-svg"
              width="44px"
              height="44px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M7.5 18C8.32843 18 9 18.6716 9 19.5C9 20.3284 8.32843 21 7.5 21C6.67157 21 6 20.3284 6 19.5C6 18.6716 6.67157 18 7.5 18Z"
                  stroke="#000"
                  strokeWidth="1.5"
                ></path>{" "}
                <path
                  d="M16.5 18.0001C17.3284 18.0001 18 18.6716 18 19.5001C18 20.3285 17.3284 21.0001 16.5 21.0001C15.6716 21.0001 15 20.3285 15 19.5001C15 18.6716 15.6716 18.0001 16.5 18.0001Z"
                  stroke="#000"
                  strokeWidth="1.5"
                ></path>{" "}
                <path
                  d="M2 3L2.26121 3.09184C3.5628 3.54945 4.2136 3.77826 4.58584 4.32298C4.95808 4.86771 4.95808 5.59126 4.95808 7.03836V9.76C4.95808 12.7016 5.02132 13.6723 5.88772 14.5862C6.75412 15.5 8.14857 15.5 10.9375 15.5H12M16.2404 15.5C17.8014 15.5 18.5819 15.5 19.1336 15.0504C19.6853 14.6008 19.8429 13.8364 20.158 12.3075L20.6578 9.88275C21.0049 8.14369 21.1784 7.27417 20.7345 6.69708C20.2906 6.12 18.7738 6.12 17.0888 6.12H11.0235M4.95808 6.12H7"
                  stroke="#000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                ></path>{" "}
              </g>
            </svg> */}
            <Link className="block md:hidden absolute inset-0" to={"/cart"} />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel
              className={`hidden md:block absolute z-10 w-screen max-w-xs sm:max-w-md px-4 mt-3.5 ${lang === "ar" ? "-left-0" : "-right-28 sm:right-0"
                }  sm:px-0`}
            >
              <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black/5 dark:ring-white/10">
                <div className="relative bg-white dark:bg-neutral-800">
                  <div className="max-h-[60vh] py-5 px-3 overflow-y-auto hiddenScrollbar">
                    <h3 className="text-xl font-semibold">
                      {t("shoppingCart")}
                    </h3>
                    <div className="divide-y divide-slate-100 dark:divide-slate-700">
                      {cartItems.cartItems.map((item: any, index: any) =>
                        renderProduct(item, index, close)
                      )}
                    </div>
                  </div>
                  <div className="bg-neutral-50 dark:bg-slate-900 p-5">
                    <p className="flex justify-between font-semibold text-slate-900 dark:text-slate-100">
                      <span>
                        <span>{t("Subtotal")}</span>
                        <span className="block text-sm text-slate-500 dark:text-slate-400 font-normal">
                          {t("ShippingCalculated")}
                        </span>
                      </span>
                      <span className="">{cartItems.totalAmount} {t("currency")}  </span>
                    </p>
                    <div className="flex space-x-2 mt-5 gap-2">
                      <ButtonSecondary
                        href={`/${lang}/cart`}
                        className="flex-1 border border-slate-200 dark:border-slate-700"
                        onClick={close}
                      >
                        {t("Viewcart")}
                      </ButtonSecondary>
                      <ButtonPrimary
                        href={`/${lang}/checkout`}
                        onClick={close}
                        className="flex-1 font-normal"
                      >
                        {t("Checkout")}
                      </ButtonPrimary>
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
