import React, { useContext } from "react";
import { Link } from "react-router-dom";
import logoImgDark from "images/logo.png";
import logoImg from "images/logo-light.png";
import logoLightImg from "images/logo-light.png";
import { LanguageContext } from "components/contextApi/ChangeLanguage";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImgDark,
  imgLight = logoLightImg,
  className = "flex-shrink-0",
}) => {
  const { lang } = useContext(LanguageContext);
  return (
    <Link
      to={`/${lang}`}
      className={`ttnc-logo inline-block text-slate-600 ${className}`}
    >
      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {img ? (
        <img
          className={`block max-h-6 sm:max-h-7 ${imgLight ? "dark:hidden" : ""
            }`}
          src={img}
          alt="Logo"
          width={"98"}
          height={"30"}
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-4 sm:max-h-7 dark:block"
          src={imgLight}
          alt="Logo-Light"
          width={"98"}
          height={"30"}
        />
      )}
    </Link>
  );
};

export default Logo;
