import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest } from "components/utils/fetchMethods";

export const getDataFiltered = createAsyncThunk(
  'products/getDataFiltered',
  async ({ params, lang }, { rejectWithValue }) => {
    try {
      const response = await getRequest(params, '/products/filter', lang);
      if (!response) {
        throw new Error(response.message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const productsSlice = createSlice({
  name: 'products',
  initialState: {
    products: [],
    loading: false,
    filteredProducts: [],
    currentPage: 1,
    rangePrices: [0, 0],
    qty: 1,
    error: null,
  },
  reducers: {
    setRangePrices(state, action) {
      state.rangePrices = action.payload;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setQty(state, action) {
      state.qty = action.payload;
    },
    setFilteredProducts(state, action) {
      state.filteredProducts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataFiltered.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDataFiltered.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.filteredProducts;
        state.filteredProducts = action.payload.filteredProducts.data;
      })
      .addCase(getDataFiltered.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setRangePrices, setCurrentPage, setQty, setFilteredProducts } = productsSlice.actions;

export default productsSlice.reducer;
