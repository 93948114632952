// import { useContext, useEffect, useRef, useState, useCallback } from "react";
// import HeaderFilterSection from "components/HeaderFilterSection";
// import HeaderFilterSearchPage from "components/HeaderFilterSearchPage.js";
// import ProductCard from "components/ProductCard";
// import { usePriceRange } from "components/contextApi/PriceRangeProvider";
// import { useTranslation } from "react-i18next";
// import { LanguageContext } from "components/contextApi/ChangeLanguage";
// import { useLocation } from "react-router-dom";

// // Debounce function to limit the rate of fetchData calls
// const debounce = (func, delay) => {
//   let timer;
//   return function (...args) {
//     const context = this;
//     if (timer) clearTimeout(timer);
//     timer = setTimeout(() => func.apply(context, args), delay);
//   };
// };

// const SectionGridFeatureItems = () => {
//   const isMounted = useRef(false);
//   const { getDatafiltered } = usePriceRange();
//   const path = useLocation();
//   const pathName = path.pathname;
//   const {
//     products,
//     currentPage,
//     setCurrentPage,
//     load,
//     setLoad,
//     setProducts,
//     setFilteredProducts,
//     filteredProducts,
//     selectedColor,
//     selectedSizes,
//     selectedBrands,
//     selectedMainId,
//     selectedSubId,
//     selectedHard,
//     selectedCapacities,
//   } = usePriceRange();

//   const { lang } = useContext(LanguageContext);
//   const { t } = useTranslation();
//   const [hasMore, setHasMore] = useState(true);
//   const observer = useRef();

//   // Combined filters into a single state object
//   const filters = {
//     color_id: selectedColor,
//     display_size: selectedSizes,
//     manufacturer_id: selectedBrands,
//     main_category_id: selectedMainId,
//     sub_category_id: selectedSubId,
//     hard_type: selectedHard,
//     hard_capacity: selectedCapacities,
//   };

//   const lastProductElementRef = useCallback(
//     (node) => {
//       if (load) return;
//       if (observer.current) observer.current.disconnect();
//       observer.current = new IntersectionObserver((entries) => {
//         if (entries[0].isIntersecting && hasMore && !load) {
//           setCurrentPage((prevPage) => prevPage + 1);
//         }
//       });
//       if (node) observer.current.observe(node);
//     },
//     [load, hasMore]
//   );

//   // Effect for handling filter changes
//   useEffect(() => {
//     // Reset state on filter change
//     setFilteredProducts([]); // Clear filtered products for new filter criteria
//     setProducts({}); // Clear products to prevent old data showing up during fetching
//     setCurrentPage(1); // Reset page number
//     setHasMore(true); // Reset hasMore flag
//     fetchData(true); // Fetch data with new filters
//   }, [
//     selectedColor,
//     selectedSizes,
//     selectedBrands,
//     selectedMainId,
//     selectedSubId,
//     selectedHard,
//     selectedCapacities,
//   ]); // Dependencies include all selected filters

//   // Fetch data function, accepting a flag to distinguish between filter and scroll
//   const fetchData = useCallback(
//     async (isFiltering = false) => {
//       try {
//         setLoad(true);
//         const filterParams = {
//           ...filters,
//           paginate: 8,
//         };

//         // If not filtering (infinite scroll), add page parameter
//         if (!isFiltering) {
//           filterParams.page = currentPage;
//         }

//         const res = await getDatafiltered(filterParams, lang);

//         if (res && res.data) {
//           if (isFiltering) {
//             // If filtering, replace products with fetched data
//             setProducts(res);
//             setFilteredProducts(res.data);
//           } else {
//             // For infinite scroll, append new data
//             setProducts((prevProducts) => ({
//               ...res,
//               data: [...(prevProducts?.data || []), ...res.data],
//             }));
//           }
//           setHasMore(res.data.length > 0); // Update hasMore based on fetched data
//         }
//         setLoad(false);
//       } catch (error) {
//         setLoad(false);
//         console.log(error.message);
//       }
//     },
//     [filters, currentPage, lang]
//   );

//   // Debounced version of fetchData
//   const debouncedFetchData = useCallback(debounce(fetchData, 300), [fetchData]);

//   // Effect to fetch data for infinite scrolling when currentPage changes
//   useEffect(() => {
//     if (currentPage > 1) {
//       fetchData(false);
//     }
//   }, [currentPage]); // Trigger fetch on page change

//   return (
//     <div
//       className="nc-SectionGridFeatureItems relative"
//       style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
//     >
//       <HeaderFilterSearchPage />
//       <div className={`grid gap-4 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}>
//         {filteredProducts?.length > 0 &&
//           filteredProducts.map((item, index) => {
//             if (filteredProducts.length === index + 1) {
//               return (
//                 <div ref={lastProductElementRef} key={index}>
//                   <ProductCard data={item} />
//                 </div>
//               );
//             } else {
//               return <ProductCard data={item} key={index} />;
//             }
//           })}
//       </div>
//       {load && (
//         <div className="flex items-center justify-center h-24 bg-white bg-opacity-80 inset-0 z-50">
//           <div className="w-16 h-16 border-4 border-[#FC5E02] border-solid border-t-transparent rounded-full animate-spin"></div>
//         </div>
//       )}
//       {!hasMore && <p className="text-center mt-6">no more</p>}
//     </div>
//   );
// };

// export default SectionGridFeatureItems;

import { useContext, useEffect, useRef, useState, useCallback } from "react";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage.js";
import ProductCard from "components/ProductCard";
import { usePriceRange } from "components/contextApi/PriceRangeProvider";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { useLocation } from "react-router-dom";

const ITEMS_PER_PAGE = 8;

const SectionGridFeatureItems = () => {
  const {
    getDatafiltered,
    products,
    setProducts,
    setFilteredProducts,
    filteredProducts,
    selectedColor,
    selectedSizes,
    selectedBrands,
    selectedMainId,
    selectedSubId,
    selectedHard,
    selectedCapacities,
  } = usePriceRange();

  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();
  const path = useLocation();

  // State management
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [fetchTrigger, setFetchTrigger] = useState(false);

  // Refs
  const intObserver = useRef(null);
  const lastItemRef = useRef(null);

  // Combine all filters into a single object
  const filters = {
    color_id: selectedColor,
    display_size: selectedSizes,
    manufacturer_id: selectedBrands,
    main_category_id: selectedMainId,
    sub_category_id: selectedSubId,
    hard_type: selectedHard,
    hard_capacity: selectedCapacities,
  };

  // Reset function for filter changes
  const resetState = useCallback(() => {
    setFilteredProducts([]);
    setProducts({});
    setPageNumber(1);
    setHasMore(true);
    setError(null);
    setFetchTrigger(true); // Trigger initial fetch after reset
  }, [setFilteredProducts, setProducts]);

  // Fetch data function
  const fetchData = useCallback(async () => {
    if (isLoading || !fetchTrigger) return; // Prevent fetch if already loading or not triggered

    try {
      setIsLoading(true);
      setError(null);

      const filterParams = {
        ...filters,
        paginate: ITEMS_PER_PAGE,
        page: pageNumber,
      };

      const response = await getDatafiltered(filterParams, lang);

      if (!response?.data) {
        throw new Error("No data received from server");
      }

      setProducts((prev) => {
        if (pageNumber === 1) {
          return response;
        }
        return {
          ...response,
          data: [...(prev?.data || []), ...response.data],
        };
      });

      setFilteredProducts((prev) => {
        if (pageNumber === 1) {
          return response.data;
        }
        return [...prev, ...response.data];
      });

      setHasMore(response.data.length === ITEMS_PER_PAGE);
      setFetchTrigger(false); // Reset trigger after successful fetch
    } catch (err) {
      setError(err.message);
      setFetchTrigger(false); // Reset trigger on error
    } finally {
      setIsLoading(false);
    }
  }, [
    filters,
    pageNumber,
    lang,
    getDatafiltered,
    setProducts,
    setFilteredProducts,
    isLoading,
    fetchTrigger,
  ]);

  // Intersection Observer callback
  const lastItemCallback = useCallback(
    (node) => {
      if (isLoading) return;

      if (intObserver.current) {
        intObserver.current.disconnect();
      }

      intObserver.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore && !isLoading) {
            setPageNumber((prev) => prev + 1);
            setFetchTrigger(true); // Trigger fetch when intersection occurs
          }
        },
        {
          threshold: 0.5,
          rootMargin: "100px",
        }
      );

      if (node) {
        lastItemRef.current = node;
        intObserver.current.observe(node);
      }
    },
    [isLoading, hasMore]
  );

  // Effect for filter changes
  useEffect(() => {
    resetState();
  }, [
    selectedColor,
    selectedSizes,
    selectedBrands,
    selectedMainId,
    selectedSubId,
    selectedHard,
    selectedCapacities,
    resetState,
  ]);

  // Effect for fetching data
  useEffect(() => {
    if (fetchTrigger) {
      fetchData();
    }
  }, [fetchTrigger, fetchData]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (intObserver.current) {
        intObserver.current.disconnect();
      }
    };
  }, []);

  return (
    <div
      className="nc-SectionGridFeatureItems relative"
      style={{ direction: lang === "ar" ? "rtl" : "ltr" }}
    >
      <HeaderFilterSearchPage />

      {error && (
        <div className="text-red-500 text-center py-4">
          {t("Error loading products")}: {error}
        </div>
      )}

      <div className="grid gap-4 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {filteredProducts?.map((item, index) => (
          <div
            key={item.id || index}
            ref={
              index === filteredProducts.length - 1 ? lastItemCallback : null
            }
          >
            <ProductCard data={item} />
          </div>
        ))}
      </div>

      {isLoading && (
        <div className="flex items-center justify-center h-24 bg-white bg-opacity-80 inset-0 z-50">
          <div className="w-16 h-16 border-4 border-[#FC5E02] border-solid border-t-transparent rounded-full animate-spin" />
        </div>
      )}

      {!isLoading && !hasMore && filteredProducts?.length > 0 && (
        <p className="text-center mt-6">{t("No more products")}</p>
      )}

      {!isLoading && filteredProducts?.length === 0 && (
        <p className="text-center mt-6">{t("No products found")}</p>
      )}
    </div>
  );
};

export default SectionGridFeatureItems;
