import React, { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "./contextApi/ChangeLanguage";

export interface PricesProps {
  className?: string;
  price?: number;
  contentClass?: string;
}

const Prices: FC<PricesProps> = ({
  className = "",
  price = 33,
  contentClass = "py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium",
}) => {
  const { t } = useTranslation()
  const { lang } = useContext(LanguageContext)
  return (
    <div className={`${className}`}>
      <div
        className={`flex items-center gap-2 md:justify-between  ${contentClass}`}
      >
        <span className="text-lg font-bold !leading-none " style={{ direction: `${lang === 'ar' ? 'rtl' : 'ltr'}` }}>
          {price.toFixed(0)}
        </span>
        <span className="text-lg font-bold !leading-none " style={{ direction: `${lang === 'ar' ? 'rtl' : 'ltr'}` }}>
          {t("EGP")}
        </span>
      </div>
    </div>
  );
};

export default Prices;
