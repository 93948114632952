import React, { FC } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import { Product } from "data/data";
import { useDispatch } from 'react-redux';
import { cartActions } from "./rtk/slices/CartSlice";

export interface NcInputNumberProps {
  className?: string;
  defaultValue?: number;
  min?: number;
  max?: number;
  onChange?: (value: number) => void;
  label?: string;
  desc?: string;
  item?: any;
  productInState?: any;
}

const NcInputNumber: FC<NcInputNumberProps> = ({
  className = "",
  defaultValue = 1,
  min = 1,
  max = 99,
  onChange,
  label,
  desc,
  item,
  productInState
}) => {
  const dispatch = useDispatch();

  const increase = (item: any) => {
    dispatch(cartActions.increaseItem(item));
  };

  const decrease = (item: Product) => {
    dispatch(cartActions.decreaseItem(item));
  };

  const handleClickDecrement = () => {
    item && decrease(item);
    productInState && decrease(productInState);
  };

  const handleClickIncrement = () => {
    item && increase(item);
    productInState && increase(productInState);
  };

  const renderLabel = () => {
    return (
      <div className="flex flex-col">
        <span className="font-medium text-neutral-800 dark:text-neutral-200">
          {label}
        </span>
        {desc && (
          <span className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">
            {desc}
          </span>
        )}
      </div>
    );
  };

  return (
    <div className={`nc-NcInputNumber flex flex-col items-center ${className}`}>
      {label && renderLabel()}

      <div className="flex items-center justify-between w-20 h-8 border border-1 border-[#D1D5DB] dark:border-neutral-500 bg-white dark:bg-neutral-900 rounded-md">
        <button
          className="w-8 h-8 flex items-center justify-center bg-transparent dark:bg-neutral-900 border-0 focus:outline-none disabled:opacity-50 disabled:cursor-default"
          type="button"
          onClick={handleClickDecrement}
          disabled={item?.quantity <= 1}
        >
          <MinusIcon className="w-4 h-4" />
        </button>
        <span className="flex-1 text-center select-none">
          {item?.quantity} {productInState?.quantity && productInState?.quantity}
        </span>
        <button
          className="w-8 h-8 flex items-center justify-center bg- transparent dark:bg-neutral-900 border-0 focus:outline-none disabled:opacity-50 disabled:cursor-default"
          type="button"
          onClick={handleClickIncrement}
          disabled={item?.quantity >= item?.stock?.quantity}
        >
          <PlusIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default NcInputNumber;
