import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { AuthContext } from "components/contextApi/AuthContext";
import axios from "axios";
import toast from "react-hot-toast";
import { Order } from "components/contextApi/DataOrderContext";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { useTranslation } from "react-i18next";

const PageCodeInput = () => {
  const { userId, setTokenReset, email } = useContext(AuthContext);
  const [code, setCode] = useState();
  const navigate = useNavigate();
  const { baseUrl } = useContext(Order);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${baseUrl}/users/reset/check`,
        {
          user_id: userId,
          code,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      if (res.data.status === 200) {
        toast.success(res.data.message);
        setTokenReset(res.data.token);
        navigate("/NewPassword");
      } else if (res.data.status === 100) {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const { lang } = useContext(LanguageContext);
  const {t} = useTranslation()
  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || sellxa</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="mt-20 mb-10 flex items-center text-3xl leading-[115%] md:text-4xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("EmailSent")}
        </h2>
        <h2 className=" mb-10 flex items-center leading-[115%] md:leading-[115%] md:text-2xl font-semibold justify-center">
          {/* your info: <br/> */}
          {t("youremail")}: {email}
        </h2>
        <div className="max-w-xs md:max-w-md mx-auto space-y-6">
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              {/* <span className="text-neutral-800 dark:text-neutral-200">
                    Code
                  </span> */}
              <Input
                type="number"
                placeholder="Enter the Code Sent to Your Email."
                className="mt-1"
                onChange={(e) => setCode(e.target.value)}
              />
            </label>
            <ButtonPrimary type="submit">{t("Continue")}</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t("login")} {` `}
            <Link className="text-green-600" to={`/${lang}/login`}>
            {t("login")}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageCodeInput;
