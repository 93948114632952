import React, { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";
import { Order } from "components/contextApi/DataOrderContext";
import axios from "axios";
import { AuthContext } from "components/contextApi/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PagesContext } from "components/contextApi/MetaContext";
import { LanguageContext } from "components/contextApi/ChangeLanguage";

// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
// import SectionPromo1 from "components/SectionPromo1";

// export interface PageContactProps {
//   className?: string;
// }

const PageContact = ({ className = "" }) => {
  const { t } = useTranslation();
  const recaptcha = useRef();
  const { userInfo, checkLogin } = useContext(AuthContext);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const user_agent = navigator.userAgent;
  const platform = navigator.platform;

  // const [apiData, setApiData] = useState(null);

  // useEffect(()=>{
  //   const getUserData = async()=>{
  //     try {
  //       const response = await axios.get('https://ip-api.org/json/');
  //       console.log(response);
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   }
  //   getUserData()
  // },[])
  const navigate = useNavigate();
  const { baseUrl } = useContext(Order);
  const contact = async () => {
    try {
      const response = await axios.post(`${baseUrl}/contact`, {
        name,
        email,
        description: message,
        user_agent,
        platform,
        ip: "",
        location: "",
        country: "",
        subject: "empty subject",
      });
      toast.success(response.data.message);
      navigate("/");
    } catch (error) {
      toast.error(error.message || "An error occurred");
    }
  };

  async function submitForm(event) {
    event.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      toast.error(t("PleaseVerifyNotRobot"));
    } else {
      contact();
    }
  }

  useEffect(() => {
    userInfo && setName(userInfo?.name);
    setEmail(userInfo?.email);
  }, [userInfo, checkLogin]);

  const info = [
    {
      title: `🗺 ${t("ADDRESS")}`,
      desc: t("addressDetails"),
    },
    {
      title: `💌 ${t("EMAIL")}`,
      desc: "sellxa.store@gmail.com",
      href: 'mailto:sellxa.store@gmail.com'
    },
    {
      title: `☎ ${t("PHONE")}`,
      desc: "01040324155",
      href: "tel:+201040324155",
    },
  ];
  const { GetPage, dataPages } = useContext(PagesContext);
  const { lang } = useContext(LanguageContext);
  useEffect(() => {
    GetPage({ page_id: 4 }, lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
      style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
    >
      <Helmet>
        <title>{dataPages?.meta_title ?? ""} </title>
        <meta
          name="description"
          content={
            dataPages?.meta_description ??
            '"contact with sellxa team now we can help you"'
          }
        />
        <link rel="Canonical" href="/Contact-us" />
      </Helmet>
      <div className="">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("Contact")}
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) =>
                item?.href ? (
                  <a href={item?.href} className="block">
                    <div key={index}>
                      <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                        {item.title}
                      </h3>
                      <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                        {item.desc}
                      </span>
                    </div>
                  </a>
                ) : (
                  <div key={index}>
                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                      {item.title}
                    </h3>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                      {item.desc}
                    </span>
                  </div>
                )
              )}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 {t("SOCIALS")}
                </h3>
                <SocialsList className="mt-2" />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={submitForm}>
                <label className="block">
                  <Label>{t("Fullname")}</Label>

                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    defaultValue={userInfo && userInfo?.name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </label>
                <label className="block">
                  <Label>{t("EmailAddress")}</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    defaultValue={userInfo && userInfo.email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                <label className="block">
                  <Label>{t("Message")}</Label>

                  <Textarea
                    className="mt-1"
                    rows={6}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit">
                    {t("sendMessage")}
                  </ButtonPrimary>
                </div>
                <div>
                  <ReCAPTCHA
                    ref={recaptcha}
                    sitekey={"6LeSoFgpAAAAAMxozZFqhN73Bak-JLg2ACNiMwOc"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container"> */}
      <div className="relative py-12 lg:py-12">
        {/* <BackgroundSection />
          <SectionPromo1 /> */}
      </div>
      {/* </div> */}
    </div>
  );
};

export default PageContact;
