import { AuthContext } from "components/contextApi/AuthContext";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import React, { useContext } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export interface CommonLayoutProps {
  children?: React.ReactNode;
}

const CommonLayout: FC<CommonLayoutProps> = ({ children }) => {
  const {userInfo} = useContext(AuthContext)
  const {t} = useTranslation();
  const{lang} = useContext(LanguageContext);
  return (
    <div className="nc-CommonLayoutProps container" style={{direction:`${lang === 'ar' ? "rtl" : "ltr"}`}}>
      <div className="mt-14 sm:mt-20">
        <div className="max-w-4xl mx-auto">
          <div className="max-w-2xl">
            <h2 className="text-3xl xl:text-4xl font-semibold">{t("MyAccount")}</h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-base sm:text-lg">
              <span className="text-slate-900 dark:text-slate-200 font-semibold">
              {
                userInfo ? userInfo?.name :  
                'Enrico Cole,'
              }
              </span>{" "}
              {
                userInfo ? userInfo?.email :  
              'ciseco@gmail.com · Los Angeles, CA'
              }
            </span>
          </div>
          <hr className="mt-10 border-slate-200 dark:border-slate-700"></hr>

          <div className="flex   overflow-x-auto hiddenScrollbar">
            {[
              {
                name: t("AccountInfo"),
                link: "/account",
              },
              // {
              //   name: "Save lists",
              //   link: "/account-savelists",
              // },
              {
                name: t("Myorder"),
                link: "/account-my-order",
              },
              {
                name: t("ChangePassword"),
                link: "/account-change-password",
              },
              // {
              //   name: "Change Billing",
              //   link: "/account-billing",
              // },
            ].map((item:any, index) => (
              <NavLink
                key={index}
                to={item.link}
                className={({ isActive }) =>
                  `mx-8 block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0  text-sm sm:text-base ${
                    isActive
                      ? "border-primary-500 font-medium text-slate-900 dark:text-slate-200"
                      : "text-slate-500 dark:text-slate-400 hover:text-slate-800 dark:hover:text-slate-200"
                  }`
                }
              >
                {item.name}
              </NavLink>
            ))}
          </div>
          <hr className="border-slate-200 dark:border-slate-700"></hr>
        </div>
      </div>
      <div className="max-w-4xl mx-auto pt-14 sm:pt-26 pb-24 lg:pb-32">
        {children}
      </div>
    </div>
  );
};

export default CommonLayout;
