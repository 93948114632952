// import React from 'react'
import { configureStore } from "@reduxjs/toolkit";
import CartSlice from "./slices/CartSlice";
import Main_Categories from "./slices/Main_Categories";
import productsReducer from "./slices/ProductsSlice";
import filtersReducer from "./slices/filtersSlice";
import categoriesReducer from "./slices/categoriesSlice";
import BrandSlice from "./slices/BrandSlice";
import couponApiSlice from "./slices/couponApiSlice";
import promoBarsSlice from "./slices/promoBarsSlice";
export const store = configureStore({
  reducer: {
    cart: CartSlice,
    main_cat: Main_Categories,
    products: productsReducer,
    filters: filtersReducer,
    categories: categoriesReducer,
    Brands: BrandSlice,
    coupon: couponApiSlice,
    promoBars: promoBarsSlice,

  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
