import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const initialToken = Cookies.get("token");
const decodeTokenAndGetUserId = (token) => {
  try {
    // Decode the token
    const decodedToken = jwtDecode(token);

    // Assuming the user ID is stored in the 'sub' (subject) claim of the token
    const userId = decodedToken.sub;

    return userId;
  } catch (error) {
    // Handle decoding errors or invalid tokens
    console.error("Error decoding token:", error);
    return null;
  }
};
const userId = initialToken ? decodeTokenAndGetUserId(initialToken) : null;
const storedItems = localStorage.getItem(`labCart_${userId}`)
  ? JSON.parse(localStorage.getItem(`labCart_${userId}`))
  : {
      cartItems: [],
      totalAmount: 0,
      totalQuantity: 0,
    };
const initialState = {
  cartItems: storedItems.cartItems,
  totalAmount: storedItems.totalAmount,
  totalQuantity: storedItems.totalQuantity,
  token: initialToken, // Include the token in the initial state
};

const cartSlice = createSlice({
  name: "cartSlice",
  initialState,
  reducers: {
    // updateToken: (state, action) => {
    //   const newToken = action.payload;
    //   const newUserId = newToken ? decodeTokenAndGetUserId(newToken) : null;

    //   // Create a new state object with the updated token and userId
    //   let newState = {
    //     ...state,
    //     token: newToken,
    //     userId: newUserId,
    //   };

    //   // Determine the appropriate local storage keys
    //   const previousLocalStoreKey = "labCart_null";
    //   const newLocalStoreKey = newUserId
    //     ? `labCart_${newUserId}`
    //     : previousLocalStoreKey;

    //   // Fetch the cart data from the previous local storage key
    //   const existingCartData = JSON.parse(
    //     localStorage.getItem(previousLocalStoreKey)
    //   );

    //   // If there's existing cart data and a new userId, transfer the data
    //   if (existingCartData && newUserId) {
    //     // Update the new state with the existing cart data
    //     newState = {
    //       ...newState,
    //       cartItems: existingCartData.cartItems,
    //       totalAmount: existingCartData.totalAmount,
    //       totalQuantity: existingCartData.totalQuantity,
    //     };

    //     // Store the updated cart data under the new userId key
    //     localStorage.setItem(
    //       newLocalStoreKey,
    //       JSON.stringify({
    //         cartItems: newState.cartItems,
    //         totalAmount: newState.totalAmount,
    //         totalQuantity: newState.totalQuantity,
    //       })
    //     );

    //     // Remove the old local storage key
    //     localStorage.removeItem(previousLocalStoreKey);
    //   }

    //   return newState;
    // },

    updateToken: (state, action) => {
      const newToken = action.payload;
      const newUserId = newToken ? decodeTokenAndGetUserId(newToken) : null;

      // Initialize the new state
      let newState = {
        ...state,
        token: newToken,
        userId: newUserId,
      };

      if (newUserId) {
        // Key for logged-in user
        const userLocalStoreKey = `labCart_${newUserId}`;

        // Get data from `labCart_null` if it exists
        const loggedOutCartData = JSON.parse(
          localStorage.getItem("labCart_null")
        );

        // Get existing user cart data if it exists
        const existingUserCartData = JSON.parse(
          localStorage.getItem(userLocalStoreKey)
        );

        if (loggedOutCartData) {
          // Merge the cart data
          const existingCartItems = existingUserCartData
            ? existingUserCartData.cartItems
            : [];
          const mergedCartItems = existingCartItems.map((item) => ({
            ...item,
          }));

          // Update quantities if item already exists in user's cart
          loggedOutCartData.cartItems.forEach((loggedOutItem) => {
            const existingItemIndex = mergedCartItems.findIndex(
              (item) => item?.data?.id === loggedOutItem?.data?.id
            );
            if (existingItemIndex !== -1) {
              // Increase quantity of existing item
              mergedCartItems[existingItemIndex].quantity +=
                loggedOutItem.quantity;
            } else {
              // Add new item to the cart
              mergedCartItems.push(loggedOutItem);
            }
          });

          // Calculate total amount and total quantity
          const totalAmount = mergedCartItems.reduce(
            (sum, item) => sum + +item?.stock?.price * item.quantity,
            0
          );
          const totalQuantity = mergedCartItems.reduce(
            (sum, item) => sum + item.quantity,
            0
          );

          newState = {
            ...newState,
            cartItems: mergedCartItems,
            totalAmount,
            totalQuantity,
          };

          // Store the merged cart data under the user-specific key
          localStorage.setItem(
            userLocalStoreKey,
            JSON.stringify({
              cartItems: newState.cartItems,
              totalAmount: newState.totalAmount,
              totalQuantity: newState.totalQuantity,
            })
          );

          // Remove the `labCart_null` key
          localStorage.removeItem("labCart_null");
        } else if (existingUserCartData) {
          newState = {
            ...newState,
            cartItems: existingUserCartData.cartItems,
            totalAmount: existingUserCartData.totalAmount,
            totalQuantity: existingUserCartData.totalQuantity,
          };
        }
      } else {
        // Handle logout, initialize a new empty `labCart_null`
        localStorage.setItem(
          "labCart_null",
          JSON.stringify({
            cartItems: [],
            totalAmount: 0,
            totalQuantity: 0,
          })
        );
      }

      return newState;
    },

    // updateToken: (state, action) => {
    //     const newToken = action.payload;
    //     const newUserId = newToken ? decodeTokenAndGetUserId(newToken) : null;

    //     // Initialize the new state
    //     let newState = {
    //       ...state,
    //       token: newToken,
    //       userId: newUserId,
    //     };

    //     if (newUserId) {
    //       // Key for logged-in user
    //       const userLocalStoreKey = `labCart_${newUserId}`;

    //       // Get data from `labCart_null` if it exists
    //       const loggedOutCartData = JSON.parse(localStorage.getItem('labCart_null'));

    //       // Get existing user cart data if it exists
    //       const existingUserCartData = JSON.parse(localStorage.getItem(userLocalStoreKey));

    //       // Merge the data
    //       if (loggedOutCartData) {
    //         const mergedCartItems = existingUserCartData
    //           ? [...existingUserCartData.cartItems, ...loggedOutCartData.cartItems]
    //           : [...loggedOutCartData.cartItems];

    //         const totalAmount = existingUserCartData
    //           ? existingUserCartData.totalAmount + loggedOutCartData.totalAmount
    //           : loggedOutCartData.totalAmount;

    //         const totalQuantity = existingUserCartData
    //           ? existingUserCartData.totalQuantity + loggedOutCartData.totalQuantity
    //           : loggedOutCartData.totalQuantity;

    //         newState = {
    //           ...newState,
    //           cartItems: mergedCartItems,
    //           totalAmount,
    //           totalQuantity,
    //         };

    //         // Store the merged cart data under the user-specific key
    //         localStorage.setItem(userLocalStoreKey, JSON.stringify({
    //           cartItems: newState.cartItems,
    //           totalAmount: newState.totalAmount,
    //           totalQuantity: newState.totalQuantity,
    //         }));

    //         // Remove the `labCart_null` key
    //         localStorage.removeItem('labCart_null');
    //       } else if (existingUserCartData) {
    //         newState = {
    //           ...newState,
    //           cartItems: existingUserCartData.cartItems,
    //           totalAmount: existingUserCartData.totalAmount,
    //           totalQuantity: existingUserCartData.totalQuantity,
    //         };
    //       }
    //     } else {
    //       // Handle logout, initialize a new empty `labCart_null`
    //       localStorage.setItem('labCart_null', JSON.stringify({
    //         cartItems: [],
    //         totalAmount: 0,
    //         totalQuantity: 0,
    //       }));
    //     }

    //     return newState;
    //   },

    // updateToken: (state, action) => {
    //   const newToken = action.payload;
    //   const newUserId = newToken ? decodeTokenAndGetUserId(newToken) : null;

    //   // Initialize the new state
    //   let newState = {
    //     ...state,
    //     token: newToken,
    //     userId: newUserId,
    //   };

    //   if (newUserId) {
    //     // Key for logged-in user
    //     const userLocalStoreKey = `labCart_${newUserId}`;

    //     // Get data from `labCart_null` if it exists
    //     const loggedOutCartData = JSON.parse(
    //       localStorage.getItem("labCart_null")
    //     );

    //     if (loggedOutCartData) {
    //       // Transfer data to `labCart_<userId>`
    //       newState = {
    //         ...newState,
    //         cartItems: loggedOutCartData.cartItems,
    //         totalAmount: loggedOutCartData.totalAmount,
    //         totalQuantity: loggedOutCartData.totalQuantity,
    //       };

    //       // Store the cart data under the user-specific key
    //       localStorage.setItem(
    //         userLocalStoreKey,
    //         JSON.stringify({
    //           cartItems: newState.cartItems,
    //           totalAmount: newState.totalAmount,
    //           totalQuantity: newState.totalQuantity,
    //         })
    //       );

    //       // Remove the `labCart_null` key
    //       localStorage.removeItem("labCart_null");
    //     } else {
    //       // Load existing cart data for the logged-in user
    //       const userCartData = JSON.parse(
    //         localStorage.getItem(userLocalStoreKey)
    //       );
    //       if (userCartData) {
    //         newState = {
    //           ...newState,
    //           cartItems: userCartData.cartItems,
    //           totalAmount: userCartData.totalAmount,
    //           totalQuantity: userCartData.totalQuantity,
    //         };
    //       }
    //     }
    //   } else {
    //     // Handle logout, initialize a new `labCart_null` without transferring data back
    //     localStorage.setItem(
    //       "labCart_null",
    //       JSON.stringify({
    //         cartItems: [],
    //         totalAmount: 0,
    //         totalQuantity: 0,
    //       })
    //     );
    //   }

    //   return newState;
    // },

    // updateToken: (state, action) => {
    //   const newToken = action.payload;

    //   // Create a new state object with the updated token
    //   let newState = {
    //     ...state,
    //     token: newToken,
    //     userId: newToken ? decodeTokenAndGetUserId(newToken) : null,
    //   };

    //   // Update cart data based on the new userId
    //   const newUserId = newState.userId;
    //   const localStoreKey = newUserId ? `labCart_${newUserId}` : `labCart_null`; // Concat key here

    //   // console.log(newState);
    //   // Fetch data from both keys if userId is not null
    //   const existingCartData = localStorage.getItem(`labCart_null`)
    //     ? JSON.parse(localStorage.getItem(`labCart_null`))
    //     : null;

    //   // Only fetch the token if there's no existing cart data
    //   if (!existingCartData) {
    //       // Fetch token and update state
    //       // ... your token fetching logic ...
    //     } else {
    //       console.log(localStoreKey);
    //     // Use the existing cart data directly
    //     newState = {
    //       ...state,
    //       token: initialToken, // Update token if needed
    //       userId: newUserId,
    //       cartItems: existingCartData.cartItems,
    //       totalAmount: existingCartData.totalAmount,
    //       totalQuantity: existingCartData.totalQuantity,
    //     };
    //     localStorage.setItem(
    //       localStoreKey,
    //       JSON.stringify({
    //         cartItems: newState.cartItems,
    //         totalAmount: newState.totalAmount,
    //         totalQuantity: newState.totalQuantity,
    //       })
    //     );
    //   }
    //   // Update local storage with the new userId, using the concatenated key
    //   return newState;
    // },
    // updateToken: (state, action) => {
    //     const newToken = action.payload;

    //     // Create a new state object with the updated token
    //     const newState = {
    //         ...state,
    //         token:  newToken,
    //         userId: newToken ? decodeTokenAndGetUserId(newToken) : null,
    //     };
    //     // Update cart data based on the new userId
    //     const newUserId = newState.userId;
    //     // console.log(newUserId);
    //     const newStoredItems = localStorage.getItem(`labCart_${newUserId}`) ?
    //         JSON.parse(localStorage.getItem(`labCart_${newUserId}`)) :
    //         { cartItems: [], totalAmount: 0, totalQuantity: 0 };

    //     // Update the new state with cart data
    //     newState.cartItems = newStoredItems.cartItems;
    //     newState.totalAmount = newStoredItems.totalAmount;
    //     newState.totalQuantity = newStoredItems.totalQuantity;

    //     // Update local storage with the new userId
    //     localStorage.setItem(`labCart_${newUserId}`, JSON.stringify({
    //         cartItems: newState.cartItems,
    //         totalAmount: newState.totalAmount,
    //         totalQuantity: newState.totalQuantity,
    //     }));
    //     return newState;
    // },
    addItem: (state, action) => {
      const allItemsofDetails = action.payload.allData;
      const optionalData = allItemsofDetails?.optional_data;

      
      const newItem = action.payload.product;
      const options = action.payload.options;
      const qty = action.payload.qty;
      
      const userId = state.userId;
      
      // console.log(newItem,"allItemsofDetails");
      const optionsData = [];
      options &&
        Object.entries(options).map((key) => {
          optionsData.push(
            (optionalData?.[key[0]]).find((item) => +item.id === +key[1])
          );
        });

      let priceOptions = 0;
      optionsData?.map((item) => {
        priceOptions += +item.price;
      });

      const existingItem = state.cartItems.find(
        (item) =>
          // console.log(item,"itemo")
          +item?.stock?.product_id === +newItem?.stock?.product_id &&
          JSON.stringify(item.optionsSelectd) === JSON.stringify(optionsData)
      );
      qty ? (state.totalQuantity += qty) : state.totalQuantity++;

      if (!existingItem) {
        state.cartItems.push({
          ...newItem,
          quantity: qty ? qty : 1,
          optionsSelectd: optionsData,
          priceOptions: priceOptions,
          totalPrice: qty
            ? (+newItem?.stock?.price + +priceOptions) * qty
            : +newItem?.stock.price + +priceOptions,
        });
      } else {
        if (qty) {
          existingItem.quantity += qty;
          existingItem.totalPrice =
            (+existingItem.stock.price +
              (existingItem.priceOptions ? +existingItem.priceOptions : 0)) *
            +existingItem.quantity;
        } else {
          existingItem.quantity++;
          existingItem.totalPrice =
            (+existingItem.stock.price +
              (existingItem.priceOptions ? +existingItem.priceOptions : 0)) *
            +existingItem.quantity;
        }
      }
      state.totalAmount = state.cartItems.reduce((acc, item) => {
        acc +=
          (Number(item?.stock?.price) +
            (item.priceOptions ? Number(item.priceOptions) : 0)) *
          Number(item.quantity);
        return acc;
      }, 0);
      if (userId) {
        localStorage.setItem(
          `labCart_${userId}`,
          JSON.stringify({
            cartItems: state.cartItems,
            totalAmount: state.totalAmount,
            totalQuantity: state.totalQuantity,
          })
        );
      } 
      else {
        localStorage.setItem(
          `labCart_null`,
          JSON.stringify({
            cartItems: state.cartItems,
            totalAmount: state.totalAmount,
            totalQuantity: state.totalQuantity,
          })
        );
      }
    },
    deleteItem: (state, action) => {
      const userId = state.userId ?? null;
      const product = action.payload;
      const existingItem = state.cartItems.find(
        (item) => JSON.stringify(item) === JSON.stringify(product)
      );
      if (existingItem) {
        state.cartItems = state.cartItems.filter(
          (item) => JSON.stringify(item) !== JSON.stringify(product)
        );
        state.totalQuantity = state.totalQuantity - existingItem.quantity;
      }
      state.totalAmount = state.cartItems.reduce((acc, item) => {
        acc +=
          (Number(item?.stock.price) +
            (item.priceOptions ? Number(item.priceOptions) : 0)) *
          Number(item.quantity);
        return acc;
      }, 0);
      console.log(userId);
      localStorage.setItem(
        `labCart_${userId}`,
        JSON.stringify({
          cartItems: state.cartItems,
          totalAmount: state.totalAmount,
          totalQuantity: state.totalQuantity,
        })
      );
    },
    decreaseItem: (state, action) => {
      const userId = state.userId ?? null;
      const product = action.payload;
      const existingItem = state.cartItems.find(
        (item) => JSON.stringify(item) === JSON.stringify(product)
      );
      if (existingItem && existingItem.quantity > 1) {
        existingItem.quantity--;
        state.totalQuantity--;
        existingItem.totalPrice =
          (+existingItem?.stock?.price +
            (existingItem.priceOptions ? +existingItem.priceOptions : 0)) *
          +existingItem.quantity;
      }
      state.totalAmount = state.cartItems.reduce((acc, item) => {
        acc +=
          (Number(item?.stock?.price) +
            (item.priceOptions ? Number(item.priceOptions) : 0)) *
          Number(item.quantity);
        return acc;
      }, 0);
      localStorage.setItem(
        `labCart_${userId}`,
        JSON.stringify({
          cartItems: state.cartItems,
          totalAmount: state.totalAmount,
          totalQuantity: state.totalQuantity,
        })
      );
    },
    increaseItem: (state, action) => {
      const userId = state.userId ?? null;
      const product = action.payload;
      const existingItem = state.cartItems.find(
        (item) => JSON.stringify(item) === JSON.stringify(product)
      );
      if (existingItem) {
        existingItem.quantity++;
        state.totalQuantity++;
        existingItem.totalPrice =
          (+existingItem?.stock?.price +
            (existingItem.priceOptions ? +existingItem.priceOptions : 0)) *
          +existingItem.quantity;
      }

      state.totalAmount = state.cartItems.reduce((acc, item) => {
        acc +=
          (Number(item.stock.price) +
            (item.priceOptions ? Number(item.priceOptions) : 0)) *
          Number(item.quantity);
        return acc;
      }, 0);
      localStorage.setItem(
        `labCart_${userId}`,
        JSON.stringify({
          cartItems: state.cartItems,
          totalAmount: state.totalAmount,
          totalQuantity: state.totalQuantity,
        })
      );
    },
    clearCart: (state, action) => {
      const userId = state.userId ?? null;
      state.cartItems = [];
      state.totalAmount = 0;
      state.totalQuantity = 0;
      localStorage.setItem(
        `labCart_${userId}`,
        JSON.stringify({
          cartItems: state.cartItems,
          totalAmount: state.totalAmount,
          totalQuantity: state.totalQuantity,
        })
      );
    },
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;
