import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact.js";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp.js";
import PageLogin from "containers/PageLogin/PageLogin.js";
import PageEmailInput from "containers/PageEmailInput/PageEmailInput";
import PageCodeInput from "containers/PageEmailInput/PageCodeInput";
import NewPassword from "containers/PageEmailInput/NewPassword";
import PageVerify from "containers/PageEmailInput/PageVerify";

// import PageSubcription from "containers/PageSubcription/PageSubcription";
// import BlogPage from "containers/BlogPage/BlogPage";
// import BlogSingle from "containers/BlogPage/BlogSingle";
import SiteHeader from "containers/SiteHeader";
// import PageCollection from "containers/PageCollection";
// import PageCollection2 from "containers/PageCollection2";
import PageSearch from "containers/PageSearch";
import PageHome2 from "containers/PageHome/PageHome2.js";
// import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
import ProductDetailPage from "containers/ProductDetailPage/ProductDetailPage";
// import ProductDetailPage2 from "containers/ProductDetailPage/ProductDetailPage2";
// import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountPass from "containers/AccountPage/AccountPass";
// import AccountBilling from "containers/AccountPage/AccountBilling";
import AccountOrder from "containers/AccountPage/AccountOrder";
import CartPage from "containers/ProductDetailPage/CartPage";
import CheckoutPage from "containers/PageCheckout/CheckoutPage";
import PrivacyPolicy from "containers/pagePrivacy/Privacy";
import Usage from "containers/pagePrivacy/Usage";
import PageCollection2 from "containers/PageCollection2";
import { Toaster } from "react-hot-toast";
// import { HashRouter } from "react-router-dom";
import { AuthContext } from "components/contextApi/AuthContext";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import PageCollection from "containers/PageCollection";
import { NormalizeURL } from "./NormalizeURL";
import ThankYou from "containers/PageThankYou/ThankYou";
import ThanksPage from "containers/PageThankYou/ThanksPage";

export const pages: Page[] = [
  { path: "/", component: PageHome2 },
  // { path: "/Home", component: PageHome2 },
  //
  // { path: "/home-header-2", component: PageHome2 },
  { path: "/:mainCategory/:link", component: ProductDetailPage },
  // { path: "/product-detail-2", component: ProductDetailPage2 },
  //
  // { path: "/page-collection-2", component: PageCollection2 },
  { path: "/:id", component: PageCollection },
  { path: "/brand/:id", component: PageCollection2 },
  // { path: "/:id", component: PageCollection2 }, // Check if "/:id" matches the expected type

  { path: "/shop", component: PageSearch },
  //
  { path: "/account", component: AccountPage },
  // { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-change-password", component: AccountPass },
  // { path: "/account-billing", component: AccountBilling },
  { path: "/account-my-order", component: AccountOrder },
  //
  { path: "/cart", component: CartPage },

  { path: "/checkout", component: CheckoutPage },
  //
  // { path: "/blog", component: BlogPage },
  // { path: "/blog-single", component: BlogSingle },
  //
  { path: "/contact-us", component: PageContact },
  { path: "/about-us", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/forgot-pass", component: PageEmailInput },
  { path: "/code", component: PageCodeInput },
  { path: "/NewPassword", component: NewPassword },
  { path: "/verify", component: PageVerify },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/privacy-usage", component: Usage },
  { path: "/thanks/:orderId", component: ThankYou },
  { path: "/thanks", component: ThanksPage },
  // { path: "/subscription", component: PageSubcription },
];

const MyRoutes = () => {
  const { isAuth, userInfo } = useContext(AuthContext);
  const { lang } = useContext(LanguageContext);
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);
  return (
    <>
      <Toaster />
      <ScrollToTop />
      <SiteHeader />
      <NormalizeURL />
      <Routes>

        {/* Redirection route for routes without language prefix */}
        <Route path="/" element={<Navigate to={`/${lang}`} />} />

        {/* <Route path="/*" element={<Navigate to={`/${lang}`} replace />} /> */}

        {pages.map(({ component: Component, path }, index) => {
          const fullPath = `/${lang}${path}`;
          // console.log(fullPath);
          return (
            <>
              <Route
                key={index}
                path={fullPath}
                element={
                  !isAuth &&
                    (path.startsWith("/account")) ? (
                    <Navigate to={`/${lang}/signup`} state={{ from: fullPath }} replace />
                  ) :
                    isAuth && (path === "/login" || path === "/signup") ? (
                      <Navigate to={`/${lang}/`} replace />
                    ) : (
                      // isAuth && (
                      // path === "/Checkout" &&
                      //   userInfo?.is_verified === 0 ? (
                      //   <Navigate to={`/${lang}/verify`} replace />
                      // ) :
                      <Component />
                    )
                  // )
                }
              />
              <Route
                key={index}
                path={path}
                element={
                  !isAuth &&
                    (path === "/checkout" || path.startsWith("/account")) ? (
                    <Navigate to={`/${lang}/login`} replace />
                  ) : isAuth && (path === "/login" || path === "/signup") ? (
                    <Navigate to={`/${lang}/`} replace />
                  )
                    //  : isAuth &&
                    //   path === "/Checkout" &&
                    //   userInfo?.is_verified === 0 ? (
                    //   <Navigate to={`/${lang}/verify`} replace /> 
                    // )
                    : (
                      <Component />
                    )
                }
              />
            </>
          );
        })}
        <Route path="/ar/contact-us" element={<PageContact />} />
        <Route path="/en/contact-us" element={<PageContact />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Footer />
    </>
    // <>
    //   <Toaster />
    //   <ScrollToTop />
    //   <SiteHeader />
    //   <Routes>
    //     {pages.map(({ component: Component, path }, index) => {
    //       return !isAuth &&
    //         (path === "/Checkout" ||
    //           path === "/account" ||
    //           path === "/account-savelists" ||
    //           path === "/account-change-password" ||
    //           path === "/account-billing" ||
    //           path === "/account-my-order") ? (
    //         <Route
    //           path="/checkout"
    //           element={<Navigate to="/login" replace />}
    //           key={index}
    //         />
    //       ) : isAuth && (path === "/Login" || path === "/Signup") ? (
    //         <Route
    //           path="/login"
    //           element={<Navigate to="/" replace />}
    //           key={index}
    //         />
    //       ) : isAuth && path === "/Checkout" && userInfo?.is_verified === 0 ? (
    //         <Route
    //           key={index}
    //           element={<Navigate to="/verify" replace />}
    //           path={"/Checkout"}
    //         />
    //       ) : (
    //         // <Route
    //         //   key={index}
    //         //   element={<Component />}
    //         //   path={lang ? `/${lang}${path}` : `/${path}`}
    //         // />
    //         <Route key={index} element={<Component />} path={path} />
    //       );
    //     })}
    //     <Route path="*" element={<Page404 />} />
    //   </Routes>
    //   <Footer />
    // </>
  );
};

export default MyRoutes;
