import React, { useContext } from "react";
// import { useLocation } from "react-router-dom";
import HeaderLogged from "components/Header/HeaderLogged";
import Header from "components/Header/Header";
import { AuthContext } from "components/contextApi/AuthContext";

const SiteHeader = () => {
  // let location = useLocation();
  const {isAuth} = useContext(AuthContext)
  return isAuth ? <HeaderLogged /> : <Header />
  
  // location.pathname === "/home2" ? <Header /> : <HeaderLogged />;
};

export default SiteHeader;
