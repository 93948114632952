import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./auth.css";
import { AuthContext } from "components/contextApi/AuthContext";
import toast from "react-hot-toast";
import axios from "axios";
import { Order } from "components/contextApi/DataOrderContext";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { useTranslation } from "react-i18next";
import { PagesContext } from "components/contextApi/MetaContext";

const PageSignUp = ({ className = "" }) => {
  const [formData, setFormData] = useState({
    username: "",
    phoneNumber: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    phoneNumber: "",
    email: "",
    password: "",
  });

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const [errorMail, setErrorMail] = useState();
  const [errorPhone, setErrorPhone] = useState();

  const { baseUrl } = useContext(Order);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // console.log(errorPhone);
  const validateForm = () => {
    let valid = true;
    const updatedErrors = { ...errors };

    // Validate username
    if (!formData.username.trim()) {
      updatedErrors.username = t("UsernameRequired");
      valid = false;
    } else {
      updatedErrors.username = "";
    }

    // Validate phone number
    const phoneRegex = /^\d+$/;
    if (!formData.phoneNumber || !phoneRegex.test(formData.phoneNumber)) {
      updatedErrors.phoneNumber = t("InvalidPhoneNumber");
      valid = false;
    } else {
      updatedErrors.phoneNumber = "";
    }

    // Validate email
    const emailRegex = /\S+@\S+\.\S+/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      updatedErrors.email = t("InvalidEmailAddress");
      valid = false;
    } else {
      updatedErrors.email = "";
    }

    // Validate password
    const minLength = 8;
    const passwordValue = formData.password.trim();
    if (!passwordValue) {
      updatedErrors.password = t("PasswordRequired");
      valid = false;
    } else if (passwordValue.length < 8) {
      updatedErrors.password = t("PasswordMinLength");
      valid = false;
    } else if (passwordValue.length < minLength) {
      updatedErrors.password = `Password must be at least ${minLength} characters long.`;
      valid = false;
    }
    // } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])/.test(passwordValue)) {
    //   updatedErrors.password = 'Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character';
    //   valid = false;
    // }
    else {
      updatedErrors.password = "";
    }

    setErrors(updatedErrors);
    return valid;
  };
  const location = useLocation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        const dataToSend = {
          name: formData.username,
          email: formData.email,
          phone: formData.phoneNumber,
          password: formData.password,
          type: 0,
          country: "f",
        };

        const response = await axios.post(
          `${baseUrl}/users/register`,
          dataToSend
        );

        if (response.data.status !== 200) {
          console.log("Registration failed. Response:", response.data);
          setLoading(false);
          // throw new Error(`HTTP error! Status: ${response}`);
          // console.log("response",response);
        } else {
          const data = await response.data;
          let dataSendApiLogin = new FormData();
          dataSendApiLogin.append("login", data.user.email);
          dataSendApiLogin.append("password", formData.password);
          await login(dataSendApiLogin);
          const fromPath = location.state?.from || `/${lang}/`;
          if (fromPath === `/${lang}/checkout`) {
            navigate(fromPath);
          } else {
            navigate(-1); // يروح للصفحة اللي كان فيها قبل صفحة اللوجين
          }
          // navigate("/", { replace: true });
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setErrorMail("");
        setErrorPhone("");
        if (error.response.data.error.email) {
          toast.error(error.response.data.error.email[0]);
          setErrorMail(error.response.data.error.email[0]);
          setErrorPhone("");
        } else if (error.response.data.error.phone) {
          toast.error(error.response.data.error.phone[0]);
          setErrorPhone(error.response.data.error.phone[0]);
          setErrorMail("");
        } else {
          if (error.response.data.error) {
            setErrorPhone(error.response.data.error);
            toast.error(error.response.data.error);
          } else {
            toast.error(error.message);
          }
          setErrorMail("");
          // setErrorPhone("");
        }
      }
      // console.log('Form submitted:', formData);
    } else {
      toast.error(t("FormErrors"));
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const { lang } = useContext(LanguageContext);
  const { GetPage, dataPages } = useContext(PagesContext);
  useEffect(() => {
    GetPage({ page_id: 13 }, lang);
  }, []);
  const description = dataPages?.meta_description;
  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>{dataPages?.meta_title}</title>
        <meta name="description" content={description} />
        <link rel="Canonical" href={`/${lang}/signup`} />
      </Helmet>
      <div
        className="container mb-24 lg:mb-32"
        style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
      >
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("Signup")}
        </h2>
        <div className="max-w-xs md:max-w-md mx-auto space-y-6 ">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t("userName")}
              </span>
              <Input
                type="text"
                placeholder={t("userPlace")}
                className="mt-1"
                name="username"
                defaultValue={formData.username}
                onChange={handleChange}

                //   onChange={event => {
                //     setInputs({ ...inputs, name: event.target.value })
                //     setErrors(validate({ ...inputs, name: event.target.value }))
                // }}
                // onBlur={(event) => {setTouched({ ...touched, name: true }); setErrors(validate({ ...inputs, name: event.target.value }))}}
                // value={inputs.name}
              />
              {/* {errors.name || touched.name ? <p>{errors.name}</p> : null} */}
              {errors.username && (
                <span className="errorSpan">{errors.username}</span>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t("PhoneNumber")}
              </span>
              <Input
                type="number"
                placeholder={t("phonePlace")}
                className="mt-1"
                name="phoneNumber"
                defaultValue={formData.phoneNumber}
                onChange={handleChange}

                //   onChange={event => {
                //     setInputs({ ...inputs, phone: event.target.value })
                //     setErrors(validate({ ...inputs, phone: event.target.value }))
                // }}
                // onBlur={(event) => {setTouched({ ...touched, phone: true }); setErrors(validate({ ...inputs, phone: event.target.value }))}}
                // value={inputs.phone}
              />
              {/* {errors.phone || touched.phone ? <p>{errors.phone}</p> : null} */}
              {errors.phoneNumber ? (
                <span className="errorSpan">{errors.phoneNumber}</span>
              ) : errorPhone ? (
                <span className="errorSpan">{errorPhone}</span>
              ) : (
                ""
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t("EmailAddress")}
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                name="email"
                defaultValue={formData.email}
                onChange={handleChange}

                //   onChange={event => {
                //     setInputs({ ...inputs, email: event.target.value })
                //     setErrors(validate({ ...inputs, email: event.target.value }))
                // }}
                // onBlur={(event) => {setTouched({ ...touched, email: true }); setErrors(validate({ ...inputs, email: event.target.value }))}}
                // value={inputs.email}
              />
              {/* {errors.email || touched.email ? <p>{errors.email}</p> : null} */}
              {errors.email ? (
                <span className="errorSpan">{errors.email}</span>
              ) : errorMail ? (
                <span className="errorSpan">{errorMail}</span>
              ) : (
                ""
              )}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                {t("Password")}
              </span>
              <Input
                type="password"
                placeholder={t("Password")}
                className="mt-1"
                defaultValue={formData.password}
                name="password"
                onChange={handleChange}
                // onChange={event => {
                //     setInputs({ ...inputs, password: event.target.value })
                //     setErrors(validate({ ...inputs, password: event.target.value }))
                // }}
                // onBlur={(event) => {setTouched({ ...touched, password: true });setErrors(validate({ ...inputs, password: event.target.value }))}}
                // value={inputs.password}
              />
              {errors.password && (
                <span className="errorSpan">{errors.password}</span>
              )}

              {/* {errors.password || touched.password ? <p>{errors.password}</p> : null} */}
            </label>
            <ButtonPrimary type="submit" loading={loading}>
              {t("Continue")}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t("Already")} {` `}
            <Link className="text-green-600" to={`/${lang}/Login`}>
              {t("login")}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;
