import { usePriceRange } from "components/contextApi/PriceRangeProvider";
import { CustomLink } from "data/types";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";

export interface PaginationProps {
  className?: string;
  products?: any
}

const Pagination: FC<PaginationProps> = ({ className = "", products }) => {

  const { currentPage, setCurrentPage } = usePriceRange();
  const { t } = useTranslation();

  const renderItem = (pag: CustomLink, index: number) => {
    // console.log(products.last_page);
    const isFirstIndex = index === 0;
    const isLastIndex = index === products.links.length - 1;
    if (isFirstIndex) {
      return (
        <Link
          key={index}
          className={`inline-flex w-12 h-11 items-center justify-center rounded-md p-1 bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
          to={pag.href}
          onClick={() => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)}
        >
          {t('prev')}
        </Link>
      );
    } else if (isLastIndex) {
      return (
        <Link
          key={index}
          className={`inline-flex w-12 h-11 items-center justify-center rounded-md p-1 bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
          to={pag.href}
          onClick={() => setCurrentPage(currentPage < products.last_page ? Number(currentPage) + 1 : currentPage)}
        >
          {t('next')}
        </Link>
      );
    } else if (Number(pag.label) === currentPage) {
      return (
        <span
          key={index}
          className={`inline-flex w-12 h-11 items-center justify-center rounded-md p-1 bg-[rgb(118,177,255)] text-black ${twFocusClass()}`}
        >
          {pag.label}
        </span>
      );
    } else {
      return (
        <Link
          key={index}
          className={`inline-flex w-11 h-11 items-center justify-center rounded-md p-1 bg-[#D9E8FB] hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 dark:text-neutral-400 dark:bg-neutral-900 dark:hover:bg-neutral-800 dark:border-neutral-700 ${twFocusClass()}`}
          to={pag.href}
          onClick={() => setCurrentPage(Number(pag.label))}
        >
          {pag.label}
        </Link>
      );
    }
  };

  return (
    <nav
      className={`nc-Pagination inline-flex gap-2 space-x-1 text-base font-medium ${className}`}
    >
      {/* {currentPage} */}
      {products?.links && products?.links?.map(renderItem)}
    </nav>
  );
};

export default Pagination;
