import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest } from "components/utils/fetchMethods";



// Thunk to get sub categories from the new API endpoint
export const getBrand = createAsyncThunk(
  'categories/getBrand',
  async ({ _, lang }, { rejectWithValue }) => {
    try {
      const response = await getRequest({}, '/manufacturer', lang);
      if (!response) {
        throw new Error(response.message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const BrandSlice = createSlice({
  name: 'categories',
  initialState: {
    Brands: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBrand.fulfilled, (state, action) => {
        state.Brands = action.payload;
      })
      .addCase(getBrand.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});


export default BrandSlice.reducer;
