// import React, { useEffect, useState, useCallback } from "react";
// import backgroundLineSvg from "images/Moon.svg";
// import useInterval from "react-use/lib/useInterval";
// import useBoolean from "react-use/lib/useBoolean";
// import { useTranslation } from "react-i18next";
// import axios from "axios";

// const BANNER_API_URL = "https://sellxa.com/backend/api/app-slider";
// const AUTO_SLIDE_INTERVAL = 5500;
// // const MANUAL_SLIDE_TIMEOUT = 1000;

// const Hero = ({ className = "" }) => {
//   const { t } = useTranslation();
//   const [banners, setBanners] = useState([]);
//   const [activeIndex, setActiveIndex] = useState(0);
//   const [isAutoSliding, toggleAutoSliding] = useBoolean(true);

//   useEffect(() => {
//     const fetchBanners = async () => {
//       try {
//         const response = await axios.get(BANNER_API_URL);
//         setBanners(response?.data || []);
//       } catch (error) {
//         console.error("Failed to fetch banners:", error);
//       }
//     };
//     fetchBanners();
//   }, []);

//   const handleSlideChange = useCallback(
//     (newIndex) => {
//       setActiveIndex((prevIndex) => {
//         if (newIndex < 0) return banners.length - 1;
//         if (newIndex >= banners.length) return 0;
//         return newIndex;
//       });
//     },
//     [banners.length]
//   );

//   useInterval(
//     () => handleSlideChange(activeIndex + 1),
//     isAutoSliding ? AUTO_SLIDE_INTERVAL : null
//   );

//   return (
//     <div>
//       {banners.map(
//         (banner, index) =>
//           activeIndex === index && (
//             <BannerItem key={index} item={banner} className={className} />
//           )
//       )}
//       {/* <SlideControls onNext={handleNext} onPrev={handlePrev} /> */}
//     </div>
//   );
// };

// const BannerItem = ({ item, className }) => (
//   <div
//     className={`nc-SectionHero2Item mt-5 h-[20vh] min-[1025px]:h-[50vh] nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
//   >
//     <Background />
//     <Content item={item} />
//   </div>
// );

// const Background = () => (
//   <div className="absolute inset-0 bg-[#E3FFE6]">
//     <img
//       className="absolute w-full h-full object-contain"
//       src={backgroundLineSvg}
//       alt="hero"
//     />
//   </div>
// );

// const Content = ({ item }) => (
//   <div className="relative pb-0 lg:py-44 h-full">
//     <div className="lg:mt-0 lg:absolute right-0 bottom-0 top-0 w-full h-full">
//       <img
//         className="w-full h-full object-fill object-right-top nc-SectionHero2Item__image"
//         src={`https://sellxa.com/backend/storage/${item}`}
//         alt="banner"
//         width="100%"
//         height="100%"
//       />
//     </div>
//   </div>
// );

// export default Hero;

// import React, { useEffect, useState, useCallback } from "react";
// import backgroundLineSvg from "images/Moon.svg";
// import useInterval from "react-use/lib/useInterval";
// import useBoolean from "react-use/lib/useBoolean";
// import { useTranslation } from "react-i18next";
// import axios from "axios";

// const BANNER_API_URL = "https://sellxa.com/backend/api/app-slider";
// const AUTO_SLIDE_INTERVAL = 5500;

// const Hero = ({ className = "" }) => {
//   const { t } = useTranslation();
//   const [banners, setBanners] = useState([]);
//   const [activeIndex, setActiveIndex] = useState(0);
//   const [isAutoSliding, toggleAutoSliding] = useBoolean(true);

//   useEffect(() => {
//     const fetchBanners = async () => {
//       try {
//         const response = await axios.get(BANNER_API_URL);
//         setBanners(response?.data || []);
//       } catch (error) {
//         console.error("Failed to fetch banners:", error);
//       }
//     };

//     // Fetch banners only once on component mount
//     fetchBanners();
//   }, []);

//   const handleSlideChange = useCallback(
//     (newIndex) => {
//       setActiveIndex((prevIndex) => {
//         if (newIndex < 0) return banners.length - 1;
//         if (newIndex >= banners.length) return 0;
//         return newIndex;
//       });
//     },
//     [banners.length]
//   );

//   useInterval(
//     () => handleSlideChange(activeIndex + 1),
//     isAutoSliding ? AUTO_SLIDE_INTERVAL : null
//   );

//   return (
//     <div>
//       {banners.map(
//         (banner, index) =>
//           activeIndex === index && (
//             <BannerItem key={index} item={banner} className={className} />
//           )
//       )}
//       {/* <SlideControls onNext={handleNext} onPrev={handlePrev} /> */}
//     </div>
//   );
// };

// const BannerItem = ({ item, className }) => (
//   <div
//     className={`nc-SectionHero2Item mt-5 h-[20vh] min-[1025px]:h-[50vh] nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
//   >
//     <Background />
//     <Content item={item} />
//   </div>
// );

// const Background = () => (
//   <div className="absolute inset-0 bg-[#E3FFE6]">
//     <img
//       className="absolute w-full h-full object-contain"
//       src={backgroundLineSvg}
//       alt="hero"
//     />
//   </div>
// );

// const Content = ({ item }) => (
//   <div className="relative pb-0 lg:py-44 h-full">
//     <div className="lg:mt-0 lg:absolute right-0 bottom-0 top-0 w-full h-full">
//       <img
//         className="w-full h-full object-fill object-right-top nc-SectionHero2Item__image"
//         src={`https://sellxa.com/backend/storage/${item}`}
//         alt="banner"
//         width="100%"
//         height="100%"
//       />
//     </div>
//   </div>
// );

// export default Hero;

import React, { useEffect, useState, useCallback, useRef } from "react";
import backgroundLineSvg from "images/Moon.svg";
import useInterval from "react-use/lib/useInterval";
import useBoolean from "react-use/lib/useBoolean";
import { useTranslation } from "react-i18next";
import axios from "axios";

const BANNER_API_URL = "https://sellxa.com/backend/api/app-slider";
const AUTO_SLIDE_INTERVAL = 5500;

const Hero = ({ className = "" }) => {
  const { t } = useTranslation();
  const [banners, setBanners] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isAutoSliding, toggleAutoSliding] = useBoolean(true);
  const bannersFetchedRef = useRef(false); // To check if banners have been fetched

  useEffect(() => {
    if (!bannersFetchedRef.current) {
      const fetchBanners = async () => {
        try {
          const response = await axios.get(BANNER_API_URL);
          setBanners(response?.data || []);
          bannersFetchedRef.current = true; // Set to true after fetching
        } catch (error) {
          console.error("Failed to fetch banners:", error);
        }
      };

      // Fetch banners only once
      fetchBanners();
    }
  }, []);

  const handleSlideChange = useCallback(
    (newIndex) => {
      setActiveIndex((prevIndex) => {
        if (newIndex < 0) return banners.length - 1;
        if (newIndex >= banners.length) return 0;
        return newIndex;
      });
    },
    [banners.length]
  );

  useInterval(
    () => handleSlideChange(activeIndex + 1),
    isAutoSliding ? AUTO_SLIDE_INTERVAL : null
  );

  return (
    <div>
      {banners.map(
        (banner, index) =>
          activeIndex === index && (
            <BannerItem key={index} item={banner} className={className} />
          )
      )}
      {/* <SlideControls onNext={handleNext} onPrev={handlePrev} /> */}
    </div>
  );
};

const BannerItem = ({ item, className }) => (
  <div
    className={`nc-SectionHero2Item mt-5 h-[20vh] min-[1025px]:h-[50vh] nc-SectionHero2Item--animation flex flex-col-reverse lg:flex-col relative overflow-hidden ${className}`}
  >
    <Background />
    <Content item={item} />
  </div>
);

const Background = () => (
  <div className="absolute inset-0 bg-[#E3FFE6]">
    <img
      className="absolute w-full h-full object-contain"
      src={backgroundLineSvg}
      alt="hero"
    />
  </div>
);

const Content = ({ item }) => (
  <div className="relative pb-0 lg:py-44 h-full">
    <div className="lg:mt-0 lg:absolute right-0 bottom-0 top-0 w-full h-full">
      <img
        className="w-full h-full object-fill object-right-top nc-SectionHero2Item__image"
        src={`https://sellxa.com/backend/storage/${item}`}
        alt="banner"
        width="100%"
        height="100%"
      />
    </div>
  </div>
);

export default Hero;
