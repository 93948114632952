import Prices from "components/Prices";
// import { LABTOP_PRODUCTS } from "data/data";
// import ButtonSecondary from "shared/Button/ButtonSecondary";
import CommonLayout from "./CommonLayout";
import { useContext, useEffect } from "react";
import { Order } from "components/contextApi/DataOrderContext";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { Helmet } from "react-helmet-async";
import { PagesContext } from "components/contextApi/MetaContext";

const AccountOrder = () => {
  const { orders, getOrders } = useContext(Order);
  useEffect(() => {
    getOrders();
  }, []);

  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);
  const renderProductItem = (data: any) => {
    const {
      price,
      product_name: name,
      quantity,
      product_id,
      image,
      link,
    } = data;

    return (
      <div key={product_id} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
        <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={`https://sellxa.com/backend/${image[0]?.url}` ?? ""}
            alt={name}
            className="h-full w-full object-contain object-center"
          />
        </div>

        <div
          className={`${lang === "ar" ? "mr-4" : "ml-4"
            }  flex flex-1 flex-col`}
        >
          <div>
            <div className="flex justify-between gap-3 ">
              <div>
                <a href={`/${lang}/laptops/${link}`}>
                  <h3 className="text-md font-medium  w-full">{name}</h3>
                </a>
              </div>

              <Prices className="mt-0.5 text-center" price={+price} />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400 flex items-center">
              <span className="">{t("qty")}</span>
              {/* <span className="inline-block sm:hidden">x</span> */}
              <span className={`${lang === "ar" ? "mr-2" : "ml-2"}`}>
                {quantity}
              </span>
            </p>

            {/* <div className="flex">
              <button
                type="button"
                className="font-medium text-indigo-600 dark:text-primary-500 "
              >
                Leave review
              </button>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderOrder = (item: any) => {
    const isoTimestamp = item?.created_at;

    // Status translations
    const statusTranslations: any = {
      Pending: lang === 'ar' ? 'قيد الانتظار' : 'Pending',
      Confirmed: lang === 'ar' ? 'تم التأكيد' : 'Confirmed',
      Shipped: lang === 'ar' ? 'تم الشحن' : 'Shipped',
      Received: lang === 'ar' ? 'تم الاستلام' : 'Received',
      Cancelled: lang === 'ar' ? 'تم الإلغاء' : 'Cancelled',
    };

    const statusText = statusTranslations[item?.status] || item?.status;
    // Status colors
    const statusColors: any = {
      Pending: 'text-yellow-500',
      Confirmed: 'text-blue-500',
      Shipped: 'text-indigo-500',
      Received: 'text-green-500',
      Cancelled: 'text-red-500',
    };
    const statusColor = statusColors[item?.status] || 'text-gray-500';


    // Parse the ISO date
    const date = new Date(isoTimestamp);

    // Format the date
    const formattedDate = format(date, "MMM d, yyyy"); // Output: Jul 4, 2024

    return (
      <div
        key={item?.order_id}
        className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0"
      >
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
          <div>
            <p className="text-lg font-semibold">#{item?.order_number}</p>
            <p className="text-slate-500 felx items-center justify-between dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
              <span>{formattedDate}</span>
              <span className="mx-2">·</span>
              <span className={`text-primary-500 ${statusColor}`}>{statusText}</span>
            </p>
          </div>
          {/* <div className="mt-3 sm:mt-0">
            <ButtonSecondary
              sizeClass="py-2.5 px-4 sm:px-6"
              fontSize="text-sm font-medium"
            >
              View Order
            </ButtonSecondary>
          </div> */}
        </div>
        <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
          {item?.cart_items?.map((data: any) => {
            return renderProductItem(data);
          })}
          <div className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
            {item?.shipping_costs && (
              <div className="flex justify-between py-2.5">
                <span>{t("ShppingEstimate")}</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {item?.shipping_costs} {t("EGP")}
                </span>
              </div>
            )}
            {item?.discount_percentage && (
              <div className="flex justify-between py-2.5">
                <span>{t("discount_percentage")}</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {+item?.discount_percentage} %
                </span>
              </div>
            )}
            {item?.discount && (
              <div className="flex justify-between py-4">
                <span>{t("discount_value")}</span>
                <span className="font-semibold text-slate-900 dark:text-slate-200">
                  {+item?.discount > 0
                    ? +item?.discount
                    : item?.total_price *
                      (+item?.discount_percentage
                        ? +item?.discount_percentage / 100
                        : 0) >
                      +item?.maximum_discount
                      ? +item?.maximum_discount
                      : item?.total_price *
                      (+item?.discount_percentage
                        ? +item?.discount_percentage / 100
                        : 0)}{" "}
                  {t("EGP")}
                </span>
              </div>
            )}
            <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
              <span>{t("OrderTotal")}</span>
              {
                <span>
                  {item?.total_price -
                    (item?.total_price *
                      (+item?.discount_percentage
                        ? +item?.discount_percentage / 100
                        : 0) >
                      +item?.maximum_discount
                      ? +item?.maximum_discount
                      : item?.total_price *
                      (+item?.discount_percentage
                        ? +item?.discount_percentage / 100
                        : 0)) +
                    +item?.shipping_costs
                    - +item?.discount
                  }{" "}
                  {t("EGP")}
                </span>
                // <span>{+item?.total_price + +item?.discount + +item?.shipping_costs} {t("EGP")}</span>
              }
            </div>
          </div>
        </div>
      </div>
    );
  };

  const { GetPage, dataPages } = useContext(PagesContext);
  useEffect(() => {
    GetPage({ page_id: 16 }, lang);
  }, []);
  const description = dataPages?.meta_description;

  return (
    <div>
      <Helmet>
        <title>{dataPages?.meta_title}</title>
        <meta name="description" content={description} />
        <link rel="Canonical" href={`/${lang}/account-my-order`} />
      </Helmet>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {t("OrderHistory")}
          </h2>
          {/* {orders?.map((item: any) => {
            return renderOrder(item);
          })} */}
          {orders
            ?.slice()
            .reverse()
            ?.map((item: any) => {
              return renderOrder(item);
            })}
          {/* {renderOrder()} */}
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountOrder;
