import React, { FC, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Pagination from "shared/Pagination/Pagination";

import HeaderFilterSearchPage from "components/HeaderFilterSearchPage.js";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import ProductCard from "components/ProductCard";
import { usePriceRange } from "components/contextApi/PriceRangeProvider";
import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
// import axios from "axios";
import resultImg from "images/no-data-.avif";
// import { Order } from "components/contextApi/DataOrderContext";
import { AuthContext } from "components/contextApi/AuthContext";
import { useDispatch } from "react-redux";
import { cartActions } from "components/rtk/slices/CartSlice";
import { notify } from "components/utils/notify";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { PagesContext } from "components/contextApi/MetaContext";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SectionGridFeatureItems from "./PageHome/SectionGridFeatureItems";
export interface PageSearchProps {
  className?: string;
}

const PageSearch: FC<PageSearchProps> = ({ className = "" }) => {

  useEffect(() => {
    // Function to send the API request
    const sendApiRequest = async () => {
      try {
        await fetch('https://sellxa.com/backend/api/log-tracking/?event_name=shop_page', {
          method: 'GET',
          mode: 'cors', // This enables CORS
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*', // Allow all origins
          },
          credentials: 'include', // Include credentials if needed
        });
      } catch (error) {
        console.error('Error sending API request:', error);
      }
    };

    // Call the function when the component mounts
    sendApiRequest();
  }, []);

  const { filteredProducts, setWord } = usePriceRange();
  const [SearchTerm, setSearchTerm] = useState("");
  const handleSearch = () => {
    setWord(SearchTerm);
  };

  // const {
  //   products,
  //   load,
  //   getDatafiltered,
  //   setProducts,
  //   setFilteredProducts,
  //   currentPage,
  //   selectedMainId,
  //   setSelectedMainId,
  //   setSelectedSubId,
  //   selectedSubId,
  //   selectedColor,
  //   selectedSizes,
  //   selectedBrands,
  //   word,
  //   setLoad,
  // } = usePriceRange();

  // const {
  //   setSelectedColors,
  //   setSelectedSizes,
  //   setSelectedBrands,
  //   setCurrentPage,
  //   setColorsState,
  //   setSizesState,
  //   setCategoriesState,
  //   // filteredProducts
  // } = usePriceRange();

  // useEffect(() => {
  //   setSelectedColors([]);
  //   setSelectedSizes([]);
  //   setSelectedBrands([]);
  //   setCurrentPage(1);
  //   setSelectedMainId(null);
  //   setSelectedSubId(null);
  //   setColorsState([]);
  //   setSizesState([]);
  //   setCategoriesState([]);
  //   setWord("");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const { lang } = useContext(LanguageContext);

  // useEffect(() => {
  //   const resfilter = async () => {
  //     try {
  //       const res = await getDatafiltered(
  //         {
  //           paginate: 50,
  //           page: currentPage,
  //           color_id: selectedColor,
  //           display_size: selectedSizes,
  //           manufacturer_id: selectedBrands,
  //           sub_category_id: selectedSubId,
  //           main_category_id: selectedMainId,
  //           word: word,
  //         },
  //         lang
  //       );

  //       setProducts(res);
  //       setLoad(false);
  //       setFilteredProducts(res.data);
  //     } catch (error: any) {
  //       setLoad(false);
  //       console.log(error.message);

  //       // notify(error.message, "error");
  //     }
  //   };
  //   resfilter();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   currentPage,
  //   selectedMainId,
  //   selectedSubId,
  //   selectedColor,
  //   selectedSizes,
  //   selectedBrands,
  //   word,
  //   lang,
  // ]);

  // const [experts, setExperts] = useState([]);
  const [loadingEx, setLoadingEx] = useState(true);
  const { t } = useTranslation();
  // const { baseUrl } = useContext(Order);

  // useEffect(() => {
  //   const getExpert = async () => {
  //     try {
  //       const res = await getDatafiltered({ paginate: 8, random: true }, lang);
  //       setExperts(res.data);
  //       setLoadingEx(false);
  //     } catch (error: any) {
  //       setLoadingEx(false);
  //       console.log(error.message);

  //       // notify(error.message, "error");
  //     }
  //   };
  //   getExpert();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loadingEx]);

  const dispatch = useDispatch();
  const { token } = useContext(AuthContext);
  const { GetPage, dataPages } = useContext(PagesContext);

  useEffect(() => {
    if (token) {
      dispatch(cartActions.updateToken(token));
    }
    GetPage({ page_id: 3 }, lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log(dataPages);

  return (
    <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
      <Helmet>
        <title>{dataPages?.meta_title ?? ""}</title>
        <meta
          name="description"
          content={
            dataPages?.meta_description ??
            "sellxa laptops and technologies ecommerce Easily select,find the correct items and add them to the cart"
          }
        />
        <link rel="Canonical" href="/shop" />
      </Helmet>

      {/* <div
        className={`nc-HeadBackgroundCommon h-24 2xl:h-28 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
        data-nc-id="HeadBackgroundCommon"
      /> */}
      {/* <div className="container">
        <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
          <form
            className="relative w-full"
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <span className="sr-only">Search all icons</span>
              <Input
                className="shadow-lg border-0 dark:border"
                id="search-input"
                type="search"
                placeholder="Type your keywords"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                rounded="rounded-full"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <ButtonCircle
                className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                size=" w-11 h-11"
                type="submit"
              >
                <i className="las la-arrow-right text-xl"></i>
              </ButtonCircle>
              <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </label>
          </form>
        </header>
      </div> */}

      <div className="container py-4 lg:pb-28 lg:pt-8 space-y-16 lg:space-y-28">
        <main style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}>
          <div className="mb-4 sm:mb-6">
            <div className="block text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
              <Link to={"/#"} className="">
                {t("Homepage")}
              </Link>
              <span className="text-xs mx-1 sm:mx-1.5">/</span>
              <span className="underline">{t("Shop")}</span>
            </div>
          </div>
          {/* FILTER */}
          {/* <HeaderFilterSearchPage /> */}
          {/* LOOP ITEMS */}
          {/* <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10" > */}
          {/* <div className="grid gap-4 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {
              !load && filteredProducts && filteredProducts?.length > 0 ? (
                filteredProducts?.map((item: any, index: number) => (
                  <ProductCard
                    data={item}
                    key={item.id ?? index}
                    className=""
                    isLiked
                  />
                ))
              ) : !load && products && products.length > 0 ? (
                products?.map((item: any, index: number) => (
                  <ProductCard
                    data={item}
                    key={item.id ?? index}
                    className=""
                    isLiked
                  />
                ))
              ) : (
                <img
                  src={resultImg}
                  alt="no results"
                  className="w-100"
                  style={{ maxWidth: "100vw" }}
                />
              )
              // <h1>Result Not Found</h1>
            }
          </div> */}
          {/* PAGINATION */}
          {/* <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            {(filteredProducts && filteredProducts?.length > 0) ||
              (!load && products && products?.length > 0) ? (
              <Pagination products={products} />
            ) : (
              ""
            )}
          </div> */}
          <SectionGridFeatureItems />
        </main>

        {/* === SECTION 5 === */}
        {/* <hr className="border-slate-200 dark:border-slate-700" /> */}
        {/* <SectionSliderCollections /> */}
        {/* {!loadingEx && experts.length > 0 ? (
          <SectionSliderProductCard
            data={experts?.filter((_, i) => i < 8)}
            heading={t("choosenByExpert")}
            subHeading=""
          />
        ) : (
          // <SectionSliderLargeProduct products={experts} />
          <div className="flex items-center justify-between gap-3">
            <div
              role="status"
              className="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
              <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              <div className="flex items-center mt-4">
                <div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                  <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
              </div>
              <span className="sr-only">Loading...</span>
            </div>
            <div
              role="status"
              className="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
              <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              <div className="flex items-center mt-4">
                <div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                  <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
              </div>
              <span className="sr-only">Loading...</span>
            </div>
            <div
              role="status"
              className="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
              <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              <div className="flex items-center mt-4">
                <div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                  <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
              </div>
              <span className="sr-only">Loading...</span>
            </div>
            <div
              role="status"
              className="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
              <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              <div className="flex items-center mt-4">
                <div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                  <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
              </div>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )} */}
        {/* {!loadingEx && experts.length > 0 ? (
          <SectionSliderLargeProduct products={experts} />
        ) : (
          <h3>loading...</h3>
        )} */}
      </div>
    </div>
  );
};

export default PageSearch;
