import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const NormalizeURL = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;
    const lowerCasePath = path.toLowerCase();

    if (path !== lowerCasePath) {
      navigate(lowerCasePath, { replace: true });
    }
  }, [location, navigate]);

  return null;  
};

