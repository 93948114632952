import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Create an async thunk for fetching colors
const baseUrl = 'https://sellxa.com/backend/api';

export const fetchColors = createAsyncThunk(
    'filters/fetchColors',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axios.get(`${baseUrl}/categories/showColor`);
        return response.data;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );
const filtersSlice = createSlice({
  name: "filters",
  initialState: {
    categories: [],
    sizes: [],
    colors: [],
    selectedColor: [],
    selectedSizes: [],
    selectedBrands: [],
    word: "",
    hards: [],
    selectedHard: [],
    capacities: [],
    selectedCapacities: [],
  },
  reducers: {
    setCategories(state, action) {
      state.categories = action.payload;
    },
    setSizes(state, action) {
      state.sizes = action.payload;
    },

    setSelectedSizes(state, action) {
      state.selectedSizes = action.payload;
    },
    setSelectedBrands(state, action) {
      state.selectedBrands = action.payload;
    },
    setWord(state, action) {
      state.word = action.payload;
    },
    setHards(state, action) {
      state.hards = action.payload;
    },
    setSelectedHard(state, action) {
      state.selectedHard = action.payload;
    },
    setCapacities(state, action) {
      state.capacities = action.payload;
    },
    setSelectedCapacities(state, action) {
      state.selectedCapacities = action.payload;
    },
    updateCapacities(state, action) {
      const { checked, name } = action.payload;
      if (checked) {
        // state.capacities.push(name);
        state.selectedCapacities.push(name);
      } else {
        // state.capacities = state.capacities.filter((i) => i !== name);
        state.selectedCapacities = state.selectedCapacities.filter(
          (i) => i !== name
        );
      }
    },
    setColorsState(state, action) {
      state.colors = action.payload;
    },
    setSelectedColors(state, action) {
      state.selectedColor = action.payload;
    },
    updateColors(state, action) {
      const { checked, name, id } = action.payload;
      if (checked) {
        // state.colors.push(name);
        state.selectedColor.push(id);
      } else {
        // state.colors = state.colors.filter((i) => i !== name);
        state.selectedColor = state.selectedColor.filter((i) => i !== id);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchColors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchColors.fulfilled, (state, action) => {
        state.loading = false;
        state.colors = action.payload;
      })
      .addCase(fetchColors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export const {
  setCategories,
  setColors,
  setSizes,
  setSelectedColor,
  setSelectedSizes,
  setSelectedBrands,
  setWord,
  setHards,
  setSelectedHard,
  setCapacities,
  setSelectedCapacities,
  updateCapacities,
  setColorsState, setSelectedColors, updateColors,
} = filtersSlice.actions;

export default filtersSlice.reducer;