

import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Pagination, Thumbs } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import "./SwiperProduct.css";

const SwiperProducts = ({ productt }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [mainIndex, setMainIndex] = useState(null);
  const [mainSwiper, setMainSwiper] = useState(null);

  const handleThumbnailClick = (index) => {
    if (mainSwiper) {
      mainSwiper.slideTo(index);
    }
  };

  const [zoomStyle, setZoomStyle] = useState({});

  const handleMouseMove = (e, imageUrl) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomStyle({
      backgroundImage: `url(${imageUrl})`,
      backgroundPosition: `${x}% ${y}%`,
      backgroundSize: "200%",
    });
  };

  const handleMouseLeave = () => {
    setZoomStyle({});
  };
  return (
    <div className="relative">
      {productt?.image?.length > 0 && (
        <>
          <Swiper
            modules={[Navigation, Thumbs]}
            spaceBetween={10}
            slidesPerView={1}

            initialSlide={0}
            // loop={true}
            navigation={true}
            thumbs={thumbsSwiper && { swiper: thumbsSwiper }}
            onSwiper={(swiper) => {
              setMainIndex(swiper.activeIndex);
              setMainSwiper(swiper);
            }}
            onSlideChange={(slide) => {
              setMainIndex(slide.activeIndex);
              setMainSwiper(slide);
            }}
            className="main-swiper"
          >
            {productt?.image?.map((image, index) => (
              <SwiperSlide key={index} style={{ overflow: "hidden" }}>
                <div
                  className="zoom-container"
                  onMouseMove={(e) =>
                    handleMouseMove(
                      e,
                      `https://sellxa.com/backend/${image?.url}`
                    )
                  }
                  onMouseLeave={handleMouseLeave}
                  style={zoomStyle}
                >
                  <img
                    src={`https://sellxa.com/backend/${image?.url}`}
                    alt="product detail"
                    className="zoom-image rounded-2xl object-contain"
                    style={{ paddingBottom: "35px" }}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            //   navigation
            // modules={[Navigation]}
            // loop={true}
            initialSlide={0}
            spaceBetween={10}
            slidesPerView={3}
            freeMode={true}
            // watchSlidesProgress={true}
            className="thumbnail-swiper"
            onSwiper={(swiper) => setThumbsSwiper(swiper.activeIndex)}
            onSlideChange={(slide) => setThumbsSwiper(slide.activeIndex)}
          >
            {productt?.image?.map((image, index) => (
              <SwiperSlide
                key={index}
                className={`thumbnail-slide ${mainIndex === index ? "active" : ""
                  }`}
                onClick={() => handleThumbnailClick(index)}
              >
                <img
                  src={`https://sellxa.com/backend/${image?.url}`}
                  alt="product thumbnail"
                  className="rounded-lg object-cover cursor-pointer w-full h-full"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  );
};

export default SwiperProducts;
