import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import facebook from "images/socials/facebook.svg";
import youtube from "images/socials/youtube.svg";
import tiktok from "images/socials/_Group_-22.svg";
import instagram from "images/socials/instagram.svg";
import imgLight from "images/logo.png";

const Footer = () => {
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);

  const GettingStartedWidget = () => (
    <div className="text-sm">
      <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
        {t("Getting started")}
      </h2>
      <ul className="mt-5 space-y-4">
        <li><Link to={`/${lang}`} className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">{t("Homepage")}</Link></li>
        <li><Link to={`/${lang}/shop`} className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">{t("Shop")}</Link></li>
        <li><Link to={`/${lang}/cart`} className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">{t("ShoppingCart")}</Link></li>
        <li><Link to={`/${lang}/signup`} className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">{t("Signup")}</Link></li>
      </ul>
    </div>
  );

  const ExploreWidget = () => (
    <div className="text-sm">
      <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
        {t("Explore")}
      </h2>
      <ul className="mt-5 space-y-4">
        <li><Link to={`/${lang}/privacy-usage`} className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">{t("PrivacyUsage")}</Link></li>
        <li><Link to={`/${lang}/privacy-policy`} className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">{t("PrivacyPolicy")}</Link></li>
        <li><Link to={`/${lang}/about-us`} className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">{t("AboutUs")}</Link></li>
        <li><Link to={`/${lang}/contact-us`} className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">{t("ContactUs")}</Link></li>
      </ul>
    </div>
  );

  const SocialWidget = () => (
    <div className="text-sm">
      <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
        {t("Social")}
      </h2>
      <ul className="mt-5 space-y-4">
        <li>
          <a href="https://www.facebook.com/sellxa.store" target="_blank" rel="noopener noreferrer" className="flex gap-2 text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
            <img src={facebook} alt="" style={{ width: "20px", height: "20px" }} />
            {t("facebook")}
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/channel/UCBDGUfPUBB-77A82-_VUktA" target="_blank" rel="noopener noreferrer" className="flex gap-2 text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
            <img src={youtube} alt="" style={{ width: "20px", height: "20px" }} />
            {t("youtube")}
          </a>
        </li>
        <li>
          <a href="https://www.tiktok.com/@sellxa.store" target="_blank" rel="noopener noreferrer" className="flex gap-2 text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
            <img src={tiktok} alt="" style={{ width: "20px", height: "20px" }} />
            {t("tiktok")}
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/sellxa.store/" target="_blank" rel="noopener noreferrer" className="flex gap-2 text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
            <img src={instagram} alt="" style={{ width: "20px", height: "20px" }} />
            {t("instagram")}
          </a>
        </li>
      </ul>
    </div>
  );

  return (
    <div
      className="lg:pt-28 lg:pb-0 pt-8 border-t border-neutral-200 dark:border-neutral-700"
      style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
    >
      <div className="hidden container md:grid grid-cols-1 md:grid-cols-4 gap-y-10 gap-x-5 sm:gap-x-8 lg:gap-x-10">
        <div className="flex flex-col gap-5 md:col-span-4 lg:col-span-1">
          <div className="flex justify-center lg:justify-start">
            <Link to="/" className="ttnc-logo inline-block text-slate-600">
              <img
                className="h-8 sm:h-10 w-auto"
                src={imgLight}
                alt="Logo-Light"
                width="150"
                height="32"
              />
            </Link>
          </div>
          <div className="text-center lg:text-left">
            <p>{t("SiteDescription")}</p>
          </div>
        </div>
        <GettingStartedWidget />
        <ExploreWidget />
        <SocialWidget />
      </div>
      <div className="md:hidden container grid grid-cols-1 md:grid-cols-4 gap-y-10 gap-x-5 sm:gap-x-8 lg:gap-x-10">
        <div className="flex flex-col gap-5 md:col-span-4 lg:col-span-1">
          <div className="flex justify-center lg:justify-start">
            <Link to="/" className="ttnc-logo inline-block text-slate-600">
              <img
                className="h-8 sm:h-10 w-auto"
                src={imgLight}
                alt="Logo-Light"
                width="150"
                height="32"
              />
            </Link>
          </div>
          <div className="text-center lg:text-left">
            <p>{t("SiteDescription")}</p>
          </div>
        </div>
        <GettingStartedWidget />
        <div className="flex gap-20">
          <ExploreWidget />
          <SocialWidget />
        </div>
      </div>
      <div className="text-center text-xs sm:text-sm leading-6 bg-slate-900 text-white py-4 mt-14">
        {t("footer")}
      </div>
    </div>
  );
};
export default Footer;