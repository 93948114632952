import rightImg from "images/hero-right1.png";
import React, { FC, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { PagesContext } from "components/contextApi/MetaContext";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { useTranslation } from "react-i18next";
import SectionHero from "containers/PageAbout/SectionHero";
import { useNavigate, useParams } from "react-router-dom"; // Import useParams
import { AuthContext } from "components/contextApi/AuthContext";
import { Order } from "components/contextApi/DataOrderContext";



const ThankYou = ({ className = "" }) => {

  useEffect(() => {
    // Function to send the API request
    const sendApiRequest = async () => {
      try {
        await fetch(
          `https://sellxa.com/backend/api/log-tracking/?event_name=thanks`,
          {
            method: 'GET',
            mode: 'cors', // This enables CORS
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*', // Allow all origins
            },
            credentials: 'include', // Include credentials if needed
          }
        );
      } catch (error) {
        console.error('Error sending API request:', error);
      }
    };

    // Call the function when the component mounts
    sendApiRequest();
  }, []);

  const { GetPage, dataPages } = useContext(PagesContext);
  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();
  const { orderId } = useParams();
  const { isAuth } = useContext(AuthContext);
  const { getOrders } = useContext(Order);
  const [loading, setLoading] = useState(true);


  const { orders } = useContext(Order);
  const navigate = useNavigate();

  useEffect(() => {
    // Ensure orders is defined and is an array before calling .find()
    console.log(orders, "orders");

    if (orders && Array.isArray(orders)) {
      const order = orders.find(o => o.order_number === orderId);

      // If no matching order is found, redirect to home
      if (!order) {
        navigate('/');
      }
    }
  }, [orders, orderId, navigate]);



  useEffect(() => {
    if (!isAuth) {
      navigate('/');
    }
  }, [])
  // const auth = AuthContext()
  // const { orders, getOrders } = useContext(Order);

  // useEffect(() => {
  //   getOrders();
  //   console.log(orders)
  // }, []);
  useEffect(() => {
    GetPage({ page_id: 15 }, lang);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getOrders();
      console.log(orders);
      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-white bg-opacity-80 inset-0 z-50">
        <div className="w-16 h-16 border-4 border-[#FC5E02] border-solid border-t-transparent rounded-full animate-spin"></div>
      </div>
    )
  }

  const description = dataPages?.meta_description;
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>{dataPages?.meta_title ?? ""}</title>
        <meta name="description" content={description} />
        <link rel="Canonical" href="/thanks" />
      </Helmet>

      {/* ======== BG GLASS ======== */}

      <BgGlassmorphism />

      <div className="container py-16 lg:pt-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          orderId={orderId}
          heading={`❤ ${t("thanksTitle")}`}
          btnText={t("Myorder")}
          href={"/account-my-order"}
          subHeading={t("thanksDes")}
          desc={t("thankYouDesc")}
        // "We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        />
      </div>
    </div>
  );
};

export default ThankYou;
