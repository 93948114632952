import { useState } from "react";
import { createContext } from "react";

import Cookies from "js-cookie";
import { useCallback } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import axios from "axios";
import { notify } from "components/utils/notify";
// import { Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState();
  const initialToken = Cookies.get("token");

  const [token, setToken] = useState(initialToken);

  const [userId, setUserId] = useState();
  const [tokenReset, setTokenReset] = useState();
  const { t: trans } = useTranslation();
  const handleSetToken = (t) => {
    if (t) {
      setToken(t);
      Cookies.set("token", t, { expires: 30 });
    } else {
      setToken(null);
      Cookies.remove("token");
    }
  };

  const login = async (body) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/users/login`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (!res.status) {
        throw new Error(res?.msg);
      }
      if (res.status === 200) {
        if (res.data.status === 101) {
          setUserInfo(res.data.data);
          handleSetToken(res.data.token);
        } else if (res.data.status === 100) {
          handleSetToken(null);
          throw Error(res.data.message);
        } else if (res.data.status === 200) {
          setUserInfo(res.data.data);
          handleSetToken(res.data.token);
        }
      } else {
        handleSetToken(null);
        throw Error(res.errors[0]);
      }
      notify(trans("LoggedInSuccessfully"), "success");
      return res.data;
    } catch (error) {
      // console.log(error.message)
      notify(error.message, "error");
    }
  };

  const checkLogin = useCallback(async () => {
    if (!token) return;
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/users/info`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      const data = res.data;
      if (!res) {
        console.error("Response not OK:", res.status, res.statusText);
        handleSetToken(null);
      } else {
        setUserInfo(data.data);
      }
    } catch (error) {
      handleSetToken(null);
    }
  }, [token]);

  const isMount = useRef(false);

  useEffect(() => {
    if (!isMount.current) {
      checkLogin();
      isMount.current = true;
    }
  }, [checkLogin]);

  const logout = () => {
    handleSetToken(null);
  };

  const [email, setEmail] = useState();

  return (
    <AuthContext.Provider
      value={{
        isAuth: !!token,
        login,
        logout,
        userInfo,
        token,
        checkLogin,
        userId,
        setUserId,
        tokenReset,
        setTokenReset,
        email,
        setEmail,
        handleSetToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
