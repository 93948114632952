import axios from "axios";
import Cookies from "js-cookie";

export const postRequest = async (body: any, endPoint: any) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}${endPoint}`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + Cookies.get("token_")!,
        },
      }
    );
    return response.data;
  } catch (err: any) {
    throw new Error(err.response.data.msg);
  }
};

export const putRequest = async (body: any, endPoint: any) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BASE_URL}${endPoint}`,
      body,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + Cookies.get("token_")!,
        },
      }
    );

    return response.data;
  } catch (err: any) {
    throw new Error(err.response.data.msg);
  }
};

export const getRequest = async (params: any, endPoint: any, lang: any) => {
  const langLocal = localStorage.getItem("lang") ?? 'ar';
  const headers = {
    Authorization: "Bearer " + Cookies.get("token")!,
    'Accept-Language': langLocal,
  };

  try {
    const response = axios
      .get(`${process.env.REACT_APP_BASE_URL}${endPoint}`, {headers, params })
      .then((response) => response.data);

    return response;
  } catch (err: any) {
    throw new Error(err.response.data.msg);
  }
};

export const deleteRequest = async (params: any, endPoint: any) => {
  const headers = {
    Authorization: "Bearer " + Cookies.get("token_")!,
    apiSecret: `${process.env.REACT_APP_API_SECRET}`,
  };

  const response = axios
    .delete(`${process.env.REACT_APP_BASE_URL}${endPoint}`, {
      headers,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw new Error(err);
    });

  return response;
};
