import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest } from "components/utils/fetchMethods";

// Thunk to get main categories
export const getMainCats = createAsyncThunk(
  'categories/getMainCats',
  async ({ params, lang }, { rejectWithValue }) => {
    try {
      const response = await getRequest(params, '/categories/main', lang);
      if (!response) {
        throw new Error(response.message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk to get sub categories based on selected main category ID
export const getSubCats = createAsyncThunk(
  'categories/getSubCats',
  async ({ params, lang, selectedMainId }, { rejectWithValue }) => {
    try {
      const response = await getRequest(params, `/categories/main/${selectedMainId}`, lang);
      if (!response) {
        throw new Error(response.message);
      }
      return response.sub_categories;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk to get sub categories from the new API endpoint
export const getSubCategory = createAsyncThunk(
  'categories/getSubCategory',
  async ({ _, lang }, { rejectWithValue }) => {
    try {
      const response = await getRequest({}, '/categories/sub', lang);
      if (!response) {
        throw new Error(response.message);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    mainCategories: [],
    selectedMainId: null,
    subCategories: [],
    selectedSubId: null,
    error: null,
  },
  reducers: {
    setSelectedMainId(state, action) {
      state.selectedMainId = action.payload;
    },
    setSelectedSubId(state, action) {
      state.selectedSubId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMainCats.fulfilled, (state, action) => {
        state.mainCategories = action.payload;
      })
      .addCase(getMainCats.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getSubCats.fulfilled, (state, action) => {
        state.subCategories = action.payload;
      })
      .addCase(getSubCats.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getSubCategory.fulfilled, (state, action) => {
        state.subCategories = action.payload;
      })
      .addCase(getSubCategory.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { setSelectedMainId, setSelectedSubId } = categoriesSlice.actions;

export default categoriesSlice.reducer;
