// slices/couponSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { notify } from "components/utils/notify";

// قراءة البيانات من localStorage وتعيينها كقيمة أولية
const getInitialData = () => {
  const storedData = localStorage.getItem('coupon_data'); 
  if (storedData) {
    return JSON.parse(storedData);
  }
  return
//    {
//     discount_code: null,
//     discount_status: null,
//     discount_cost: null,
//     discount_percentage: null,
//     maximum_discount: null,
//   };
};

// إنشاء الـ thunk لـ API call باستخدام Axios
export const checkCoupon = createAsyncThunk(
    "coupon/checkCoupon",
    async (params) => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/coupon/check`,
        { params }
      );
      if(!response.data.valid){
        notify('الكوبون غير فعال','error')
      }
      // console.log(response.data.valid);
      return response.data;
    }
);  
const couponSlice = createSlice({
  name: "coupon",
  initialState: {
    data: getInitialData(), // تعيين البيانات الأولية من localStorage
    status: "idle",
    error: null,
  },
  reducers: {
    removeCoupon: (state) => {
      // مسح البيانات من localStorage
      localStorage.removeItem('coupon_data');
      
      // تعيين الـ state إلى القيم الافتراضية
      state.data = null
      
    //   {
    //     discount_code:null,
    //     discount_status: null,
    //     discount_cost: null,
    //     discount_percentage: null,
    //     maximum_discount: null,
    //   };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkCoupon.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkCoupon.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;

        // التحقق من حالة الخصم وتخزين القيم في localStorage ككائن واحد
        const couponData = action.payload.data;
        const storageData = {
          discount_code: couponData.code ?? '',
          discount_status: couponData.discount_status,
          discount_cost: couponData.discount_status === 1 ? couponData.discount_cost : null,
          discount_percentage: couponData.discount_status === 0 ? couponData.discount_percentage : null,
          maximum_discount: couponData.discount_status === 0 ? couponData.maximum_discount : null,
        };

        localStorage.setItem('coupon_data', JSON.stringify(storageData));
      })
      .addCase(checkCoupon.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// تصدير الـ reducer الجديد
export const { removeCoupon } = couponSlice.actions;

export default couponSlice.reducer;