import React, { useEffect, useState, useRef, useContext } from "react";
import Label from "components/Label/Label";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { Order } from "components/contextApi/DataOrderContext";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import { AuthContext } from "components/contextApi/AuthContext";
import axios from "axios";
import { cartActions } from "components/rtk/slices/CartSlice";
import { removeCoupon } from "components/rtk/slices/couponApiSlice";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CartView from "./CartView";

const ShippingAddress = ({
  code,
  shipping,
  setChildConfirmClick,
  isActive,
  onCloseActive,
  onOpenActive,
  setShipping,
}) => {
  useEffect(() => {
    setChildConfirmClick(() => handleConfirmClick); // Provide the function reference
  }, [setChildConfirmClick]);

  const [countries, setCountries] = useState([]);
  const { setCountryId, setCountryName } = useContext(Order);
  const [cityError, setCityError] = useState(false);
  const [countryIdError, setCountryIdError] = useState(false);
  // State for errors
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const cartItems = useSelector((state) => state.cart);
  const { baseUrl, setOrdered } = useContext(Order);
  const { data, status, error } = useSelector((state) => state.coupon);

  const { token, isAuth, handleSetToken, checkLogin } = useContext(AuthContext);
  const {
    cityName,
    countryName,
    address,
    suite,
    name,
    phone,
    email,
    cityId,
    countryId,
    setAddress,
    setSuite,
    setName,
    setEmail,
    setPhone,
  } = useContext(Order);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const viewRef = useRef(null);

  const cartbody = cartItems.cartItems.map((item) => ({
    product_id: item?.stock?.product_id,
    quantity: item.quantity,
    price: item.totalPrice,
    image: item?.image[0]?.url,
    additional_orders: item.optionsSelectd.map((option) => ({
      table_name: option.name,
      table_id: option.id,
      price: option.price,
      quantity: 1,
    })),
  }));

  const handleUpdate = async () => {
    try {
      const res = await axios.put(
        `${baseUrl}/users/update`,
        {
          address,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res) {
        console.log("Information Updated Successfully");
      } else {
        console.log("Failed To Update Information");
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const submitOrder = async () => {
    try {
      // console.log("iam authentecated");
      if (cartbody && cartbody?.length > 0) {
        const res = await axios.post(
          `${baseUrl}/users/order/create`,
          {
            // total_price: cartItems.totalAmount + shipping,
            coupon_code: data?.data?.code,
            discount: 0,
            has_discount: false,
            request_details: "[]",
            cart_items: cartbody,
            shipping_address: {
              address_line: address,
              governorate_id: countryId,
              area_id: cityId,
              apartment_details: suite,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        // toast.success(t("order_sent_successfully"));
        dispatch(cartActions.clearCart());
        dispatch(removeCoupon());

        setOrdered(true);
        navigate(`/${lang}/thanks/${res?.data?.order?.order_number}`);

        await handleUpdate();
      } else {
        toast.error(t("EmptyCartMessage"));
      }
    } catch (error) {
      console.error("Error submitting order:", error);
    }
  };
  const submitGuestOrder = async () => {
    try {
      // console.log("iam just a thef");
      if (cartbody && cartbody?.length > 0) {
        const res = await axios.post(
          `${baseUrl}/users/order/create-ghost`,
          {
            // total_price: cartItems.totalAmount + shipping,
            full_name: name,
            email: email,
            phone_number: phone,
            coupon_code: data?.data?.code,
            discount: 0,
            has_discount: false,
            request_details: "[]",
            cart_items: cartbody,
            shipping_address: {
              address_line: address,
              governorate_id: countryId,
              area_id: cityId,
              apartment_details: suite,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
        );
        // toast.success(t("order_sent_successfully"));
        // console.log(res?.data?.token, "token");
        handleSetToken(res?.data?.token);

        dispatch(cartActions.clearCart());
        dispatch(removeCoupon());
        checkLogin();

        setOrdered(true);
        // navigate(`/${lang}/thanks`);
        navigate(`/${lang}/thanks/${res?.data?.order?.order_number}`);

        window.location.reload();

        // await handleUpdate();
      } else {
        toast.error(t("EmptyCartMessage"));
      }
    } catch (error) {
      console.error("Error submitting order:", error);
    }
  };

  // Validation function
  const validateInputs = () => {
    let valid = true;

    if (!name) {
      setNameError(true);
      valid = false;
    } else {
      setNameError(false);
    }

    if (!phone) {
      setPhoneError(true);
      valid = false;
    } else {
      // console.log(phone, "phone before validation");
      let phoneToValidate = phone || "";

      if (phoneToValidate.startsWith("+2")) {
        phoneToValidate = phoneToValidate.slice(2);
      }

      // console.log(phoneToValidate, "phone after validation");
      // Regex for Egyptian phone number
      const egyptPhoneRegex = /^(01[0-5])[0-9]{8}$/;

      if (!egyptPhoneRegex.test(phoneToValidate)) {
        setPhoneError(true);
        valid = false;
        // alert("رقم الهاتف غير صحيح"); // Alert for incorrect phone number
      } else {
        setPhoneError(false);
      }
    }

    if (!email) {
      setEmailError(true);
      valid = false;
    } else {
      setEmailError(false);
    }
    if (!cityId) {
      setCityError(true);
      valid = false;
    } else {
      setCityError(false);
    }
    if (!countryId) {
      setCountryIdError(true);
      valid = false;
    } else {
      setCountryIdError(false);
    }

    if (!address) {
      setAddressError(true);
      valid = false;
    } else {
      setAddressError(false);
    }

    return valid;
  };

  // Confirm button click handler
  const handleConfirmClick = () => {
    console.log("clicked");
    console.log(
      address,
      countryId,
      cityId,
      name,
      phone,
      email,
      suite,
      "details"
    );
    // let phoneToValidate = phone || "";

    // if (phoneToValidate.startsWith("+2")) {
    //   phoneToValidate = phoneToValidate.slice(2);
    //   // console.log(phone, "phone after validation");
    // }

    // setPhone(phoneToValidate);
    // console.log(phone, "phone after validation");
    // console.log(phoneToValidate, "phoneToValidate");

    if (isAuth) {
      onCloseActiveEdited();
      submitOrder();
    } else {
      if (validateInputs()) {
        submitGuestOrder();
      } else {
        // If validation fails, scroll to the view
        if (viewRef.current) {
          viewRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    }
  };

  const onCloseActiveEdited = () => {
    onCloseActive();
    if (!address || !countryId || !cityId) {
      if (!cityId) setCityError(true);
      if (!countryId) setCountryIdError(true);
      if (!address) setAddressError(true);
    } else {
      setCityError(false);
      setCountryIdError(false);
      setAddressError(false);
    }
  };

  const unmount = useRef(false);

  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);

  const [isCitiesLoading, setIsCitiesLoading] = useState(false);
  const [cities, setCities] = useState();
  const { setCityId, setCityName } = useContext(Order);
  useEffect(() => {
    const getAllCountries = async () => {
      try {
        const res = await axios.get(`${baseUrl}/country`, {
          headers: {
            "Accept-Language": lang,
          },
        });
        setCountries(res.data.data);
      } catch (error) {
        console.log(error.message);
      }
    };
    if (unmount.current === false) {
      getAllCountries();
      unmount.current = true;
    }
  }, [baseUrl, lang]);

  const getCountryName = () => {
    if (countryId && countries) {
      const country = countries.find((country) => +country.id === +countryId);
      if (country) {
        setCountryName(country.name);
      }
    }
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    // Ensure the phone input always starts with '2+'
    if (!input.startsWith("+20")) {
      setPhone("+20");
    } else {
      setPhone(input);
    }
  };
  const handleCountryChange = async (e) => {
    if (!countries) return;
    const selectedCountryId = e.target.value;
    const selectedCountry = countries.find(
      (country) => +country.id === +selectedCountryId
    );

    if (selectedCountry) {
      setCountryId(selectedCountryId);
      setShipping(selectedCountry.shipping || 0);

      // Clear city state
      setCityId("");
      setCities([]);

      // Start loading cities
      setIsCitiesLoading(true);

      try {
        // const timeoutId = setTimeout(() => controller.abort(), 10000); // 10 second timeout

        const res = await axios.get(`${baseUrl}/country/${selectedCountryId}`, {
          headers: {
            "Accept-Language": lang,
          },
        });

        // clearTimeout(timeoutId);

        if (res.data && Array.isArray(res.data.data)) {
          setCities(res.data.data);
          if (res.data.data.length === 0) {
            console.log(`No cities found for country ${selectedCountryId}`);
          }
        } else {
          console.error("Unexpected API response format", res.data);
          setCities([]);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request cancelled", error.message);
        } else {
          console.error("Error loading cities:", error);
        }
        setCities([]);
      } finally {
        setIsCitiesLoading(false);
      }
    } else {
      // If no country is selected, clear both country and city
      setCountryId("");
      setCityId("");
      setCities([]);
      setShipping(0);
    }
  };

  useEffect(() => {
    getCountryName();
  }, [countryId, countries, setCountryName]);

  // const unmountCity = useRef(false);

  const getAllCities = async (countryId) => {
    try {
      console.log("Selected Country ID:", countryId);

      const res = await axios.get(`${baseUrl}/country/${countryId}`, {
        headers: {
          "Accept-Language": lang,
        },
      });
      setCities(res.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (countryId) {
      getAllCities(countryId);
    }
  }, [countryId, baseUrl, lang]);

  const getCityName = () => {
    if (cityId) {
      setCityName(cities?.find((city) => +city?.id === +cityId).name);
    }
  };
  useEffect(() => {
    getCityName();
  }, [cityId, cities, setCityName]);

  const renderShippingAddress = () => {
    return (
      <>
        <div
          className="border border-slate-200 dark:border-slate-700 rounded-xl "
          style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
        >
          <div className="p-6 flex flex-col sm:flex-row items-start">
            <div>
              <h3 className=" text-slate-700 dark:text-slate-300 flex">
                <span className="uppercase">{t("ShippingAddress")}</span>
              </h3>
            </div>
            {/* <ButtonSecondary
            sizeClass="py-2 px-4 "
            fontSize="text-sm font-medium"
            className={`bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 ${
              lang === "ar" ? "sm:mr-auto" : "sm:ml-auto"
            } !rounded-lg`}
            onClick={onOpenActive}
          >
            {t("Change")}
          </ButtonSecondary> */}
          </div>
          <div
            className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
              isActive ? "block" : "hidden"
            }`}
          >
            <div className="flex flex-col sm:grid-cols-2 gap-4 sm:gap-3">
              <div>
                <Label className="text-sm">{t("Governorate")}*</Label>
                <Select
                  disabled={isCitiesLoading}
                  className={`mt-1.5 text-[#033341] rounded-md ${
                    countryIdError ? "border-red-500" : ""
                  }`}
                  onChange={
                    handleCountryChange
                    // console.log(e.target.value);
                    // setCountryId(e.target.value);
                    // setShipping(e.target.shipping)
                  }
                >
                  <option style={{ color: "#033341" }} value="">
                    {t("PleaseSelectGovernorate")}
                  </option>
                  {countries && countries.length > 0 ? (
                    countries.map((country) => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))
                  ) : (
                    <option value="">{t("Loading Countries")}</option>
                  )}
                </Select>
                {countryIdError && (
                  <p className="text-red-500 text-sm mt-1">
                    {t("input_country_error")}
                  </p>
                )}
              </div>
              <div>
                <Label className="text-sm">{t("City")}*</Label>
                <Select
                  required
                  disabled={isCitiesLoading || !countryId}
                  className={`mt-1.5 rounded-md text-[#033341] ${
                    cityError ? "border-red-500" : ""
                  }`}
                  onChange={(e) => {
                    setCityId(e.target.value);
                  }}
                >
                  <option style={{ color: "#033341" }} value="">
                    {t("PleaseSelectCity")}
                  </option>
                  {cities?.map((city) => (
                    <option
                      className="text-[#033341]"
                      key={city.id}
                      value={city.id}
                    >
                      {city.name}
                    </option>
                  ))}
                </Select>
                {cityError && (
                  <p className="text-red-500 text-sm mt-1">
                    {t("input_city_error")}
                  </p>
                )}
                {/* {isCitiesLoading && (
                  <div className="mt-2 flex items-center">
                    <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-900"></div>
                    <span className="ml-2 text-sm text-gray-500">
                      {t("LoadingCities")}
                    </span>
                  </div>
                )} */}
              </div>
            </div>
            <div ref={viewRef}>
              <Label className="text-sm">{t("Address")}*</Label>
              <Input
                placeholder={t("Address")}
                className="mt-1.5 placeholder:text-base rounded-md text-[#033341]"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
              {addressError && (
                <p className="text-red-500 text-sm mt-1">
                  {t("input_address_error")}
                </p>
              )}
            </div>
            {setSuite("NA")}

            {/* <div className="pt-2">
              <ButtonPrimary onClick={onCloseActiveEdited}>
                {t("confirm")}
              </ButtonPrimary>
            </div> */}
          </div>
        </div>
        {!isAuth && (
          <div
            className="border border-slate-200 dark:border-slate-700 rounded-xl mt-8"
            style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
          >
            <div className="p-6 flex flex-col sm:flex-row items-start">
              <div>
                <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                  <span className="uppercase">{t("personal_details")}</span>
                </h3>
              </div>
              {/* <ButtonSecondary
            sizeClass="py-2 px-4 "
            fontSize="text-sm font-medium"
            className={`bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 ${
              lang === "ar" ? "sm:mr-auto" : "sm:ml-auto"
            } !rounded-lg`}
            onClick={onOpenActive}
          >
            {t("Change")}
          </ButtonSecondary> */}
            </div>
            <div
              className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
                isActive ? "block" : "hidden"
              }`}
            >
              <div className="flex flex-col sm:grid-cols-2 gap-4 sm:gap-3">
                <div>
                  <Label className="text-sm">{t("input_name")}*</Label>
                  <Input
                    placeholder={t("input_name")}
                    className={`mt-1.5 placeholder:text-base rounded-md ${
                      nameError ? "border-red-500" : ""
                    }`}
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  {nameError && (
                    <p className="text-red-500 text-sm mt-1">
                      {t("input_name_error")}
                    </p>
                  )}
                </div>
                <div>
                  <Label className="text-sm">{t("input_phone")}*</Label>
                  <Input
                    style={{ direction: `${lang === "ar" ? "ltr" : "ltr"}` }}
                    placeholder={t("input_phone")}
                    className={`mt-1.5 placeholder:text-base ${
                      lang === "ar" ? "placeholder:text-right" : ""
                    }  rounded-md ${phoneError ? "border-red-500" : ""}`}
                    value={phone}
                    onChange={handlePhoneChange}
                  />
                  {phoneError && (
                    <p className="text-red-500 text-sm mt-1">
                      {t("input_phone_error")}
                    </p>
                  )}
                </div>
              </div>
              <div>
                <Label className="text-sm">{t("input_email")}*</Label>
                <Input
                  placeholder={t("input_email")}
                  className={`mt-1.5 placeholder:text-base rounded-md ${
                    emailError ? "border-red-500" : ""
                  }`}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="email"
                />
                {emailError && (
                  <p className="text-red-500 text-sm mt-1">
                    {t("input_email_error")}
                  </p>
                )}
              </div>
              {setSuite("NA")}
            </div>
          </div>
        )}
        <div className=" hidden max-lg:block mt-8">
          <CartView
            cartItems={cartItems}
            code={code}
            data={data}
            shipping={shipping}
          />
        </div>
        <div className="mt-8">
          <ButtonPrimary
            onClick={handleConfirmClick}
            className="block w-full bg-[#FC5E02] text-xl"
          >
            {t("confirm")}
          </ButtonPrimary>
        </div>
      </>
    );
  };

  return <>{renderShippingAddress()}</>;
};

export default ShippingAddress;
