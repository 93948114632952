import React, { useEffect, useState, createContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const [lang, setLang] = useState(() => {
    const storedLang = localStorage.getItem("lang");

    if (storedLang) {
      return storedLang;
    } else if (location.pathname.startsWith("/ar")) {
      return "ar";
    } else if (location.pathname.startsWith("/en")) {
      return "en";
    } else {
      return "ar"; // Default to Arabic if no language stored or path found
    }
  });

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.startsWith("/ar") || pathname.startsWith("/en")) {
      const newLang = pathname.substring(1, 3);
      setLang(newLang);
      localStorage.setItem("lang", newLang);
    }
  }, [location.pathname]);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const changeLanguage = (newLang) => {
    setLang(newLang);
    localStorage.setItem("lang", newLang);
    i18n.changeLanguage(newLang);
  };

  return (
    <LanguageContext.Provider value={{ lang, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
