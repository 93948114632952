import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
// import LikeButton from "./LikeButton";
import Prices from "./Prices";
import {
  ArrowsPointingOutIcon,
  PlusCircleIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
// import { Product, LABTOP_PRODUCTS } from "data/data";
import { PlusIcon, StarIcon } from "@heroicons/react/24/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import BagIcon from "./BagIcon";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import ModalQuickView from "./ModalQuickView";
import ProductStatus from "./ProductStatus";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "./rtk/slices/CartSlice";
import { LanguageContext } from "./contextApi/ChangeLanguage";
import { useTranslation } from "react-i18next";
import "./CustomSlider.css";

// import imgTest from 'images/products/lab3.jpg'

const ProductCard = ({ className = "", data, isLiked = false }) => {
  const { sizes, variants, status, stock } = data;
  const cartItems = useSelector((state) => state.cart);
  const productInState = cartItems?.cartItems?.find(
    (item) => Number(item?.stock?.product_id) === Number(stock?.product_id)
  );
  const [variantActive] = React.useState(0);
  const [showModalQuickView, setShowModalQuickView] = React.useState(false);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const apiCall = async () => {
  //     try {
  //       const res = await fetch(
  //         "https://sellxa.com/backend/api/log-tracking/?event_name=product"
  //       );
  //       console.log(res);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   apiCall();
  // });

  // console.log(data,"dataCard");

  const formattedString = data?.data?.name;

  // const productName = data?.product?.name ?? "";
  // const processorName = data?.processor?.name ?? "";
  // const processorCore = data?.processor?.cores ?? "";
  // const processorBrand = processorName + " " + processorCore;
  // const processorArchitecture = data?.processor?.architecture ?? "";
  // const thermalSolution = data?.processor?.thermal_solution ?? "";
  // const ramType =
  //   data?.ram_type?.capacity &&
  //   `${data?.ram_type?.capacity ? data?.ram_type?.capacity + "GB" : ""} ${
  //     data?.ram_type?.name ?? ""
  //   }`;
  // const color = data?.color?.name ?? "";
  // const displaySize =
  //   (data?.display_size?.display_size ?? "") +
  //   " " +
  //   (data?.display_size?.resolution ?? "") +
  //   " " +
  //   (data?.display_size?.screen_type ?? "");
  // const hardDriveType =
  //   data?.hard_drive?.type && `${data?.hard_drive?.type ?? ""}`;
  // const hardDriveCapacity =
  //   data?.hard_drive?.capacity &&
  //   `${data?.hard_drive?.capacity ?? ""}GB ${hardDriveType}`;

  // const formattedString = `${productName ? productName + "," : ""}
  // ${hardDriveCapacity ? hardDriveCapacity + "," : ""}
  // ${ramType ? ramType + "," : ""}
  // ${processorBrand ? processorBrand + "," : ""}
  //    ${processorArchitecture ? processorArchitecture + "," : ""} ${
  //   thermalSolution ? thermalSolution + "," : ""
  // }  ${color ? color + "," : ""} ${displaySize ?? ""}`;

  const { lang } = useContext(LanguageContext);
  const { t, t: trans } = useTranslation();
  const notifyAddTocart = ({ size }) => {
    const toastId = toast.custom(
      (t) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-slate-200 dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p
            className={`block text-base font-semibold leading-none ${
              lang === "ar" ? "text-right" : "text-left"
            }`}
          >
            {trans("Added_to_cart")}
          </p>
          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          {renderProductCartOnNotify({ size, toastId })}
        </Transition>
      ),
      { position: "top-right", id: "nc-product-notify", duration: 2000 }
    );

    // Close toast on scroll
    const handleScroll = () => {
      toast.dismiss(toastId);
      window.removeEventListener("scroll", handleScroll);
    };
    window.addEventListener("scroll", handleScroll);
  };

  const renderProductCartOnNotify = ({ size, toastId }) => {
    return (
      <div
        className={`flex`}
        style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
      >
        <div className="h-16 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={`https://sellxa.com/backend/${data?.image[0]?.url}`}
            alt={"productImg"}
            className="h-full w-full object-contain object-center"
            style={{ width: "100%", height: "100%" }}
          />
        </div>

        <div
          className={`${lang === "ar" ? "mr-4" : "ml-4"}  flex flex-1 flex-col`}
        >
          <div>
            <div className="flex justify-between mb-3">
              <div className="w-[60%] md:w-[70%]">
                <h3 className="text-[9px] md:text-[12px] leading-4 md:leading-5 font-semibold line-clamp-3">
                  {formattedString}
                </h3>
              </div>
              <Prices
                price={data?.stock ? +data.stock.price : 0}
                className="mt-0.5"
              />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400">
              {t("Qty_Added")} 1
            </p>
            <p className="text-gray-500 dark:text-slate-400">
              {t("Total_Qty")}{" "}
              {productInState?.quantity ? +productInState?.quantity + 1 : 1}
            </p>
            <div className="flex">
              <Link
                to={`/${lang}/cart`}
                className="font-medium text-primary-6000 dark:text-primary-500"
                onClick={() => toast.dismiss(toastId)}
              >
                {t("Viewcart")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };
  // const notifyAddTocart = ({ size }) => {
  //   toast.custom(
  //     (t) => (
  //       <Transition
  //         appear
  //         show={t.visible}
  //         className="p-4 max-w-md w-full bg-slate-200 dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
  //         enter="transition-all duration-150"
  //         enterFrom="opacity-0 translate-x-20"
  //         enterTo="opacity-100 translate-x-0"
  //         leave="transition-all duration-150"
  //         leaveFrom="opacity-100 translate-x-0"
  //         leaveTo="opacity-0 translate-x-20"
  //       >
  //         <p
  //           className={`block text-base font-semibold leading-none ${
  //             lang === "ar" ? "text-right" : "text-left"
  //           }`}
  //         >
  //           {trans("Added_to_cart")}
  //         </p>
  //         <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
  //         {renderProductCartOnNotify({ size })}
  //       </Transition>
  //     ),
  //     { position: "top-right", id: "nc-product-notify", duration: 2000 }
  //   );
  // };

  // const renderProductCartOnNotify = ({ size }) => {
  //   return (
  //     <div
  //       className={`flex`}
  //       style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
  //     >
  //       <div className="h-16 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
  //         <img
  //           src={`https://sellxa.com/backend/${data?.image[0]?.url}`}
  //           // src={imgTest}
  //           alt={"productImg"}
  //           className="h-full w-full object-contain object-center"
  //         />
  //       </div>

  //       <div
  //         className={`${lang === "ar" ? "mr-4" : "ml-4"}  flex flex-1 flex-col`}
  //       >
  //         <div>
  //           <div className="flex justify-between mb-3">
  //             <div className="w-[60%] md:w-[70%]">
  //               <h3 className="text-[9px] md:text-[12px] leading-4 md:leading-5 font-semibold line-clamp-3">
  //                 {formattedString}
  //               </h3>
  //             </div>
  //             <Prices
  //               price={data?.stock ? +data.stock.price : 0}
  //               className="mt-0.5"
  //             />
  //           </div>
  //         </div>
  //         <div className="flex flex-1 items-end justify-between text-sm">
  //           <p className="text-gray-500 dark:text-slate-400">
  //             {t("Qty_Added")} 1
  //           </p>
  //           <p className="text-gray-500 dark:text-slate-400">
  //             {t("Total_Qty")}{" "}
  //             {productInState?.quantity ? +productInState?.quantity + 1 : 1}
  //           </p>
  //           <div className="flex">
  //             <Link
  //               to={`/${lang}/cart`}
  //               className="font-medium text-primary-6000 dark:text-primary-500 "
  //             >
  //               {t("Viewcart")}
  //             </Link>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderGroupButtons = () => {
    // console.log(data,"data");
    return (
      <div className="hidden absolute bottom-0 group-hover:bottom-4 inset-x-1 md:flex justify-center opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all">
        {/* <ButtonPrimary
          className="shadow-lg"
          fontSize="text-xs"
          sizeClass="py-2 px-4"
          onClick={() => {
            if (
              (!productInState &&
                data.data.is_available === 1 &&
                data.stock.quantity > 0) ||
              (data?.stock?.quantity > productInState?.quantity &&
                data.data.is_available === 1 &&
                data.stock.quantity > 0)
            ) {
              notifyAddTocart({ size: "" });
              dispatch(cartActions.addItem({ product: data }));
            } else {
              toast.error(t("ProductLimitReached"));
            }
          }}
        >
          <BagIcon className="w-3.5 h-3.5 mb-0.5" />
          <span className="ml-1">{t("Add_bag")}</span>
        </ButtonPrimary> */}
        <ButtonSecondary
          className="ml-4 bg-white hover:!bg-gray-100 hover:text-slate-900 transition-colors shadow-lg"
          fontSize="text-xs"
          sizeClass="py-2 px-4"
          onClick={() => setShowModalQuickView(true)}
        >
          <ArrowsPointingOutIcon className="w-3.5 h-3.5" />
          <span className="ml-1">{t("Quick_view")}</span>
        </ButtonSecondary>
      </div>
    );
  };

  const renderSizeList = () => {
    if (!sizes || !sizes.length) {
      return null;
    }

    return (
      <div className="absolute bottom-0 inset-x-1 space-x-1.5 flex justify-center opacity-0 invisible group-hover:bottom-4 group-hover:opacity-100 group-hover:visible transition-all">
        {sizes.map((size, index) => {
          return (
            <div
              key={index}
              className="nc-shadow-lg w-10 h-10 rounded-xl bg-white hover:bg-slate-900 hover:text-white transition-colors cursor-pointer flex items-center justify-center uppercase font-semibold tracking-tight text-sm text-slate-900"
              onClick={() => notifyAddTocart({ size })}
            >
              {size}
            </div>
          );
        })}
      </div>
    );
  };

  // const {t} = useTranslation()
  const price = data?.stock ? +data.stock.price : 0;
  const priceAfterDiscount = data?.stock?.price_before_discount ?? null;
  const [activeIndex, setActiveIndex] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (data?.stock?.in_stock < 3 && data?.stock?.in_stock > 0) {
  //       setActiveIndex(0);
  //     } else {
  //       setActiveIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
  //     }
  //   }, 3000); // Change every 3 seconds
  //   return () => clearInterval(interval);
  // }, []);
  useEffect(() => {
    // Only start the interval if the stock is between 1 and 2
    console.log(data?.stock?.quantity, "stock");
    if (
      data?.stock?.quantity < 3 &&
      data?.stock?.quantity > 0 &&
      data?.stock?.in_stock !== 0
    ) {
      const interval = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? 1 : 0));
      }, 3000); // Change every 3 seconds

      return () => clearInterval(interval); // Clean up the interval on unmount or data change
    }
  }, [data?.stock?.in_stock]); // Add dependency to ensure it updates only when stock changes

  return (
    <>
      <div
        className={`nc-ProductCard relative flex flex-col bg-transparent ${className} border-gray-300 rounded-lg`}
        data-nc-id="ProductCard"
        style={{ borderWidth: "1px" }}
      >
        <Link
          to={`/${lang}/${data?.data?.main_category?.name}/${data?.data?.link}`}
          className="absolute inset-0"
        ></Link>

        {/* bg-[#F3F4F6] */}
        {/* dark:bg-[#1e293b]  */}
        <div className="relative flex-shrink-0  rounded-xl overflow-hidden z-1 group">
          {/* bg-slate-50 dark:bg-slate-300 */}
          <Link
            to={`/${lang}/${data?.data?.main_category?.name}/${data?.data?.link}`}
            className="block"
          >
            <NcImage
              containerClassName=" pt-10"
              // containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
              src={`https://sellxa.com/backend/${data?.image[0]?.url}`}
              // src={imgTest}
              className="object-contain w-full h-full"
              // drop-shadow-xl
            />
          </Link>

          <ProductStatus status={data ?? ""} />

          {/* <LikeButton liked={isLiked} className="absolute top-3 right-3 z-10" /> */}
          {renderGroupButtons()}
          {/*{renderSizeList()}
          {sizes ? renderSizeList() : renderGroupButtons()} */}
        </div>

        <div className=" px-2.5 pt-5 pb-2.5">
          {/* {renderVariants()} */}

          <div>
            <h2
              className={`text-[12px] leading-4 md:leading-5 nc-ProductCard__title ${
                lang === "ar" ? "text-right" : "text-base"
              } font-bold transition-colors line-clamp-2 md:line-clamp-2`}
              style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
            >
              {formattedString}
            </h2>
            {/* <p className={`text-sm text-slate-500 dark:text-slate-400 mt-1 `}>
              {data?.product?.additional_features}
            </p> */}
          </div>
          <div
            className={`flex flex-col justify-between items-start 
              `}
            // ${lang === "ar" ? "flex-row-reverse" : "flex-row"}
          >
            {/* <Prices price={data?.stock ? +data?.stock?.price : 0} /> */}
            <div className="flex items-start gap-1">
              <span className="text-[12px] md:text-lg font-bold text-[#3BB77E]">
                {price.toFixed(0)} {t("EGP")}
              </span>
              {priceAfterDiscount && (
                <span className="text-[8px] md:text-sm line-through text-[#ADADAD]">
                  {priceAfterDiscount.toFixed(0)} {t("EGP")}
                </span>
              )}
            </div>
            {/* <div className="h-[16px] mb-5 rounded-sm px-1 mt-1 overflow-hidden">
              <div className=" h-[16px] bg-[#1500FF1A]/10 mb-5 rounded-sm flex items-center justify-between gap-1 px-1  ">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M11 7V8.5C11 9.33 10.33 10 9.5 10H9C9 9.45 8.55 9 8 9C7.45 9 7 9.45 7 10H5C5 9.45 4.55 9 4 9C3.45 9 3 9.45 3 10H2.5C1.67 10 1 9.33 1 8.5V7H6.5C7.05 7 7.5 6.55 7.5 6V2.5H8.42001C8.78001 2.5 9.11001 2.695 9.29001 3.005L10.145 4.5H9.5C9.225 4.5 9 4.725 9 5V6.5C9 6.775 9.225 7 9.5 7H11Z"
                    fill="#1400FF"
                  />
                  <path
                    d="M4 11C4.55228 11 5 10.5523 5 10C5 9.44772 4.55228 9 4 9C3.44772 9 3 9.44772 3 10C3 10.5523 3.44772 11 4 11Z"
                    fill="#1400FF"
                  />
                  <path
                    d="M8 11C8.55228 11 9 10.5523 9 10C9 9.44772 8.55228 9 8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11Z"
                    fill="#1400FF"
                  />
                  <path
                    d="M11 6.26501V7H9.5C9.225 7 9 6.775 9 6.5V5C9 4.725 9.225 4.5 9.5 4.5H10.145L10.87 5.76999C10.955 5.91999 11 6.09001 11 6.26501Z"
                    fill="#1400FF"
                  />
                  <path
                    d="M7 1H3C2.025 1 1.21501 1.7 1.04001 2.625H3.5C3.705 2.625 3.875 2.795 3.875 3C3.875 3.205 3.705 3.375 3.5 3.375H1V4.125H2.5C2.705 4.125 2.875 4.295 2.875 4.5C2.875 4.705 2.705 4.875 2.5 4.875H1V5.625H1.5C1.705 5.625 1.875 5.795 1.875 6C1.875 6.205 1.705 6.375 1.5 6.375H1V7H6.5C7.05 7 7.5 6.55 7.5 6V1.5C7.5 1.225 7.275 1 7 1Z"
                    fill="#1400FF"
                  />
                  <path
                    d="M1.04001 2.625H0.5C0.295 2.625 0.125 2.795 0.125 3C0.125 3.205 0.295 3.375 0.5 3.375H1V3C1 2.87 1.01501 2.745 1.04001 2.625Z"
                    fill="#1400FF"
                  />
                  <path
                    d="M0.5 4.125C0.295 4.125 0.125 4.295 0.125 4.5C0.125 4.705 0.295 4.875 0.5 4.875H1V4.125H0.5Z"
                    fill="#1400FF"
                  />
                  <path
                    d="M0.5 5.625C0.295 5.625 0.125 5.795 0.125 6C0.125 6.205 0.295 6.375 0.5 6.375H1V5.625H0.5Z"
                    fill="#1400FF"
                  />
                </svg>
                <span
                  style={{ fontWeight: "400" }}
                  className="text-[8px] font-bold text-[#1500FF]"
                >
                  شحن سريع
                </span>
              </div>
              <div className=" h-[16px] bg-[#FD7C311A]/10 mb-5 rounded-sm flex items-center justify-between gap-1 px-1  ">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M9.17499 9.84C8.89999 10.545 8.22999 11 7.47499 11H4.52499C3.76499 11 3.09999 10.545 2.82499 9.84C2.54999 9.13 2.73999 8.345 3.29999 7.835L5.32499 6H6.67999L8.69999 7.835C9.25999 8.345 9.44499 9.13 9.17499 9.84Z"
                    fill="#FD7D32"
                  />
                  <path
                    d="M6.91 9.07H5.09C4.9 9.07 4.75 8.915 4.75 8.73C4.75 8.54 4.905 8.39 5.09 8.39H6.91C7.1 8.39 7.25 8.545 7.25 8.73C7.25 8.915 7.095 9.07 6.91 9.07Z"
                    fill="#FD7D32"
                  />
                  <path
                    d="M9.17496 2.16C8.89996 1.455 8.22996 1 7.47496 1H4.52496C3.76996 1 3.09996 1.455 2.82496 2.16C2.55496 2.87 2.73996 3.655 3.30496 4.165L5.32496 6H6.67996L8.69996 4.165C9.25996 3.655 9.44496 2.87 9.17496 2.16ZM6.90996 3.615H5.08996C4.89996 3.615 4.74996 3.46 4.74996 3.275C4.74996 3.09 4.90496 2.935 5.08996 2.935H6.90996C7.09996 2.935 7.24996 3.09 7.24996 3.275C7.24996 3.46 7.09496 3.615 6.90996 3.615Z"
                    fill="#FD7D32"
                  />
                </svg>

                <span
                  style={{ fontWeight: "400" }}
                  className="text-[8px] font-bold text-[##FD792D]"
                >
                  باقي قطعه واحده
                </span>
              </div>
            </div> */}
            <div className="custom-slider-wrapper flex items-start">
              <div
                className={`custom-slider-content ${
                  activeIndex === 0 ? "custom-active-slide" : ""
                }`}
              >
                <div className="h-[16px] bg-[#1500FF1A]/10 mb-5 rounded-sm flex items-center justify-between gap-1 px-1 mt-1">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M11 7V8.5C11 9.33 10.33 10 9.5 10H9C9 9.45 8.55 9 8 9C7.45 9 7 9.45 7 10H5C5 9.45 4.55 9 4 9C3.45 9 3 9.45 3 10H2.5C1.67 10 1 9.33 1 8.5V7H6.5C7.05 7 7.5 6.55 7.5 6V2.5H8.42001C8.78001 2.5 9.11001 2.695 9.29001 3.005L10.145 4.5H9.5C9.225 4.5 9 4.725 9 5V6.5C9 6.775 9.225 7 9.5 7H11Z"
                      fill="#1400FF"
                    />
                    <path
                      d="M4 11C4.55228 11 5 10.5523 5 10C5 9.44772 4.55228 9 4 9C3.44772 9 3 9.44772 3 10C3 10.5523 3.44772 11 4 11Z"
                      fill="#1400FF"
                    />
                    <path
                      d="M8 11C8.55228 11 9 10.5523 9 10C9 9.44772 8.55228 9 8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11Z"
                      fill="#1400FF"
                    />
                    <path
                      d="M11 6.26501V7H9.5C9.225 7 9 6.775 9 6.5V5C9 4.725 9.225 4.5 9.5 4.5H10.145L10.87 5.76999C10.955 5.91999 11 6.09001 11 6.26501Z"
                      fill="#1400FF"
                    />
                    <path
                      d="M7 1H3C2.025 1 1.21501 1.7 1.04001 2.625H3.5C3.705 2.625 3.875 2.795 3.875 3C3.875 3.205 3.705 3.375 3.5 3.375H1V4.125H2.5C2.705 4.125 2.875 4.295 2.875 4.5C2.875 4.705 2.705 4.875 2.5 4.875H1V5.625H1.5C1.705 5.625 1.875 5.795 1.875 6C1.875 6.205 1.705 6.375 1.5 6.375H1V7H6.5C7.05 7 7.5 6.55 7.5 6V1.5C7.5 1.225 7.275 1 7 1Z"
                      fill="#1400FF"
                    />
                    <path
                      d="M1.04001 2.625H0.5C0.295 2.625 0.125 2.795 0.125 3C0.125 3.205 0.295 3.375 0.5 3.375H1V3C1 2.87 1.01501 2.745 1.04001 2.625Z"
                      fill="#1400FF"
                    />
                    <path
                      d="M0.5 4.125C0.295 4.125 0.125 4.295 0.125 4.5C0.125 4.705 0.295 4.875 0.5 4.875H1V4.125H0.5Z"
                      fill="#1400FF"
                    />
                    <path
                      d="M0.5 5.625C0.295 5.625 0.125 5.795 0.125 6C0.125 6.205 0.295 6.375 0.5 6.375H1V5.625H0.5Z"
                      fill="#1400FF"
                    />
                  </svg>
                  <span
                    style={{ fontWeight: "400" }}
                    className="text-[8px] md:text-sm font-bold text-[#1500FF]"
                  >
                    شحن سريع
                  </span>
                </div>
              </div>
              {data?.stock?.quantity < 3 &&
              data?.stock?.quantity > 0 &&
              data?.stock?.in_stock !== 0 ? (
                <div
                  className={`custom-slider-content ${
                    activeIndex === 1 ? "custom-active-slide" : ""
                  }`}
                >
                  <div className="h-[16px] bg-[#FD7C311A]/10 mb-5 rounded-sm flex items-center justify-between gap-1 px-1 mt-1">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M9.17499 9.84C8.89999 10.545 8.22999 11 7.47499 11H4.52499C3.76499 11 3.09999 10.545 2.82499 9.84C2.54999 9.13 2.73999 8.345 3.29999 7.835L5.32499 6H6.67999L8.69999 7.835C9.25999 8.345 9.44499 9.13 9.17499 9.84Z"
                        fill="#FD7D32"
                      />
                      <path
                        d="M6.91 9.07H5.09C4.9 9.07 4.75 8.915 4.75 8.73C4.75 8.54 4.905 8.39 5.09 8.39H6.91C7.1 8.39 7.25 8.545 7.25 8.73C7.25 8.915 7.095 9.07 6.91 9.07Z"
                        fill="#FD7D32"
                      />
                      <path
                        d="M9.17496 2.16C8.89996 1.455 8.22996 1 7.47496 1H4.52496C3.76996 1 3.09996 1.455 2.82496 2.16C2.55496 2.87 2.73996 3.655 3.30496 4.165L5.32496 6H6.67996L8.69996 4.165C9.25996 3.655 9.44496 2.87 9.17496 2.16ZM6.90996 3.615H5.08996C4.89996 3.615 4.74996 3.46 4.74996 3.275C4.74996 3.09 4.90496 2.935 5.08996 2.935H6.90996C7.09996 2.935 7.24996 3.09 7.24996 3.275C7.24996 3.46 7.09496 3.615 6.90996 3.615Z"
                        fill="#FD7D32"
                      />
                    </svg>
                    <span
                      style={{ fontWeight: "400" }}
                      className="text-[8px] md:text-sm font-bold text-[#FD792D]"
                    >
                      باقي قطعه واحده
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
            <ButtonPrimary
              style={
                data?.stock?.in_stock === 1
                  ? {
                      background:
                        "linear-gradient(130.77deg, #FC5E02 33.91%, #FFFFFF 265.33%)",
                      width: "100%",
                      height: "38px",
                    }
                  : {
                      background: "gray",
                      width: "100%",
                      height: "38px",
                    }
              }
              className="md:hidden flex justify-center items-center text-center rounded-md py-1 px-2"
              fontSize="text-xs"
              sizeClass="py-1 px-1"
              onClick={() => {
                if (
                  (!productInState &&
                    data.data.is_available === 1 &&
                    data.stock.quantity > 0) ||
                  (data?.stock?.quantity > productInState?.quantity &&
                    data.data.is_available === 1 &&
                    data.stock.quantity > 0)
                ) {
                  notifyAddTocart({ size: "" });
                  dispatch(cartActions.addItem({ product: data }));
                } else {
                  toast.error(t("ProductLimitReached"));
                }
              }}
            >
              <span className={`${lang === "en" ? "ml-1" : "mr-1"}`}>
                {t("Add_bag")}
              </span>
              {/* <ShoppingCartIcon className="w-6 h-4" /> */}
              {/* <span className="ml-1">{t("Add_bag")}</span> */}
            </ButtonPrimary>
            <ButtonPrimary
              style={
                data?.stock?.in_stock === 1
                  ? {
                      background:
                        "linear-gradient(130.77deg, #FC5E02 33.91%, #FFFFFF 265.33%)",
                      width: "100%",
                      height: "38px",
                    }
                  : {
                      background: "gray",
                      width: "100%",
                      height: "38px",
                    }
              }
              className={`hidden md:flex rounded-md
                  ${
                    data.data.is_available === 1 &&
                    data.stock.quantity > 0 &&
                    (!productInState ||
                      data.stock.quantity > productInState.quantity)
                      ? "!bg-gray-500 hover:!bg-gray-600"
                      : "opacity-50"
                  }
                `}
              fontSize="text-xs"
              sizeClass="py-2 px-4"
              disabled={
                data.data.is_available === 1 &&
                data.stock.quantity > 0 &&
                (!productInState ||
                  data.stock.quantity > productInState.quantity)
                  ? false
                  : true
              }
              onClick={() => {
                if (
                  (!productInState &&
                    data.data.is_available === 1 &&
                    data.stock.quantity > 0) ||
                  (data?.stock?.quantity > productInState?.quantity &&
                    data.data.is_available === 1 &&
                    data.stock.quantity > 0)
                ) {
                  notifyAddTocart({ size: "" });
                  dispatch(cartActions.addItem({ product: data }));
                } else {
                  toast.error(t("ProductLimitReached"));
                }
              }}
            >
              {/* <BagIcon className="w-3.5 h-3.5 mb-0.5" /> */}
              <span className={`${lang === "en" ? "ml-1" : "mr-1"}`}>
                {t("Add_bag")}
              </span>
            </ButtonPrimary>
            {/* <div className="flex items-center mb-0.5">
              <StarIcon className="w-5 h-5 pb-[1px] text-amber-400" />
              <span className="text-sm ml-1 text-slate-500 dark:text-slate-400">
                {(Math.random() * 1 + 4).toFixed(1)} (
                {Math.floor(Math.random() * 70 + 20)} {t("reviews")})
              </span>
            </div> */}
          </div>
        </div>
      </div>

      {/* QUICKVIEW */}
      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        item={data ?? {}}
      />
    </>
  );
};

export default ProductCard;
