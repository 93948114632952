import { getRequest } from "components/utils/fetchMethods";
import { useState } from "react";
import { createContext } from "react";

export const Order = createContext();

export const OrderProvider = ({ children }) => {
  const [countryId, setCountryId] = useState();
  const [countryName, setCountryName] = useState();
  const [cityId, setCityId] = useState();
  const [cityName, setCityName] = useState();
  const [address, setAddress] = useState();
  const [suite, setSuite] = useState();
  const [name, setName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();

  const [ordered, setOrdered] = useState(false);

  // const baseUrl = 'https://x-freee.com/laptop/back-end/api';
  const baseUrl = "https://sellxa.com/backend/api";

  const [orders, setOrders] = useState();

  const getOrders = async (params, lang) => {
    try {
      const response = await getRequest(params, `/users/order`, lang);
      if (!response) {
        throw new Error(response?.message);
      }
      setOrders(response?.orders);
      // return response;
    } catch (error) {
      console.log(error);
      // notify("Error fetching data:", "error");
    }
  };
  return (
    <Order.Provider
      value={{
        baseUrl,
        countryId,
        setCountryId,
        countryName,
        setCountryName,
        cityId,
        setCityId,
        cityName,
        setCityName,
        address,
        setAddress,
        suite,
        setSuite,
        orders,
        getOrders,
        ordered,
        setOrdered,
        name,
        setName,
        phone,
        setPhone,
        email,
        setEmail,
      }}
    >
      {children}
    </Order.Provider>
  );
};
