import React, { useContext } from "react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
// import Logo from "shared/Logo/Logo";
import { Disclosure } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import { NavItemType } from "./NavigationItem";
// import { NAVIGATION_DEMO_2 } from "data/navigation";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode2";
import { useTranslation } from "react-i18next";
import ncNanoId from "utils/ncNanoId";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { AuthContext } from "components/contextApi/AuthContext";
import { removeCoupon } from "components/rtk/slices/couponApiSlice";
import { useDispatch } from "react-redux";
import logoImg from "images/logo.png";
import lightLogo from "images/logo-light.png";

import { Link } from "react-router-dom";
import LangDropdown from "components/Header/LangDropdown";
import AvatarDropdown from "components/Header/AvatarDropdown";
import Avatar from "shared/Avatar/Avatar";
// import LangDropdown from "components/Header/LangDropdown";
export interface NavMobileProps {
  data?: NavItemType[];
  onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
  // data = NAVIGATION_DEMO_2,
  onClickClose,
}) => {
  const dispatch = useDispatch();

  const { logout } = useContext(AuthContext);
  const { userInfo } = useContext(AuthContext);


  const _renderMenuChild = (
    item: NavItemType,
    itemClass = " pl-3 text-neutral-900 dark:text-neutral-200 font-medium "
  ) => {
    return (
      <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
        {item.children?.map((i, index) => (
          <Disclosure key={i.href + index} as="li">
            <NavLink
              to={{
                pathname: i.href || undefined,
              }}
              className={({ isActive }) =>
                `flex text-sm rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass} ${isActive ? "text-secondary" : ""
                }`
              }
            >
              <span
                className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                onClick={onClickClose}
              >
                {i.name}
              </span>
              {i.children && (
                <span
                  className="flex items-center flex-grow"
                  onClick={(e) => e.preventDefault()}
                >
                  <Disclosure.Button
                    as="span"
                    className="flex justify-end flex-grow"
                  >
                    <ChevronDownIcon
                      className="ml-2 h-4 w-4 text-slate-500"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
              )}
            </NavLink>
            {i.children && (
              <Disclosure.Panel>
                {_renderMenuChild(
                  i,
                  "pl-3 text-slate-600 dark:text-slate-400 "
                )}
              </Disclosure.Panel>
            )}
          </Disclosure>
        ))}
      </ul>
    );
  };

  const _renderItem = (item: NavItemType, index: number) => {
    return (
      <Disclosure
        key={index}
        as="li"
        className="text-slate-900 dark:text-white"
      >
        <NavLink
          className={({ isActive }) =>
            `flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg ${isActive ? "text-secondary" : ""
            }`
          }
          to={{
            pathname: item.href || undefined,
          }}
        >
          <span
            className={!item.children ? "block w-full" : ""}
            onClick={onClickClose}
          >
            {item.name}
          </span>
          {item.children && (
            <span
              className="block flex-grow"
              onClick={(e) => e.preventDefault()}
            >
              <Disclosure.Button
                as="span"
                className="flex justify-end flex-grow"
              >
                <ChevronDownIcon
                  className="ml-2 h-4 w-4 text-neutral-500"
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
          )}
        </NavLink>
        {item.children && (
          <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
        )}
      </Disclosure>
    );
  };

  // const renderMagnifyingGlassIcon = () => {
  //   return (
  //     <svg
  //       width={22}
  //       height={22}
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
  //         stroke="currentColor"
  //         strokeWidth="1.5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //       <path
  //         d="M22 22L20 20"
  //         stroke="currentColor"
  //         strokeWidth="1.5"
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       />
  //     </svg>
  //   );
  // };

  // const renderSearchForm = () => {
  //   return (
  //     <form
  //       action=""
  //       method="POST"
  //       className="flex-1 text-slate-900 dark:text-slate-200"
  //     >
  //       <div className="bg-slate-50 dark:bg-slate-800 flex items-center space-x-1 py-2 px-4 rounded-xl h-full">
  //         {renderMagnifyingGlassIcon()}
  //         <input
  //           type="search"
  //           placeholder="Type and press enter"
  //           className="border-none bg-transparent focus:outline-none focus:ring-0 w-full text-sm "
  //         />
  //       </div>
  //       <input type="submit" hidden value="" />
  //     </form>
  //   );
  // };

  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);

  const NAVIGATION_DEMO_2: any = [
    {
      id: ncNanoId(),
      href: `/${lang}/`,
      name: t("Home"),
    },
    {
      id: ncNanoId(),
      href: `/${lang}/shop`,
      name: t("Shop"),
    },
    {
      id: ncNanoId(),
      href: `/${lang}/cart`,
      name: t("Cart"),
    },
    {
      id: ncNanoId(),
      href: `/${lang}/about-us`,
      name: t("AboutUs"),
    },
    {
      id: ncNanoId(),
      href: `/${lang}/contact-us`,
      name: t("ContactUs"),
    },
  ];
  const { isAuth } = useContext(AuthContext);

  return (
    <div
      className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800"
      style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
    >
      <div className="py-6 px-5">
        <div className="flex justify-between items-center mt-5">
          <Link to="/" className={`ttnc-logo inline-block text-slate-600`}>
            {/* THIS USE FOR MY CLIENT */}
            {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}

            <img
              className="max-h-8 sm:max-h-10 block dark:hidden"
              src={logoImg}
              alt="Logo-Light"
            />
            <img
              className="max-h-8 sm:max-h-10 hidden dark:block"
              src={lightLogo}
              alt="Logo-Light"
            />
          </Link>
          <div className="language flex items-center gap-2 justify-start">
            <span className="text-black dark:text-neutral-200 text-xs mb-2">
              {lang === "ar" ? "اللغة" : "Language"}
            </span>
            <LangDropdown />
          </div>
        </div>


        <span className="absolute right-2 top-2 p-1">
          <ButtonClose onClick={onClickClose} />
        </span>

        {/* <div className="mt-5">{renderSearchForm()}</div> */}
      </div>

      {/* user box */}
      {/* <AvatarDropdown /> */}

      {isAuth && <div className="flex items-center space-x-3 gap-3 py-6 px-5">
        <Link to={`/${lang}/account`} className="flex gap-5 items-center justify-center">
          <Avatar
            imgUrl={
              userInfo
                ? `https://sellxa.com/backend/storage/app/public/${userInfo?.avatar}`
                : `'/avatar.png'`
            }
            sizeClass="w-16 h-16"
          />
          {/* <Avatar imgUrl={avatarImgs[7]} sizeClass="w-12 h-12" /> */}

          <div className="mr-4">
            <h4 className="font-semibold">
              {userInfo ? userInfo.name : "user name"}
            </h4>
            <p className="text-xs mt-0.5">
              {userInfo ? userInfo.email : "Email:"}
            </p>
          </div>
        </Link>

      </div>}

      <ul className="flex flex-col py-6 px-2 space-y-1">
        {isAuth && (
          <Link
            to={"/account-my-order"}
            className={`flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg`}

          >
            {/* className={`${lang === "ar" ? "mr-4" : "ml-4"}`} */}
            <div >
              <p className="text-sm font-medium ">{t("Myorder")}</p>
            </div>
          </Link>
        )}
        {NAVIGATION_DEMO_2?.map(_renderItem)}
        {isAuth && (
          <Link
            to={"/#"}
            className={`flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg`}
            onClick={() => {
              logout();
              dispatch(removeCoupon());
            }}
          >
            {/* className={`${lang === "ar" ? "mr-4" : "ml-4"}`} */}

            <div
              onClick={() => {
                logout();
                dispatch(removeCoupon());
              }}
            >
              <p className="text-sm font-medium ">{t("log out")}</p>
            </div>
          </Link>
        )}
      </ul>
      {/* <div className="flex items-center justify-between py-6 px-5 space-x-2">
        <div className="language hidden md:flex flex-col justify-start border-l-2 border-gray-300 pl-5 ">
          <span className="text-slate-900">
            {lang === "ar" ? "اللغة" : "Language"}
          </span>
          <LangDropdown />
        </div>
      </div> */}

      {!isAuth && (
        <div className="flex items-center justify-between py-6 px-5 space-x-2">
          <ButtonPrimary
            href={`/${lang}/signup`}
            onClick={onClickClose}
            className="!px-10"
          >
            {t("Signup")}
          </ButtonPrimary>

          {/* 
        <ButtonPrimary href={"/"} className="!px-10">
          Buy this template
        </ButtonPrimary> */}
        </div>
      )}

      <div className="flex p-3 fixed bottom-0 left-0 right-0 flex-col mt-5 text-slate-600 dark:text-slate-300 text-sm">
        <span>{t("Discover_the_most")}</span>
        <div
          className="flex justify-between items-center mt-4"
          style={{ direction: "ltr" }}
        >
          <SocialsList itemClass="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-xl" />
          <span className="block">
            <SwitchDarkMode className="bg-neutral-100 dark:bg-neutral-800" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default NavMobile;
