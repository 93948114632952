import React, { useContext, useEffect } from "react";
// import facebookSvg from "images/Facebook.svg";
// import twitterSvg from "images/Twitter.svg";
// import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { AuthContext } from "components/contextApi/AuthContext";
import axios from "axios";
import toast from "react-hot-toast";
import { Order } from "components/contextApi/DataOrderContext";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { useTranslation } from "react-i18next";
import { PagesContext } from "components/contextApi/MetaContext";

const PageEmailInput = ({ className = "" }) => {
  const { setUserId, email, setEmail } = useContext(AuthContext);
  const navigate = useNavigate();
  const { baseUrl } = useContext(Order);
  const { lang } = useContext(LanguageContext);
  const sendReset = async (id) => {
    try {
      const res = await axios.post(
        `${baseUrl}/users/reset`,
        { user_id: id, type: "email" },
        { headers: { "Content-Type": "application/json" } }
      );
      if (res.data.status === 200) {
        toast.success(t("PleaseCheckYourMail"));
        navigate("/code");
      } else if (res.data.status === 100) {
        toast.error(t("PleaseCheckEnteredEmail"));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        `${baseUrl}/users/reset/data`,
        { query: email },
        { headers: { "Content-Type": "application/json" } }
      );
      if (+res.data.status === 200) {
        sendReset(res.data.data.id);
        // console.log(res.data);
        setUserId(res.data.data.id);
      } else if (res.data.status === 100) {
        toast.error(t("PleaseCheckEnteredEmail"));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  const { t } = useTranslation();
  const { GetPage, dataPages } = useContext(PagesContext);
  useEffect(() => {
    GetPage({ page_id: 19 }, lang);
  }, []);
  const description = dataPages?.meta_description;
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>{dataPages?.meta_title}</title>
        <meta name="description" content={description} />
        <link rel="Canonical" href={`/${lang}/forgot-pass`} />
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("FindAccount")}
        </h2>
        <div className="max-w-xs md:max-w-md mx-auto space-y-6">
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                {t("EmailAddress")}
              </span>
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <ButtonPrimary type="submit">{t("Continue")}</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t("login")} {` `}
            <Link className="text-green-600" to={`/${lang}/login`}>
              {t("login")}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageEmailInput;
