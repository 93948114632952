import React from "react";
import ReactDOM from "react-dom/client";
//
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "rc-slider/assets/index.css";
//
import App from "./App.js";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "components/rtk/store";
import { PriceRangeProvider } from "components/contextApi/PriceRangeProvider";
import { AuthProvider } from "components/contextApi/AuthContext";
import { OrderProvider } from "components/contextApi/DataOrderContext";
import i18n from "./i18n.js"; // Import the i18n instance
import { LanguageProvider } from "components/contextApi/ChangeLanguage";
import { BrowserRouter } from "react-router-dom";
import { PagesProvider } from "components/contextApi/MetaContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <AuthProvider>
      <PriceRangeProvider>
        <OrderProvider>
          <LanguageProvider>
            <PagesProvider>
              <Provider store={store}>
                <App />
              </Provider>
            </PagesProvider>
          </LanguageProvider>
        </OrderProvider>
      </PriceRangeProvider>
    </AuthProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
