import { Switch } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import { FaSun, FaMoon } from 'react-icons/fa';

export interface SwitchDarkMode2Props {
  className?: string;
}

const SwitchDarkMode2: React.FC<SwitchDarkMode2Props> = ({
  className = "",
}) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    if (localStorage.theme === "dark") {
      toDark();
    } else {
      toLight();
    }
  }, []);

  const toDark = () => {
    setIsDarkMode(true);
    const root = document.querySelector("html");
    if (!root) return;
    !root.classList.contains("dark") && root.classList.add("dark");
    localStorage.theme = "dark";
  };

  const toLight = () => {
    setIsDarkMode(false);
    const root = document.querySelector("html");
    if (!root) return;
    root.classList.remove("dark");
    localStorage.theme = "light";
  };

  function _toogleDarkMode() {
    if (localStorage.theme === "light") {
      toDark();
    } else {
      toLight();
    }
  }

  return (
    <div className="inline-flex items-center">
      <span className="sr-only">Enable dark mode</span>
      <Switch
        checked={isDarkMode}
        onChange={_toogleDarkMode}
        className={`${isDarkMode ? "bg-gray-700" : "bg-yellow-500"}
          relative inline-flex h-8 w-14 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
      >
        <span className="sr-only">Enable dark mode</span>
        <span
          className={`${isDarkMode ? "translate-x-6" : "translate-x-0"
            } pointer-events-none inline-block h-7 w-7 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out flex items-center justify-center`}
        >
          {isDarkMode ? (
            <FaMoon className="text-gray-700" />
          ) : (
            <FaSun className="text-yellow-500" />
          )}
        </span>
      </Switch>
    </div>
  );
};

export default SwitchDarkMode2;