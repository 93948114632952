// import { productImgs } from "contains/fakeData";
// import productVariantImg2 from "images/products/v2.jpg";
// import productVariantImg3 from "images/products/v3.jpg";
// import productVariantImg4 from "images/products/v4.jpg";
// import productVariantImg5 from "images/products/v5.jpg";
// import productVariantImg6 from "images/products/v6.jpg";
//
// import productSport1 from "images/products/sport-1.png";
// import productSport2 from "images/products/sport-2.png";
// import productSport3 from "images/products/sport-3.png";
// import productSport4 from "images/products/sport-4.png";
// import productSport5 from "images/products/sport-5.png";
// import productSport6 from "images/products/sport-6.png";
// import productSport7 from "images/products/sport-7.png";
// import productSport8 from "images/products/sport-8.png";

//

import lab1 from "images/products/lab1.jpg";
import lab2 from "images/products/lab2.jpg";
import lab3 from "images/products/lab3.jpg";
import lab4 from "images/products/lab4.avif";
import lab5 from "images/products/lab5.avif";
import lab6 from "images/products/lab6.avif";
import lab7 from "images/products/lab7.avif";
import lab8 from "images/products/lab8.avif";
// import lab4 from "images/products/lab8.avif";


export interface ProductVariant {
  id: number;
  name: string;
  thumbnail?: string;
  color?: string;
  featuredImage: string;
}
export interface Product {
  id: number;
  name: string;
  price: number;
  image: string;
  description: string;
  category: string;
  tags: any[];
  link: "/product-detail/";
  variants?: ProductVariant[];
  variantType?: "color" | "image";
  sizes?: string[];
  allOfSizes?: string[];
  status?: "New in" | "limited edition" | "Sold Out" | "50% Discount";
  quantity?: number | 1;
  brand: string;
  color: string;
  size: any;
  used: boolean;
  option?:any;
  ram?:any;
  hard?:any;
}

// const DEMO_VARIANTS: ProductVariant[] = [
//   {
//     id: 1,
//     name: "Black",
//     thumbnail: productVariantImg6,
//     featuredImage: productImgs[0],
//   },
//   {
//     id: 2,
//     name: "White",
//     thumbnail: productVariantImg2,
//     featuredImage: productImgs[1],
//   },
//   {
//     id: 3,
//     name: "Orange",
//     thumbnail: productVariantImg3,
//     featuredImage: productImgs[2],
//   },
//   {
//     id: 4,
//     name: "Sky Blue",
//     thumbnail: productVariantImg4,
//     featuredImage: productImgs[3],
//   },
//   {
//     id: 5,
//     name: "Natural",
//     thumbnail: productVariantImg5,
//     featuredImage: productImgs[4],
//   },
// ];
// const DEMO_VARIANT_COLORS: ProductVariant[] = [
//   {
//     id: 1,
//     name: "Violet",
//     color: "bg-violet-400",
//     featuredImage: productImgs[0],
//   },
//   {
//     id: 2,
//     name: "Yellow",
//     color: "bg-yellow-400",
//     featuredImage: productImgs[1],
//   },
//   {
//     id: 3,
//     name: "Orange",
//     color: "bg-orange-400",
//     featuredImage: productImgs[2],
//   },
//   {
//     id: 4,
//     name: "Sky Blue",
//     color: "bg-sky-400",
//     featuredImage: productImgs[3],
//   },
//   {
//     id: 5,
//     name: "Green",
//     color: "bg-green-400",
//     featuredImage: productImgs[4],
//   },
// ];

// export const PRODUCTS: Product[] = [
//   {
//     id: 1,
//     name: "Rey Nylon Backpack",
//     description: "Brown cockroach wings",
//     price: 74,
//     image: productImgs[16],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     link: "/product-detail/",
//     variants: DEMO_VARIANTS,
//     variantType: "image",
//     sizes: ["XS", "S", "M", "L", "XL"],
//     allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
//     status: "New in",
//   },
//   {
//     id: 2,
//     name: 'Round Buckle 1" Belt',
//     description: "Classic green",
//     price: 68,
//     image: productImgs[1],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     link: "/product-detail/",
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     status: "50% Discount",
//   },
//   {
//     id: 3,
//     name: "Waffle Knit Beanie",
//     description: "New blue aqua",
//     price: 132,
//     image: productImgs[15],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     link: "/product-detail/",
//     variants: DEMO_VARIANTS,
//     variantType: "image",
//     sizes: ["S", "M", "L", "XL"],
//     allOfSizes: ["S", "M", "L", "XL", "2XL", "3XL"],
//   },
//   {
//     id: 4,
//     name: "Travel Pet Carrier",
//     description: "Dark pink 2023",
//     price: 28,
//     image: productImgs[3],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     link: "/product-detail/",
//     status: "Sold Out",
//   },
//   {
//     id: 5,
//     name: "Leather Gloves",
//     description: "Perfect mint green",
//     price: 42,
//     image: productImgs[4],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANTS,
//     variantType: "image",
//     sizes: ["XS", "S", "M", "L", "XL"],
//     allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
//     link: "/product-detail/",
//   },
//   {
//     id: 6,
//     name: "Hoodie Sweatshirt",
//     description: "New design 2023",
//     price: 30,
//     image: productImgs[5],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variantType: "color",
//     variants: DEMO_VARIANT_COLORS,
//     link: "/product-detail/",
//   },
//   {
//     id: 7,
//     name: "Wool Cashmere Jacket",
//     description: "Matte black",
//     price: 12,
//     image: productImgs[8],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANTS,
//     variantType: "image",
//     link: "/product-detail/",
//     status: "New in",
//   },
//   {
//     id: 8,
//     name: "Ella Leather Tote",
//     description: "Cream pink",
//     price: 145,
//     image: productImgs[7],
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANTS,
//     variantType: "image",
//     sizes: ["XS", "S", "M", "L", "XL"],
//     allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
//     link: "/product-detail/",
//     status: "limited edition",
//   },
// ];


// export const SPORT_PRODUCTS: Product[] = [
//   {
//     id: 1,
//     name: "Mastermind Toys",
//     description: "Brown cockroach wings",
//     price: 74,
//     image: productSport1,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     link: "/product-detail/",
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     sizes: ["XS", "S", "M", "L", "XL"],
//     allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
//     status: "New in",
//   },
//   {
//     id: 2,
//     name: "Jump Rope Kids",
//     description: "Classic green",
//     price: 68,
//     image: productSport2,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     link: "/product-detail/",
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     status: "50% Discount",
//   },
//   {
//     id: 3,
//     name: "Tee Ball Beanie",
//     description: "New blue aqua",
//     price: 132,
//     image: productSport3,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     link: "/product-detail/",
//     variants: DEMO_VARIANTS,
//     variantType: "image",
//     sizes: ["S", "M", "L", "XL"],
//     allOfSizes: ["S", "M", "L", "XL", "2XL", "3XL"],
//   },
//   {
//     id: 4,
//     name: "Rubber Table Tennis",
//     description: "Dark pink 2023",
//     price: 28,
//     image: productSport4,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     link: "/product-detail/",
//     status: "Sold Out",
//   },
//   {
//     id: 5,
//     name: "Classic Blue Rugby",
//     description: "Perfect mint green",
//     price: 42,
//     image: productSport5,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANTS,
//     variantType: "image",
//     sizes: ["XS", "S", "M", "L", "XL"],
//     allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
//     link: "/product-detail/",
//   },
//   {
//     id: 6,
//     name: "Manhattan Toy WRT",
//     description: "New design 2023",
//     price: 30,
//     image: productSport6,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variantType: "color",
//     variants: DEMO_VARIANT_COLORS,
//     link: "/product-detail/",
//   },
//   {
//     id: 7,
//     name: "Tabletop Football ",
//     description: "Matte black",
//     price: 12,
//     image: productSport7,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANTS,
//     variantType: "image",
//     link: "/product-detail/",
//     status: "New in",
//   },
//   {
//     id: 8,
//     name: "Pvc Catching Toy",
//     description: "Cream pink",
//     price: 145,
//     image: productSport8,
//     category: "Category 1",
//     tags: ["tag1", "tag2"],
//     variants: DEMO_VARIANT_COLORS,
//     variantType: "color",
//     sizes: ["XS", "S", "M", "L", "XL"],
//     allOfSizes: ["XS", "S", "M", "L", "XL", "2XL", "3XL"],
//     link: "/product-detail/",
//     status: "limited edition",
//   },
// ];
export const LABTOP_PRODUCTS: Product[] = [
  {
    id: 1,
    name: "Labtop DELL core i7",
    description: "Brown cockroach wings",
    price: 17500,
    image: lab1,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    status: "New in",
    color: "white",
    size: 13,
    used: true,
    brand: "Dell",
    ram: 8,
    hard:512,
    option:{
      ram:[
        {
          id: 1.1,
          size: 16,
          price: 50,
        },
        {
          id: 1.2,
          size: 24,
          price: 100
        }
      ],
      colors:[
        {
          id: 1.1,
          color: "red",
          price: 50,
          image: lab2,
        },
        {
          id: 1.2,
          color: "white",
          price: 100,
          image: lab3,
        }
      ],
      hard:[
        {
          id: 1.1,
          size: 1024,
          price: 50,
        },
        {
          id: 1.2,
          size: 2048,
          price: 100,
        }
      ], 
      test:[
        {
          id:1.1,
          test1: 'test1',
          test2: 'test2'
        },
        {
          id:1.2,
          test1: 'test1',
          test2: 'test2'
        }
      ]
    }
  },
  {
    id: 2,
    name: "Labtop HP core i9",
    description: "Classic green",
    price: 18500,
    image: lab2,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    // variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    status: "50% Discount",
    color: "black",
    size: 14,
    used: false,
    brand: "HP",
    hard: 512,
    ram: 8,
    option:{
      ram:[
        {
          id: 2.1,
          size: 16,
          price: 50
        },
        {
          id: 2.2,
          size: 24,
          price: 100
        }
      ],
      colors:[
        {
          id: 2.1,
          color: "red",
          price: 50,
          image: lab2,
        },
        {
          id: 2.2,
          color: "white",
          price: 100,
          image: lab3,
        }
      ],
      hard:[
        {
          id: 2.1,
          size: 1024,
          price: 50,
        },
        {
          id: 2.2,
          size: 2048,
          price: 100,
        }
      ],
    }
  },
  {
    id: 3,
    name: "Labtop LENOVO 250 G8",
    description: "New blue aqua",
    price: 19500,
    image: lab3,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    link: "/product-detail/",
    // variants: DEMO_VARIANTS,
    variantType: "image",
    // sizes: ["S", "M", "L", "XL"],
    // allOfSizes: ["S", "M", "L", "XL", "2XL", "3XL"],
    color: "silver",
    size: 15,
    used: true,
    brand: "ASUS",
    hard: 512,
    ram: 8,
    option:{
      ram:[
        {
          id: 3.1,
          size: 16,
          price: 50
        },
        {
          id: 3.2,
          size: 24,
          price: 100
        }
      ],
      colors:[
        {
          id: 3.1,
          color: "red",
          price: 50,
          image: lab2,
        },
        {
          id: 3.2,
          color: "white",
          price: 100,
          image: lab3,
        }
      ],
      hard:[
        {
          id: 3.1,
          size: 1024,
          price: 50,
        },
        {
          id: 3.2,
          size: 2048,
          price: 100,
        }
      ],
    }

  },
  {
    id: 4,
    name: "Labtop LENOVO Ideapad 1",
    description: "Dark pink 2023",
    price: 22000,
    image: lab4,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    // variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    link: "/product-detail/",
    status: "Sold Out",
    color: "red",
    size: 16,
    used: false,
    brand: "MAC",
    hard: 512,
    ram: 8,
    option:{
      ram:[
        {
          id: 4.1,
          size: 16,
          price: 50
        },
        {
          id: 4.2,
          size: 24,
          price: 100
        }
      ],
      colors:[
        {
          id: 4.1,
          color: "red",
          price: 50,
          image: lab2,
        },
        {
          id: 4.2,
          color: "white",
          price: 100,
          image: lab3,
        }
      ],
      hard:[
        {
          id: 4.1,
          size: 1024,
          price: 50,
        },
        {
          id: 4.2,
          size: 2048,
          price: 100,
        }
      ],
    }
  },
  {
    id: 5,
    name: "Acer Nitro 5 ",
    description: "Intel Core I5-12500H",
    price: 25000,
    image: lab5,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    // variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    link: "/product-detail/",
    // status: "Sold Out",
    color: "blue",
    size: 17,
    used: true,
    brand: "ACER",
    hard: 512,
    ram: 8,
    option:{
      ram:[
        {
          id: 5.1,
          size: 16,
          price: 50
        },
        {
          id: 5.2,
          size: 24,
          price: 100
        }
      ],
      colors:[
        {
          id: 5.1,
          color: "red",
          price: 50,
          image: lab2,
        },
        {
          id: 5.2,
          color: "white",
          price: 100,
          image: lab3,
        }
      ],
      hard:[
        {
          id: 5.1,
          size: 1024,
          price: 50,
        },
        {
          id: 5.2,
          size: 2048,
          price: 100,
        }
      ],
    }
  },
  {
    id: 6,
    name: "Acer Nitro 5 ",
    description: "Intel Core I5-12500H",
    price: 26000,
    image: lab6,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    // variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    link: "/product-detail/",
    // status: "Sold Out",
    color: "yellow",
    size: 18,
    used: false,
    brand: "Dell",
    hard: 512,
    ram: 8,
    option:{
      ram:[
        {
          id: 6.1,
          size: 16,
          price: 50
        },
        {
          id: 6.2,
          size: 24,
          price: 100
        }
      ],
      colors:[
        {
          id: 6.1,
          color: "red",
          price: 50,
          image: lab2,
        },
        {
          id: 6.2,
          color: "white",
          price: 100,
          image: lab3,
        }
      ],
      hard:[
        {
          id: 6.1,
          size: 1024,
          price: 50,
        },
        {
          id: 6.2,
          size: 2048,
          price: 100,
        }
      ],
    }
  },
  {
    id: 7,
    name: "Acer Nitro 5 ",
    description: "Intel Core I5-12500H",
    price: 27000,
    image: lab7,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    // variants: DEMO_VARIANT_COLORS,
    variantType: "color",
    link: "/product-detail/",
    // status: "Sold Out",
    color: "brown",
    size: 19,
    used: true,
    brand: "Dell",
    hard: 512,
    ram: 8,
    option:{
      ram:[
        {
          id: 7.1,
          size: 16,
          price: 50
        },
        {
          id: 7.2,
          size: 24,
          price: 100
        }
      ],
      colors:[
        {
          id: 7.1,
          color: "red",
          price: 50,
          image: lab2,
        },
        {
          id: 7.2,
          color: "white",
          price: 100,
          image: lab3,
        }
      ],
      hard:[
        {
          id: 7.1,
          size: 1024,
          price: 50,
        },
        {
          id: 7.2,
          size: 2048,
          price: 100,
        }
      ],
    }
  },
  {
    id: 8,
    name: "Acer Nitro 5 ",
    description: "Intel Core I5-12500H",
    price: 32000,
    image: lab8,
    category: "Category 1",
    tags: ["tag1", "tag2"],
    // variants: DEMO_VARIANT_COLORS,
    // variantType: "color",
    link: "/product-detail/",
    // status: "Sold Out",
    color: "white",
    size: 20,
    used: false,
    brand: "lenovo",
    ram: 8,
    hard: 512,
    option:{
      ram:[
        {
          size: 16,
          price: 50
        },
        {
          size: 24,
          price: 100
        }
      ],
      colors:[
        {
          id: 8.1,
          color: "red",
          price: 50,
          image: lab2,
        },
        {
          id: 8.2,
          color: "white",
          price: 100,
          image: lab3,
        }
      ],
      hard:[
        {
          id: 8.1,
          size: 1024,
          price: 50,
        },
        {
          id: 8.2,
          size: 2048,
          price: 100,
        }
      ],
    }
  },
];
