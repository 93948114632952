import { usePriceRange } from "components/contextApi/PriceRangeProvider";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
interface SearchModalProps {
  onClose: () => void;
}

const SearchModal: FC<SearchModalProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { getDatafiltered } = usePriceRange();
  const [word, setWord] = useState("");
  const [listProduct, setListProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getDatafiltered({ word: word });
      setListProducts(res.data);
    };

    if (word) {
      fetchData();
    } else {
      setListProducts([]);
    }
  }, [word]);

  const handleChange = (e: any) => {
    setWord(e.target.value);
  };
  return (
    <div
      className="fixed inset-0 z-10 h-screen flex items-start justify-center"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity h-screen"
        aria-hidden="true"
        onClick={onClose} // Close modal when clicking on overlay
      ></div>

      <div className="relative bg-white rounded-lg text-left shadow-xl transition-all w-full max-w-4xl mx-2 my-8">
        <div className="bg-white px-4 pt-5 p-6 pb-4">
          <div className="flex items-start flex-col gap-3">
            <div className="flex flex-row-reverse items-center justify-between w-full gap-2">
              <span onClick={onClose} className="font-bold">
                {t("close")}
              </span>
              <input
                onChange={handleChange}
                type="text"
                value={word}
                placeholder={t("WhatWouldYouLikeToBuy")}
                className="flex-1 border-2 border-blue-500 bg-blue-50 outline-none rounded-md py-2 px-2 w-full"
              />
            </div>
            <ul className="flex flex-col gap-2 w-full">
              {listProduct?.map((product: any) => (
                <li className="hover:bg-gray-200 p-2">
                  <Link
                    to={`/${product?.data?.main_category?.name}/${product?.data?.link}`}
                    className="w-full block"
                    onClick={onClose}
                  >
                    {product?.data?.name}
                  </Link>
                </li>
              ))}
            </ul>
            {/* <h5 className="text-md font-semibold text-gray-500">
              RECOMMENDED CATEGORIES
            </h5>
            <ul className="flex flex-col gap-2 w-full">
              <li className="hover:bg-gray-200 p-2">
                <a href="##" className="w-full block">
                  category
                </a>
              </li>
              <li className="hover:bg-gray-200 p-2">
                <a href="##" className="w-full block">
                  category
                </a>
              </li>
              <li className="hover:bg-gray-200 p-2">
                <a href="##" className="w-full block">
                  category
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
