import Label from "components/Label/Label";
import React, { useContext, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { AuthContext } from "components/contextApi/AuthContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Order } from "components/contextApi/DataOrderContext";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { PagesContext } from "components/contextApi/MetaContext";
import { Helmet } from "react-helmet-async";

interface ErrorType {
  pass?: string;
  duplicate?:string;
}
const AccountPass = () => {
  const {t} = useTranslation();

  const {token} = useContext(AuthContext);
  const [passwordOld,setPasswordOld] = useState();
  const [passwordNew,setPasswordNew] = useState();
  const [passwordNew2, setPasswordNew2] = useState();

  const handleChangePasswordOld = (event:any) => {
    setPasswordOld(event.target.value);
  };
  const [error,setError] = useState<ErrorType>({pass:"",duplicate:""});
  const handleChangePasswordNew = (event:any) => {
    setPasswordNew(event.target.value);
  };


  const handleChangePasswordNew2 = (event:any) => {
    setPasswordNew2(event.target.value);
  };


  useEffect(() => {
    if (passwordNew && passwordOld && passwordNew === passwordOld) {
      setError({ duplicate: t("NewPasswordEqualOld") });
    } else if (passwordNew && passwordNew2 && passwordNew !== passwordNew2) {
      setError({ pass: t("PasswordsDoNotMatch") });
    } else {
      setError({ pass: "" });
    }
  }, [passwordNew, passwordNew2, passwordOld]); 

  const navigate = useNavigate();
  const {baseUrl} = useContext(Order)
  const handleSubmit = (e:any)=>{
    e.preventDefault();
    console.log(token);
    if (!error.pass && !error.duplicate) {
      console.log("no error");   
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const res: any = axios.put(
          `${baseUrl}/users/update`,
          { 
            password_old: passwordOld, 
            password: passwordNew
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success(t("PasswordUpdatedSuccessfully"))
        navigate('/')
      } 
      catch (error:any) {
      console.error('Error:', error.message);
      }
    }
  }

  const { lang } = useContext(LanguageContext);
  const { GetPage, dataPages } = useContext(PagesContext);
  useEffect(() => {
    GetPage({ page_id: 17 }, lang);
  }, []);
  const description = dataPages?.meta_description;
  return (
    <div>
      <Helmet>
        <title>{dataPages?.meta_title}</title>
        <meta name="description" content={description} />
        <link rel="Canonical" href={`/${lang}/account-change-password`} />
      </Helmet>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {t("UpdateYourPassword")}
          </h2>
          <form onSubmit={handleSubmit}>
            <div className=" max-w-xl space-y-6">
              <div>
                <Label>{t("CurrentPassword")}</Label>
                <Input type="password" className="mt-1.5" 
                        defaultValue={""}
                        required
                        onChange={handleChangePasswordOld}/>
              </div>
              <div>
                <Label>{t("NewPassword")}</Label>
                <Input type="password" className="mt-1.5" 
                        defaultValue={""}
                        required
                        onChange={handleChangePasswordNew}/>
              </div>
              {
                error && error.duplicate &&
                <p style={{color:"red"}}>{error.duplicate}</p>
              }
              <div>
                <Label>{t("ConfirmPassword")}</Label>
                <Input type="password" className="mt-1.5" 
                        defaultValue={""}
                        required
                        onChange={handleChangePasswordNew2}/>
              </div>
              {
                error && error.pass &&
                <p style={{color:"red"}}>{error.pass}</p>
              }
              <div className="pt-2">
                <ButtonPrimary type="submit">{t("UpdatePassword")}</ButtonPrimary>
              </div>
            </div>
          </form>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPass;
