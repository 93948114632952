import React from "react";
import top from "images/top.webp";
import left1 from "images/left2.webp";
import left2 from "images/left3.webp";
import right from "images/right.webp";
import "./Banners.css";
const Banners = () => {
  return (
    <div className="BannersContainer md:block">
      <div className="Banners">
        <div className="topLeft">
          <img src={top} alt="banner 1" className="w-full h-full" />
        </div>
        <div className="buttomLeft">
          <img src={left1} alt="banner 2" className="w-full h-full" />
        </div>
        <div className="buttomLeft2">
          <img src={left2} alt="banner 3" className="w-full h-full" />
        </div>
        <div className="rightBanner">
          <img src={right} alt="banner 4" className="w-full h-full" />
        </div>
      </div>
    </div>
  ); 
};

export default Banners;
