import React, { useContext, useEffect, useState } from "react";
import "./Marquee.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchPromoBars } from "components/rtk/slices/promoBarsSlice";
import { LanguageContext } from "components/contextApi/ChangeLanguage";

const Marquee = ({ isVisible, setIsVisible }) => {
  const [bgColor, setBgColor] = useState("#FC5E02");
  const [textColor, setTextColor] = useState("#fff");
  const { lang } = useContext(LanguageContext);
  const dispatch = useDispatch();
  const { promoBars, status } = useSelector((state) => state.promoBars);
  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchPromoBars(lang));
    }
  }, [status]);

  useEffect(() => {
    if (promoBars) {
      setBgColor(promoBars?.color?.color);
      setTextColor(promoBars?.color?.text_color);
    }
    if (promoBars.success && Array.isArray(promoBars.data) && promoBars.data.length === 0) {
      setIsVisible(false);
    }
  }, [promoBars]);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div
      className={`marquee-container`}
      style={{
        direction: `${lang === "ar" ? "rtl" : "ltr"}`,
        background: bgColor,
        color: textColor === bgColor ? "#fff" : textColor,
      }}
    >
      <div className={`marquee-content ${lang === "ar" ? "" : "marquee-content-en"}`}>
        {promoBars?.data?.map((item, i) => (
          <a
            key={i}
            href={item?.url}
            target="blank"
            className="font-bold md:text-lg text-md"
          >
            {item?.name}
          </a>
        ))}
      </div>
      <button
        className="close-button text-3xl"
        onClick={handleClose}
        style={{
          color: textColor === bgColor ? "#fff" : textColor,
        }}
      >
        ×
      </button>
    </div>
  );
};

export default Marquee;
