import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";

import Input from "shared/Input/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { AuthContext } from "components/contextApi/AuthContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

export default function LoginDropDown() {
  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();
  const { login } = useContext(AuthContext);

  const navigate = useNavigate();
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required(t("EmailRequired")),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required(t("PasswordRequired")),
  });

  const initialValues = {
    email: "",
    password: "",
  };
  const location = useLocation();
  const onSubmit = (values, { setSubmitting }) => {
    const response = async () => {
      try {
        const res = await login({
          login: values.email,
          password: values.password,
        });
        if (res.data) {
          const fromPath = location.state?.from || `/${lang}/`;
          if (fromPath === `/${lang}/checkout`) {
            navigate(fromPath);
          } else {
            // navigate(-1); // يروح للصفحة اللي كان فيها قبل صفحة اللوجين
          }
          // navigate('/checkout', { replace: true });
        }
      } catch (error) {
        // toast.error(error.message);
      }
    };
    response();
    // You can handle form submission here (e.g., call an API)
    setSubmitting(false);
  };
  return (
    <div className="AvatarDropdown ">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              // className={`w-8 h-8 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none flex items-center justify-center`}
              className={`border-none outline-none mb-1`}
            >
              {/* <div className="SignUp flex flex-col justify-start items-start sm:px-5 lg:px-0 pl-0 lg:pl-5"> */}
              <span className=" text-[12px] text-black dark:text-neutral-200 font-semibold hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 flex items-center justify-center gap-2">
                {lang === "ar" ? "حسابي" : "Account"}
                <ChevronDownIcon
                  className={`text-black dark:text-neutral-200 ${
                    open ? "-rotate-180" : "text-opacity-70"
                  }
                  ml-1 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                  aria-hidden="true"
                />
              </span>
              {/* </div> */}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-10 w-screen max-w-[360px] px-4 mt-3.5 ${
                  lang === "ar" ? "-left-10" : "-right-10 sm:right-0"
                } sm:px-0 `}
              >
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 w-full">
                  <div className="relative grid grid-cols-1 gap-6 bg-white dark:bg-neutral-800 py-7 px-6">
                    <div className="w-full mx-auto space-y-6">
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {({ isSubmitting }) => (
                          <Form className="grid grid-cols-1 gap-6">
                            <label className="block">
                              <span className="text-neutral-800 text-[12px] font-semibold dark:text-neutral-200">
                                {t("EmailAddress")}
                              </span>
                              <Field
                                as={Input}
                                type="email"
                                id="email"
                                name="email"
                                placeholder="example@example.com"
                                className="mt-1 rounded-lg text-[12px]"
                                // ref={emailRef}
                              />
                            </label>
                            <ErrorMessage name="email" component="div" />
                            <label className="block">
                              <span className="flex justify-between text-[12px] font-semibold items-center text-neutral-800 dark:text-neutral-200">
                                {t("Password")}
                                <Link
                                  to={`/${lang}/forgot-pass`}
                                  className="text-sm text-green-600"
                                >
                                  {t("Forgot")}
                                </Link>
                              </span>
                              <Field
                                as={Input}
                                className="mt-1 rounded-lg text-[12px]"
                                type="password"
                                id="password"
                                name="password"
                                // ref={passwordRef}
                              />
                            </label>
                            <ErrorMessage name="password" component="div" />
                            <ButtonPrimary
                              type="submit"
                              disabled={isSubmitting}
                            >
                              {t("Continue")}
                            </ButtonPrimary>
                          </Form>
                        )}
                      </Formik>

                      {/* ==== */}
                      <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
                      <span className="block text-center text-[14px] text-neutral-700 dark:text-neutral-300">
                        {t("newUser")} {` `}
                        <Link className="text-green-600" to={`/${lang}/signup`}>
                          {t("Createaccount")}
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
