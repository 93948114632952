import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Asynchronous thunk action
export const fetchPromoBars = createAsyncThunk(
  "promoBars/fetchPromoBars",
  async ({ lang }) => {
    const response = await axios.get(
      "https://sellxa.com/backend/api/promo-bars",
      {
        headers: {
          "Accept-Language": lang, // أو 'en' حسب اللغة المطلوبة
        },
      }
    );    
    return response.data;
  }
);

const promoBarsSlice = createSlice({
  name: "promoBars",
  initialState: {
    promoBars: [],
    status: "idle", // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromoBars.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPromoBars.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.promoBars = action.payload;
      })
      .addCase(fetchPromoBars.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default promoBarsSlice.reducer;
