import React, { FC, useContext, useEffect, useRef, useState } from "react";
import "styles/productDetails.css";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import LikeButton from "components/LikeButton";
import AccordionInfo from "./AccordionInfo";
// import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import NcNumber from "./NcNumber.js";
import { LABTOP_PRODUCTS } from "data/data";
import {
  // NoSymbolIcon,
  // ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
// import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import SectionSliderProductCard from "components/SectionSliderProductCard";

import Policy from "./Policy";
// import ReviewItem from "components/ReviewItem";
// import ButtonSecondary from "shared/Button/ButtonSecondary";
// import SectionPromo2 from "components/SectionPromo2";
// import ModalViewAllReviews from "./ModalViewAllReviews";
// import NotifyAddTocart from "components/NotifyAddTocart";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "components/rtk/store";
import { cartActions } from "components/rtk/slices/CartSlice";
// import axios from "axios";
import { Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
// import NcInputNumber from "./NcNumber.js";
import { usePriceRange } from "components/contextApi/PriceRangeProvider";
import { Helmet } from "react-helmet-async";
import { AuthContext } from "components/contextApi/AuthContext";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
// import { notify } from "components/utils/notify";
// import { Order } from "components/contextApi/DataOrderContext";
import "./customSwiper.css";
import { getRequest } from "components/utils/fetchMethods";

import "./DetailsSection.css";
import Page404 from "containers/Page404/Page404";
import SwiperProducts from "./swiperProducts/SwiperProducts";
export interface ProductDetailPageProps {
  className?: string;
}

const ProductDetailPage: FC<ProductDetailPageProps> = ({ className = "" }) => {

  const { token } = useContext(AuthContext);
  const { t, t: trans } = useTranslation();
  useEffect(() => {
    if (token) {
      dispatch(cartActions.updateToken(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [activeTabs, setActiveTabs] = useState<Record<string, number>>({});

  const handleTabClick: any = (key: string, index: number) => {
    setActiveTabs((prevActiveTabs) => ({
      ...prevActiveTabs,
      [key]: index,
    }));
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [activeTabColor, setActiveTabColor] = useState(0);

  const dispatch = useDispatch();
  const cartItems: any = useSelector((state: RootState) => state.cart);

  const { mainCategory, link } = useParams();

  // const product = LABTOP_PRODUCTS.find(
  //   (item: any) => Number(item.id) === Number(id)
  // );
  // console.log(mainCategory);

  const [productt, setProductt] = useState<any>();
  const [loadingProduct, setLoadingProduct] = useState(true);
  const { lang } = useContext(LanguageContext);

  useEffect(() => {
    setLoading(true)
    const getProduct = async (params: any, lang: any) => {
      try {
        const res = await getRequest(params, `/products/find`, lang);
        if (!res) {
          throw new Error(res?.message);
        }
        // setSelectedSub(response.sub_categories)
        // setLoadingSub(false)
        setProductt(res);
        console.log(res)
        setLoadingProduct(false);
        setLoading(false)
      } catch (error: any) {
        if (error.response.status === 404) {
          setProductt(error.response.status);
        }
        console.log(error.response.status);
        setLoading(false)

        // toast.error(error.message);
      }
    };

    // const getProduct = async () => {
    //   try {
    //     const res = await axios.get(
    //       `${baseUrl}/products/find/${mainCategory}/${link}`
    //     );
    //     setProductt(res.data);
    //     setLoadingProduct(false);
    //   } catch (error: any) {
    //     toast.error(error.message);
    //   }
    // };
    getProduct({ mainCategory, link }, lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCategory, link]);

  const [experts, setExperts] = useState([]);
  const [loadingEx, setLoadingEx] = useState(true);
  const { getDatafiltered } = usePriceRange();
  const x = useRef(true);

  useEffect(() => {
    const getExpert = async () => {
      try {
        const res = await getDatafiltered({ paginate: 7, random: true }, lang);
        setExperts(res.data);
        setLoadingEx(false);
      } catch (error: any) {
        setLoadingEx(false);
        console.log(error.message);
        // notify(error.message, "error");
      }
    };
    if (x.current === true) {
      getExpert();
      x.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingEx, loadingProduct, productt]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optionsData: any = [];
  Object.entries(activeTabs)?.forEach(([key, index]) => {
    const option = productt?.optional_data?.[key];
    if (option) {
      const selectedItem = option.find((item: any) => +item.id === index);
      if (selectedItem) {
        optionsData.push(selectedItem);
      }
    }
  });

  let priceOptions = 0;
  optionsData?.forEach((item: any) => {
    priceOptions += +item.price || 0;
  });

  // const [imageLab, setImageLab] = useState("");

  // useEffect(() => {
  //   // Assuming optionsData is a dependency, update the state once after the loop
  //   const image = optionsData?.find((item: any) => item.image)?.image;
  //   if (image) {
  //     setImageLab(image);
  //   }
  // }, [optionsData]);

  const productInState: any = cartItems.cartItems.find(
    (item: any) =>
      Number(item?.stock?.product_id) === Number(productt?.stock?.product_id)
  );

  const { sizes } = LABTOP_PRODUCTS[0];
  // const LIST_IMAGES_DEMO = [detail1JPG, detail2JPG, detail3JPG];

  // const [variantActive, setVariantActive] = React.useState(0);
  // const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [qualitySelected, setQualitySelected] = React.useState(1);
  // const [isOpenModalViewAllReviews, setIsOpenModalViewAllReviews] = useState(false);

  const { qty } = usePriceRange();

  // const productName = productt?.data?.product?.name ?? "";
  // const processorName = productt?.data?.processor?.name ?? "";
  // const processorCore = productt?.data?.processor?.cores ?? "";
  // const processorBrand = processorName + " " + processorCore;
  // const processorArchitecture = productt?.data?.processor?.architecture ?? "";
  // const thermalSolution = productt?.data?.processor?.thermal_solution ?? "";
  // const ramType =
  //   productt?.data?.ram_type?.capacity &&
  //   `${
  //     productt?.data?.ram_type?.capacity
  //       ? productt?.data?.ram_type?.capacity + "GB"
  //       : ""
  //   } ${productt?.data?.ram_type?.name ?? ""}`;
  // const color = productt?.data?.color?.name ?? "";
  // const displaySize =
  //   (productt?.data?.display_size?.display_size ?? "") +
  //   " " +
  //   (productt?.data?.display_size?.resolution ?? "") +
  //   " " +
  //   (productt?.data?.display_size?.screen_type ?? "");
  // const hardDriveType =
  //   productt?.data?.hard_drive?.type &&
  //   `${productt?.data?.hard_drive?.type ?? ""}`;
  // const hardDriveCapacity =
  //   productt?.data?.hard_drive?.capacity &&
  //   `${productt?.data?.hard_drive?.capacity ?? ""}GB ${hardDriveType}`;

  // const name = `${productName ? productName + "," : ""}
  // ${hardDriveCapacity ? hardDriveCapacity + "," : ""}
  // ${ramType ? ramType + "," : ""}
  // ${processorBrand ? processorBrand + "," : ""}
  //    ${processorArchitecture ? processorArchitecture + "," : ""} ${
  //   thermalSolution ? thermalSolution + "," : ""
  // }  ${color ? color + "," : ""} ${displaySize ?? ""}`;
  const hasRequestedRef = useRef(false);

  useEffect(() => {
    // Function to send the API request
    const sendApiRequest = async () => {
      try {
        await fetch(
          `https://sellxa.com/backend/api/log-tracking/?event_name=laptops_page&product_id=${productt?.data?.id}`,
          {
            method: 'GET',
            mode: 'cors', // This enables CORS
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*', // Allow all origins
            },
            credentials: 'include', // Include credentials if needed
          }
        );
      } catch (error) {
        console.error('Error sending API request:', error);
      }
    };

    // Only send the request if it has not been sent yet
    if (productt && !hasRequestedRef.current) {
      sendApiRequest();
      hasRequestedRef.current = true; // Mark as requested
    }
  }, [productt]);
  const [loading, setLoading] = useState(false);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-white bg-opacity-80 inset-0 z-50">
        <div className="w-16 h-16 border-4 border-[#FC5E02] border-solid border-t-transparent rounded-full animate-spin"></div>
      </div>
    )
  }

  const name = productt?.data?.name ?? "";
  const notifyAddTocart = () => {
    const toastId = toast.custom(
      (t: any) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p className="block text-base font-semibold leading-none">
            {trans("Added_to_cart")}
          </p>
          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          {renderProductCartOnNotify({ toastId })}
        </Transition>
      ),
      { position: "top-right", id: "nc-product-notify", duration: 2000 }
    );
    // Close toast on scroll
    const handleScroll = () => {
      toast.dismiss(toastId);
      window.removeEventListener("scroll", handleScroll);
    };
    window.addEventListener("scroll", handleScroll);
  };

  const renderProductCartOnNotify = ({ toastId }: any) => {
    return (
      <div
        className={`flex`}
        style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
      >
        <div className="h-16 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={`https://sellxa.com/backend/${productt?.image[0]?.url}`}
            alt={"productImg"}
            className="h-full w-full object-contain object-center"
          />
        </div>

        <div
          className={`${lang === "ar" ? "mr-4" : "ml-4"}  flex flex-1 flex-col`}
        >
          <div>
            <div className="flex justify-between mb-3">
              <div className="w-[60%] md:w-[70%]">
                <h3 className="text-[9px] md:text-[12px] leading-4 md:leading-5 font-semibold line-clamp-3">
                  {name}
                </h3>
                {/* <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>
                    {variants ? variants[variantActive].name : `Natural`}
                  </span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{size || "XL"}</span>
                </p> */}
              </div>
              <Prices
                price={productt?.stock ? +productt?.stock.price : 0}
                className="mt-0.5"
              />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400">
              {t("Qty_Added")} {qty ? qty : 1}
            </p>
            <p className="text-gray-500 dark:text-slate-400">
              {t("Total_Qty")}{" "}
              {productInState?.quantity ? +productInState?.quantity + qty : qty}
            </p>
            <div className="flex">
              <Link
                to={`/${lang}/cart`}
                className="font-medium text-primary-6000 dark:text-primary-500"
                onClick={() => toast.dismiss(toastId)}
              >
                {t("Viewcart")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const renderVariants = () => {
  //   if (!variants || !variants.length) {
  //     return null;
  //   }

  //   return (
  //     <div>
  //       <label htmlFor="">
  //         <span className="text-sm font-medium">
  //           Color:
  //           <span className="ml-1 font-semibold">
  //             {variants[variantActive].name}
  //           </span>
  //         </span>
  //       </label>
  //       <div className="flex mt-3">
  //         {variants.map((variant, index) => (
  //           <div
  //             key={index}
  //             onClick={() => setVariantActive(index)}
  //             className={`relative flex-1 max-w-[75px] h-10 sm:h-11 rounded-full border-2 cursor-pointer ${
  //               variantActive === index
  //                 ? "border-primary-6000 dark:border-primary-500"
  //                 : "border-transparent"
  //             }`}
  //           >
  //             <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
  //               <img
  //                 src={variant.thumbnail}
  //                 alt=""
  //                 className="absolute w-full h-full object-cover"
  //               />
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSizeList = () => {
  //   if (!allOfSizes || !sizes || !sizes.length) {
  //     return null;
  //   }
  //   return (
  //     <div>
  //       <div className="flex justify-between font-medium text-sm">
  //         <label htmlFor="">
  //           <span className="">
  //             Size:
  //             <span className="ml-1 font-semibold">{sizeSelected}</span>
  //           </span>
  //         </label>
  //         <a
  //           target="_blank"
  //           rel="noopener noreferrer"
  //           href="##"
  //           className="text-primary-6000 hover:text-primary-500"
  //         >
  //           See sizing chart
  //         </a>
  //       </div>
  //       <div className="grid grid-cols-5 sm:grid-cols-7 gap-2 mt-3">
  //         {allOfSizes.map((size, index) => {
  //           const isActive = size === sizeSelected;
  //           const sizeOutStock = !sizes.includes(size);
  //           return (
  //             <div
  //               key={index}
  //               className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center
  //               text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${
  //                 sizeOutStock
  //                   ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
  //                   : "cursor-pointer"
  //               } ${
  //                 isActive
  //                   ? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
  //                   : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
  //               }`}
  //               onClick={() => {
  //                 if (sizeOutStock) {
  //                   return;
  //                 }
  //                 setSizeSelected(size);
  //               }}
  //             >
  //               {size}
  //             </div>
  //           );
  //         })}
  //       </div>
  //     </div>
  //   );
  // };

  // const renderStatus = () => {
  //   if (!status) {
  //     return null;
  //   }
  //   const CLASSES =
  //     "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300";
  //   if (status === "New in") {
  //     return (
  //       <div className={CLASSES}>
  //         <SparklesIcon className="w-3.5 h-3.5" />
  //         <span className="ml-1 leading-none">{status}</span>
  //       </div>
  //     );
  //   }
  //   if (status === "50% Discount") {
  //     return (
  //       <div className={CLASSES}>
  //         <IconDiscount className="w-3.5 h-3.5" />
  //         <span className="ml-1 leading-none">{status}</span>
  //       </div>
  //     );
  //   }
  //   if (status === "Sold Out") {
  //     return (
  //       <div className={CLASSES}>
  //         <NoSymbolIcon className="w-3.5 h-3.5" />
  //         <span className="ml-1 leading-none">{status}</span>
  //       </div>
  //     );
  //   }
  //   if (status === "limited edition") {
  //     return (
  //       <div className={CLASSES}>
  //         <ClockIcon className="w-3.5 h-3.5" />
  //         <span className="ml-1 leading-none">{status}</span>
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  const renderSectionContent = () => {
    let optionName = "";
    optionsData?.forEach((item: any) => {
      optionName += "," + item.name + ": " + item.value || "";
    });


    // Hero section

    const price = productt?.stock ? +productt.stock.price : 0;
    const priceAfterDiscount = productt?.stock?.price_before_discount ?? null;

    return (
      <div className="space-y-7 2xl:space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h1 className=" leading-relaxed text-md sm:text-2xl sm:leading-relaxed font-bold">
            {name + (optionName ? "|" + optionName : "")}
          </h1>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5 gap-5">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}
            {/* <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold hidden md:block"
              price={Number(productt?.stock.price ?? 0) + Number(priceOptions)}
            /> */}
            <div className="flex items-center justify-center gap-1">
              <span className="text-[12px] md:text-xl font-bold text-[#3BB77E]">
                {price.toFixed(0)} {t("EGP")}
              </span>
              {priceAfterDiscount && (
                <span className="text-[8px] md:text-sm line-through text-[#ADADAD]">
                  {priceAfterDiscount.toFixed(0)} {t("EGP")}
                </span>
              )}
            </div>

            {/* <div className="h-7 border-l border-slate-300 dark:border-slate-700"></div> */}

            <div className="flex items-center">
              {/* <div className="flex items-center text-sm font-medium">
                <StarIcon className="w-5 h-5 pb-[1px] text-yellow-400" />
                <div className="ml-1.5 flex">
                  <span>4.9</span>
                  <span className="block mx-2">·</span>
                  <span className="text-slate-600 dark:text-slate-400 underline">
                    142 reviews
                  </span>
                </div>
              </div>
              <span className="hidden sm:block mx-2.5">·</span> */}
              <div className="hidden sm:flex items-center text-sm">
                <SparklesIcon className="w-3.5 h-3.5" />
                <span className="ml-1 leading-none">
                  {productt?.data?.is_available && productt?.stock?.quantity > 0
                    ? t("InStock")
                    : t("OutOfStock")}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        {/* <div className="">{renderVariants()}</div> */}
        {/* <div className="">{renderSizeList()}</div> */}

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        {/* <div className="flex space-x-3.5 gap-2"> */}
        <div className="bg-white fixed bottom-0 left-0 md:relative md:bg-transparent items-center p-4 w-full z-50 md:z-0 flex justify-between gap-4">
          <div className="flex items-center justify-center px-1 sm:p-2.5 rounded-md">
            <NcNumber product={productt} className={'hidden md:flex'} />

            {/* <Prices
              contentClass="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold block md:hidden h-[45px]"
              price={Number(productt?.stock.price ?? 0) + Number(priceOptions)}
            /> */}
            <div className="py-1 px-2 md:py-1.5 md:px-3 flex-col text-lg font-semibold md:hidden h-[45px] flex items-start gap-1">
              <span className="-mb-[10px] mt-3 text-[14px] md:text-lg font-bold text-[#3BB77E]">
                {price} {t("EGP")}
              </span>
              {priceAfterDiscount && (
                <span className="-mt-[40px] text-[10px] md:text-sm line-through text-[#ADADAD]">
                  {priceAfterDiscount.toFixed(0)} {t("EGP")}
                </span>
              )}
            </div>
            {/* <NcInputNumber item={product} productInState={productInState} /> */}
          </div>
          <ButtonPrimary
            style={
              productt?.stock?.in_stock
                ? {
                  background:
                    "linear-gradient(130.77deg, #FC5E02 33.91%, #FFFFFF 265.33%)",
                  width: "100%",
                  height: "40px",
                }
                : {
                  background: "gray",
                  width: "100%",
                  height: "40px",
                }
            }
            className="flex-1 flex-shrink-0 rounded-md !md:h-full px-1 py-2 sm:p-2.5"
            onClick={() => {
              if (
                (!productInState &&
                  productt.data.is_available === 1 &&
                  productt.stock.quantity > 0) ||
                (productt?.stock?.quantity > productInState?.quantity &&
                  productt.data.is_available === 1 &&
                  productt.stock.quantity > 0)
              ) {
                notifyAddTocart();
                dispatch(
                  cartActions.addItem({
                    product: productt,
                    options: activeTabs,
                    allData: productt,
                    qty: qty,
                  })
                );
              } else {
                toast.error(`${t("ProductLimitReached")}`);
              }
            }}
          // disabled={productInState?.stock?.quantity <= productInState?.quantity ? true : false}
          >
            {/* <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" /> */}
            <span className={`${lang === "en" ? "ml-3" : "mr-3"}`}>
              {t("Add_bag")}
            </span>
          </ButtonPrimary>
        </div>
        <h1>
          {productt?.stock?.quantity < 3 ? `${t("only")} 1 ${t("piece")} ${t("left")}` : `${t("only")} ${productt?.stock?.quantity} ${t("piece")} ${t("left")}`}
        </h1>
        <h1>
          {productInState
            ? ` ${t("ProductAddedPreviously")} (${t("qty")} : ${productInState.quantity ?? 0
            })`
            : ""}
        </h1>
        {productt &&
          Object.keys(productt?.optional_data).map((key) => (
            <div key={key}>
              <div className="flex items-center px-2">{key} :</div>
              <div className="flex space-x-3.5">
                <div className="selectColors flex items-center gap-5 w-full">
                  {productt?.optional_data[key]?.map(
                    (item: any, index: any) => (
                      <div
                        className={`selectColors_collor cursor-pointer dark:text-neutral-100 ${activeTabs[key] === item.id ? "activeColor" : ""
                          }`}
                        onClick={() => handleTabClick(key, item.id)}
                        key={index}
                      >
                        {productt?.data?.value}
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          ))}
        {/*  */}
        <hr className=" 2xl:!my-10 border-slate-200 dark:border-slate-700"></hr>
        {/*  */}

        {/* ---------- 5 ----------  */}
        {renderDetailSection()}
        {/* <AccordionInfo data={productt} /> */}

        {/* ---------- 6 ----------  */}
        {/* <div className="hidden xl:block">
          <Policy />
        </div> */}
      </div>
    );
  };

  const renderDetailSection = () => {
    return (
      <div
        className=""
        style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
      >
        <h2 className="text-2xl font-semibold">{t("ProductDetails")}</h2>
        <div className="prose prose-sm sm:prose dark:prose-invert sm:max-w-4xl mt-7">
          {/* <div
            dangerouslySetInnerHTML={{
              __html: productt?.data?.additional_features,
            }}
          /> */}
          {/* <p>{productt?.data?.product?.additional_features}</p>
          <p>{productt?.data?.product?.model_number}</p>
           */}
          <ul className="detailsSection">
            {productt?.data?.processor && (
              <li className="flex gap-24 items-start max-[767px]:gap-0 m-0">
                <p className="font-semibold w-[8rem] m-0">{t("Processor")}:</p>
                <p className="text-right m-0">
                  {productt?.data?.processor?.name +
                    " " +
                    productt?.data?.processor?.cores}
                </p>
              </li>
            )}
            {productt?.data?.display_size &&
              productt?.data?.display_size?.display_size && (
                <li className="flex gap-24 items-start max-[767px]:gap-0">
                  <p className="font-semibold w-[8rem] m-0">
                    {t("DisplaySize")}:
                  </p>
                  <p className="text-right m-0">
                    {productt?.data?.display_size?.display_size}
                  </p>
                </li>
              )}
            {productt?.data?.resolution && (
              <li className="flex gap-24 items-start max-[767px]:gap-0">
                <p className="font-semibold w-[8rem] m-0">{t("Resolution")}:</p>
                <p className="text-right m-0">
                  {productt?.data?.resolution?.resolution}{" "}
                  {productt?.data?.resolution?.size}
                </p>
              </li>
            )}
            {productt?.data?.color && (
              <li className="flex gap-24 items-start max-[767px]:gap-0">
                <p className="font-semibold w-[8rem] m-0">{t("Color")}:</p>
                <p className="text-right m-0">{productt?.data?.color}</p>
              </li>
            )}
            {productt?.data?.hard_drive && (
              <li className="flex gap-24 items-start max-[767px]:gap-0">
                <p className="font-semibold w-[8rem] m-0">{t("HardDrive")}:</p>
                <p className="text-right m-0">
                  {productt?.data?.hard_drive?.capacity}{" "}
                  {productt?.data?.hard_drive?.type}
                </p>
              </li>
            )}
            {productt?.data?.ram_type && (
              <li className="flex gap-24 items-start max-[767px]:gap-0">
                <p className="font-semibold w-[8rem] m-0">{t("RamType")}:</p>
                <p className="text-right m-0">
                  {productt?.data?.ram_type?.capacity + "GB"}{" "}
                  {productt?.data?.ram_type?.type}
                </p>
              </li>
            )}
            {productt?.data?.battery_capacity && (
              <li className="flex gap-24 items-start max-[767px]:gap-0">
                <p className="font-semibold w-[8rem] m-0">
                  {t("BatteryCapacity")}:
                </p>
                <p className="text-right m-0">
                  {productt?.data?.battery_capacity?.manufacturer}{" "}
                  {productt?.data?.battery_capacity?.type}{" "}
                  {productt?.data?.battery_capacity?.capacity}{" "}
                </p>
              </li>
            )}
            {/* {productt?.data?.battery_capacity?.capacity &&
              productt?.data?.battery_capacity?.compatible_devices && (
                <li>
                  {productt?.data?.battery_capacity?.capacity}{" "}
                  {productt?.data?.battery_capacity?.compatible_devices}
                </li>
              )}
            {productt?.data?.operating_system?.name && (
              <li>{productt?.data?.operating_system?.name}</li>
            )}
            {productt?.data?.resolution?.resolution_type && (
              <li>{productt?.data?.resolution?.resolution_type}</li>
            )}
            {productt?.data?.display_size?.display_size &&
              productt?.data?.display_size?.refresh_rate &&
              productt?.data?.display_size?.resolution && (
                <li>
                  {productt?.data?.display_size?.display_size}{" "}
                  {productt?.data?.display_size?.refresh_rate}{" "}
                  {productt?.data?.display_size?.resolution}
                </li>
              )}
            {productt?.data?.processor?.brand &&
              productt?.data?.processor?.memory_support && (
                <li>
                  {productt?.data?.processor?.brand}{" "}
                  {productt?.data?.processor?.memory_support}
                </li>
              )} */}
          </ul>
        </div>
      </div>
    );
  };

  // const path = useLocation()
  // console.log(productt);

  if (productt === 404) {
    return <Page404 />;
  }

  return (
    <div className={`nc-ProductDetailPage ${className}`}>
      <Helmet>
        <title>{productt?.meta?.meta_title}</title>
        <meta name="description" content={productt?.meta?.meta_description} />
        <link rel="Canonical" href="/Shop" />
      </Helmet>
      {/* MAIn */}
      <main className="container mt-5 lg:mt-11">
        <div
          className="mb-4 sm:mb-6"
          style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
        >
          <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
            <Link to={`/${lang}`} className="">
              {t("Homepage")}
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <Link to={`/${lang}/shop`} className="">
              {t("Shop")}
            </Link>
            <span className="text-xs mx-1 sm:mx-1.5">/</span>
            <span className="underline">{productt?.data?.link}</span>
          </div>
        </div>

        <div
          className="lg:flex gap-4"
          style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
        >
          {/* CONTENT */}

          {/* CONTENT images */}

          <div className="w-full lg:w-[55%] ">
            <SwiperProducts productt={productt} />

            {/* <div className="relative">
              <div className="aspect-w-[8rem] aspect-h-16">
                <img
                  src={`https://sellxa.com/backend/${productt?.data.product?.image[0]?.url}`}
                  className="w-full rounded-2xl object-contain"
                  alt="product detail 1"
                />
              </div>
              {renderStatus()}
              // META FAVORITES 
               // <LikeButton className="absolute right-3 top-3 " />
            </div> */}
            {/* <div className="grid grid-cols-2 gap-3 mt-3 sm:gap-6 sm:mt-6 xl:gap-8 xl:mt-8">
              {[
                productt?.data.product?.image[1]?.url,
                productt?.data.product?.image[2]?.url,
              ].map((item, index) => {
                return (
                  <div
                    key={index}
                    className="aspect-w-11 xl:aspect-w-10 2xl:aspect-w-11 aspect-h-16"
                  >
                    <img
                      src={`https://sellxa.com/backend/${item}`}
                      className="w-full rounded-2xl object-contain"
                      alt="product detail 1"
                    />
                  </div>
                );
              })}
            </div> */}
          </div>

          {/* SIDEBAR */}
          <div
            className="w-full lg:w-[45%] pt-10 lg:pt-0 lg:pl-7 xl:pl-9 2xl:pl-10"
            style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
          >
            {renderSectionContent()}
          </div>
        </div>
        {/* <div className="hidden lg:block w-full mt-12 sm:mt-16">
          <Policy />
        </div> */}
        {/* DETAIL AND REVIEW */}
        <div className="mt-12 sm:mt-16 space-y-10 sm:space-y-16">
          <AccordionInfo data={productt} />
          <div className="block">
            <Policy />
          </div>
          {/* {renderDetailSection()} */}
          <hr className="border-slate-200 dark:border-slate-700" />
          {/* {renderReviews()} */}
          {/* <hr className="border-slate-200 dark:border-slate-700" /> */}
          {/* OTHER SECTION */}
          {!loadingEx && experts.length > 0 ? (
            <SectionSliderProductCard
              heading={t("CustomersPurchased")}
              subHeading=""
              headingFontClassName="text-2xl font-semibold"
              headingClassName="mb-10 text-neutral-900 dark:text-neutral-50"
              data={experts}
              className="pb-4"
            />
          ) : (
            <h3>loading...</h3>
          )}

          {/* SECTION */}
          {/* <hr className="border-slate-200 dark:border-slate-700" />

          <div className="pb-20 xl:pb-28 lg:pt-14">
            <SectionPromo2 />
          </div> */}
        </div>
      </main>

      {/* MODAL VIEW ALL REVIEW */}
      {/* <ModalViewAllReviews
        show={isOpenModalViewAllReviews}
        onCloseModalViewAllReviews={() => setIsOpenModalViewAllReviews(false)}
      /> */}
    </div>
  );
};

export default ProductDetailPage;
