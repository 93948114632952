import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ThanksPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    }, [navigate]); // Add navigate to the dependency array to avoid warnings

    return (
        <></>
    );
}

export default ThanksPage;
