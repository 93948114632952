import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { AuthContext } from "components/contextApi/AuthContext";
import axios from "axios";
import toast from "react-hot-toast";
import { Order } from "components/contextApi/DataOrderContext";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { useTranslation } from "react-i18next";

const NewPassword = () => {
  const [pass, setPass] = useState();
  const { tokenReset, email } = useContext(AuthContext);
  const navigate = useNavigate();
  const { baseUrl } = useContext(Order);

  const { lang } = useContext(LanguageContext);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // eslint-disable-next-line no-unused-vars
      const res = await axios.post(
        `${baseUrl}/users/reset/change`,
        {
          password: pass,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenReset}`,
          },
        }
      );
      toast.success("password updated successfully");
      navigate(`/${lang}/login`);
    } catch (error) {
      toast.error(error.message);
    }
  };
  const { t } = useTranslation();
  return (
    <div className={`nc-PageLogin`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Sellxa</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("ResetPassword")}
        </h2>
        <h2 className=" mb-10 flex items-center leading-[115%] md:leading-[115%] md:text-2xl font-semibold justify-center">
          {/* your info: <br/> */}
          {t("youremail")}: {email}
        </h2>
        <div className="max-w-xs md:max-w-md mx-auto space-y-6">
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <label className="block">
              {/* <span className="text-neutral-800 dark:text-neutral-200">
                    Password
                  </span> */}
              <Input
                type="text"
                placeholder="Enter New Password"
                className="mt-1"
                onChange={(e) => setPass(e.target.value)}
              />
            </label>
            <ButtonPrimary type="submit">{t("Continue")}</ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t("login")} {` `}
            <Link className="text-green-600" to={`/${lang}/login`}>
              {t("login")}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
