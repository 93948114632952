import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
// import SectionPromo1 from "components/SectionPromo1";
import ProductCard from "components/ProductCard.js";
import { LABTOP_PRODUCTS } from "data/data";
import SidebarFilters from "./SidebarFilters";
import resultImg from "images/no-data-.avif";

import { notify } from "components/utils/notify";
import { usePriceRange } from "components/contextApi/PriceRangeProvider";
import { useDispatch } from "react-redux";
import { AuthContext } from "components/contextApi/AuthContext";
import { cartActions } from "components/rtk/slices/CartSlice";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import Pagination from "shared/Pagination/Pagination";
import { Link } from "react-router-dom";
import { getBrand } from "components/rtk/slices/BrandSlice";
import { PagesContext } from "components/contextApi/MetaContext";
import axios from "axios";
import { Order } from "components/contextApi/DataOrderContext";

export interface PageCollection2Props {
  className?: string;
}

const PageCollection2: FC<PageCollection2Props> = ({ className = "" }) => {
  const { id } = useParams();
  const { products, setProducts } = usePriceRange();
  const [experts, setExperts] = useState([]);
  const [loadingEx, setLoadingEx] = useState(true);
  const { getDatafiltered } = usePriceRange();
  const x = useRef(true);

  const [cats, setCats] = useState<any>();
  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();

  useEffect(() => {
    const getExpert = async () => {
      try {
        const res = await getDatafiltered({ paginate: 4, random: true }, lang);
        setExperts(res?.data);
        setLoadingEx(false);
      } catch (error: any) {
        setLoadingEx(false);
        console.log(error.message)
        // notify(error.message, "error");
      }
    };
    if (x.current === true) {
      getExpert();
      x.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingEx]);

  const dispatch = useDispatch();
  const { token } = useContext(AuthContext);
  useEffect(() => {
    if (token) {
      dispatch(cartActions.updateToken(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    setSelectedColors,
    setSelectedSizes,
    setSelectedBrands,
    setCurrentPage,
    setColorsState,
    setSizesState,
    setCategoriesState,
    setWord,
  } = usePriceRange();

  useEffect(() => {
    setSelectedColors([]);
    setSelectedSizes([]);
    setSelectedBrands([]);
    setCurrentPage(1);
    setColorsState([]);
    setSizesState([]);
    setCategoriesState([]);
    setWord("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const res = await getDatafiltered({ manufacturer_id: id }, lang);
        // console.log(res);
        setCats(res);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        console.log(error.message)
        // notify(error.message, "error");
      }
    };
    getData();
  }, [id]);

  const [brand, setBrand] = useState();
  const { metaLoaded, setMetaLoaded } = useContext(PagesContext);

  const { baseUrl } = useContext(Order);
  const getAllBrands = async () => {
    try {
      setMetaLoaded(false);
      const res = await axios.get(`${baseUrl}/manufacturer/${id}`, {
        headers: {
          "Accept-Language": lang, // Set your desired language here
        },
      });
      //@ts-ignore
      setBrand(res?.data);
      setMetaLoaded(true);
    } catch (error: any) {
      console.log(error.message);
      setMetaLoaded(true);
    }
  };

  // const getAllBrands = async () => {
  //   try {
  //     setMetaLoaded(false);
  //     //@ts-ignore
  //     const res = await dispatch(getBrand({ lang: lang }));
  //     //@ts-ignore
  //     const brand = res?.payload.find(
  //       (item: any) => item?.name_en.toLowerCase() === id?.toLowerCase()
  //     );
  //     setBrand(brand);
  //     setMetaLoaded(true);
  //   } catch (error) {
  //     console.log(error);
  //     setMetaLoaded(true);
  //   }
  // };

  useEffect(() => {
    getAllBrands();
  }, []);

  return (
    <div
      className={`nc-PageCollection ${className}`}
      data-nc-id="PageCollection"
    >
      <Helmet>
        {/* @ts-ignore */}
        <title>{brand?.meta_title}</title>
        {/* @ts-ignore */}
        <meta name="description" content={brand?.meta_description} />
      </Helmet>
      <div
        className="container py-5"
        style={{
          direction: `${lang === "ar" ? "rtl" : "ltr"}`,
          margin: `0 !important`,
        }}
      >
        <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
          <Link to={`/${lang}`} className="">
            {t("Homepage")}
          </Link>
          <span className="text-xs mx-1 sm:mx-1.5">/</span>
          {/* @ts-ignore */}
          <span className="underline">{brand?.name}</span>
          {/* <span className="underline">{cats?.data[0]?.data?.manufacturer}</span> */}
        </div>
      </div>

      <div className="container py-3 lg:pb-3 lg:pt-3 space-y-16 sm:space-y-20 lg:space-y-28">
        <div
          className=""
          style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }} 
        >
          {/* HEADING */}
          <div className="max-w-screen-sm">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              {/* @ts-ignore */}
              {brand?.name}
              {/* {cats?.data[0]?.data?.manufacturer} */}
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
              {/* We not only help you design exceptional products, but also make it
            easy for you to share your designs with more like-minded people. */}
              {cats?.description}
            </span>
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />
          <main>
            {/* <TabFilters /> */}
            <div className="grid gap-8 grid-cols-2 lg:grid-cols-3 xl:grid-cols-4   gap-x-8 gap-y-10 mt-8 lg:mt-10">
              {loading ? (
                "loading..."
              ) : cats && cats.data?.length > 0 ? (
                cats?.data?.map((item: any, index: any) => (
                  <ProductCard data={item} key={index} />
                ))
              ) : (
                <img
                  src={resultImg}
                  alt="no results"
                  className="w-100"
                  style={{ maxWidth: "100vw" }}
                />
              )}
            </div>

            {/* PAGINATION */}
            <div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
              {/* {products && products?.data?.length > 0 && <Pagination />} */}
              {/* <ButtonPrimary loading>Show me more</ButtonPrimary> */}
              <Pagination products={cats} />
            </div>
          </main>
        </div>

        {/* === SECTION 5 === */}
        <hr className="border-slate-200 dark:border-slate-700" />

        {!loadingEx && experts.length > 0 ? (
          <SectionSliderProductCard
            data={experts?.filter((_, i) => i < 8)}
            heading={t("choosenByExpert")}
            subHeading=""
          />
        ) : (
          // <SectionSliderLargeProduct products={experts} />
          <div className="flex items-center justify-between gap-3">
            <div
              role="status"
              className="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
              <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              <div className="flex items-center mt-4">
                <div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                  <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
              </div>
              <span className="sr-only">Loading...</span>
            </div>
            <div
              role="status"
              className="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
              <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              <div className="flex items-center mt-4">
                <div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                  <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
              </div>
              <span className="sr-only">Loading...</span>
            </div>
            <div
              role="status"
              className="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
              <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              <div className="flex items-center mt-4">
                <div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                  <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
              </div>
              <span className="sr-only">Loading...</span>
            </div>
            <div
              role="status"
              className="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
            >
              <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                <svg
                  className="w-10 h-10 text-gray-200 dark:text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 16 20"
                >
                  <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                </svg>
              </div>
              <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
              <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
              <div className="flex items-center mt-4">
                <div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                  <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
              </div>
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
        {/* {!loadingEx && experts?.length > 0 ? (
        
        <SectionSliderLargeProduct products={experts} />
      ) : (
        <h3>loading...</h3>
      )} */}
        {/* <SectionSliderCollections /> */}
        {/* <hr className="border-slate-200 dark:border-slate-700" /> */}

        {/* SUBCRIBES */}
        {/* <SectionPromo1 /> */}
      </div>
    </div>
  );
};

export default PageCollection2;
