import React, { useContext, useEffect, useState } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import { usePriceRange } from "components/contextApi/PriceRangeProvider";
import { LanguageContext } from "components/contextApi/ChangeLanguage";

const NcNumber = ({product,className}) => {
  const {lang} = useContext(LanguageContext)
  const {qty, setQty} = usePriceRange();
  const [disableInc,setDisableInc] = useState(true);
  const [disableDec,setDisableDec] = useState(true);
  // console.log(product);
  useEffect(() => {
    if(product?.stock?.quantity >= 1){
      setQty(1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(()=>{
    if(product?.stock?.quantity <= 1){
      setDisableDec(true)
      setDisableInc(true)
    }
    else if(qty > 1 &&  qty < product?.stock?.quantity){
      setDisableDec(false)
      setDisableInc(false)
    }
    else if(qty <= 1){
      setDisableDec(true)
      setDisableInc(false)
    }else if(qty >= product?.stock?.quantity){
      setDisableDec(false)
      setDisableInc(true)
    }
  },[qty])
  

  const increase = () => {
    setQty(prevValue => prevValue + 1)
  };
  const decrease = () => {
    setQty(prevValue => prevValue - 1)
  };
  const handleClickDecrement = () => {
    if (qty <= 1) return
    decrease();
  };
  const handleClickIncrement = () => {
    if (qty >= product?.stock?.quantity){
      // setDisableInc(true)
      // setDisableDec(false)
      return
    } 
    increase();
  };


  return (
    <div
      className={`nc-NcInputNumber flex items-center justify-between space-x-5 w-full ${className}`}
      style={{direction: 'ltr'}}
    >
      <div
        className={`nc-NcInputNumber__content flex items-center justify-between w-[104px] sm:w-28`}
      >
        <button
          className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 dark:hover:border-neutral-400 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
          type="button"
          onClick={handleClickDecrement}
          disabled={disableDec}
        >
          <MinusIcon className="w-4 h-4" />
        </button>
        <span className="select-none block flex-1 text-center leading-none">
          {qty}
        </span>
        <button
          className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 dark:hover:border-neutral-400 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
          type="button"
          onClick={handleClickIncrement}
          disabled={disableInc}
        >
          <PlusIcon className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
};

export default NcNumber;
