import React, { FC, useContext } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import LikeButton from "components/LikeButton";
import { StarIcon } from "@heroicons/react/24/solid";
import BagIcon from "components/BagIcon";
import { LABTOP_PRODUCTS } from "data/data";
import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import IconDiscount from "components/IconDiscount";
import Prices from "components/Prices";
import toast from "react-hot-toast";
import AccordionInfo from "containers/ProductDetailPage/AccordionInfo";
import { Link } from "react-router-dom";
import { usePriceRange } from "./contextApi/PriceRangeProvider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./rtk/store";
import { cartActions } from "components/rtk/slices/CartSlice";
import NcNumber from "containers/ProductDetailPage/NcNumber.js";
import { Transition } from "@headlessui/react";
import { LanguageContext } from "./contextApi/ChangeLanguage";
import { useTranslation } from "react-i18next";

export interface ProductQuickViewProps {
  className?: string;
  item: any;
}

const ProductQuickView: FC<ProductQuickViewProps> = ({
  className = "",
  item,
}) => {
  const { lang } = useContext(LanguageContext);

  const { sizes, variants, status, allOfSizes } = LABTOP_PRODUCTS[0];

  const [variantActive, setVariantActive] = React.useState(0);
  const [sizeSelected, setSizeSelected] = React.useState(sizes ? sizes[0] : "");

  const name = item.data.name;

  // console.log(item);

  // const notifyAddTocart = () => {
  //   toast.custom(
  //     (t) => (
  //       <NotifyAddTocart
  //         productImage={LIST_IMAGES_DEMO[0]}
  //         qualitySelected={qualitySelected}
  //         show={t.visible}
  //         sizeSelected={sizeSelected}
  //         variantActive={variantActive}
  //       />
  //     ),
  //     { position: "top-right", id: "nc-product-notify", duration: 3000 }
  //   );
  // };

  // const productName = item?.product?.name ?? "";
  // const processorName = item?.processor?.name ?? "";
  // const processorCore = item?.processor?.cores ?? "";
  // const processorBrand = processorName + " " + processorCore;
  // const processorArchitecture = item?.processor?.architecture ?? "";
  // const thermalSolution = item?.processor?.thermal_solution ?? "";
  // const ramType =
  //   item?.ram_type?.capacity &&
  //   `${item?.ram_type?.capacity ? item?.ram_type?.capacity + "GB" : ""} ${
  //     item?.ram_type?.name ?? ""
  //   }`;
  // const color = item?.color?.name ?? "";
  // const displaySize =
  //   (item?.display_size?.display_size ?? "") +
  //   " " +
  //   (item?.display_size?.resolution ?? "") +
  //   " " +
  //   (item?.display_size?.screen_type ?? "");
  // const hardDriveType =
  //   item?.hard_drive?.type && `${item?.hard_drive?.type ?? ""}`;
  // const hardDriveCapacity =
  //   item?.hard_drive?.capacity &&
  //   `${item?.hard_drive?.capacity ?? ""}GB ${hardDriveType}`;

  // const name = `${productName ? productName + "," : ""}
  // ${hardDriveCapacity ? hardDriveCapacity + "," : ""}
  // ${ramType ? ramType + "," : ""}
  // ${processorBrand ? processorBrand + "," : ""}
  //    ${processorArchitecture ? processorArchitecture + "," : ""} ${
  //   thermalSolution ? thermalSolution + "," : ""
  // }  ${color ? color + "," : ""} ${displaySize ?? ""}`;

  //   const productName = item?.product?.name ?? '';
  //   const processorBrand = item?.processor?.brand ?? '';
  //   const processorArchitecture = item?.processor?.architecture ?? '';
  //   const thermalSolution = item?.processor?.thermal_solution ?? '';
  //   const ramType = item?.ram_type?.name && item?.ram_type?.capacity && `${item?.ram_type?.name ?? ''} ${item?.ram_type?.capacity ?? ''}GB`;
  //   const color = item?.color?.name ?? '';
  //   const displaySize = item?.display_size?.display_size ?? '';
  //   const hardDriveCapacity = item?.hard_drive?.capacity && `${item?.hard_drive?.capacity ?? ''}GB`;
  //   const resolution = `${item?.resolution?.resolution_type ?? ''} ${item?.resolution?.resolution_width ?? ''}*${item?.resolution?.resolution_height ?? ''}`;
  // const name = `${productName}, ${processorBrand}, ${processorArchitecture}, ${thermalSolution}, ${ramType}, ${color}, ${displaySize}, ${hardDriveCapacity}, ${resolution}`;

  const { t, t: trans } = useTranslation();
  const notifyAddTocart = () => {
    const toastId = toast.custom(
      (t) => (
        <Transition
          appear
          show={t.visible}
          className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
          enter="transition-all duration-150"
          enterFrom="opacity-0 translate-x-20"
          enterTo="opacity-100 translate-x-0"
          leave="transition-all duration-150"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-20"
        >
          <p className="block text-base font-semibold leading-none">
            {trans("Added_to_cart")}
          </p>
          <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
          {renderProductCartOnNotify({ toastId })}
        </Transition>
      ),
      { position: "top-right", id: "nc-product-notify", duration: 2000 }
    );
    // Close toast on scroll
    const handleScroll = () => {
      toast.dismiss(toastId);
      window.removeEventListener("scroll", handleScroll);
    };
    window.addEventListener("scroll", handleScroll);
  };

  const renderProductCartOnNotify = ({ toastId }: any) => {
    // console.log(item)
    return (
      <div className={`flex ${lang === "ar" ? "flex-row-reverse" : ""}`}>
        <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={`https://sellxa.com/backend/${item.image[0]?.url}`}
            alt={"productImg"}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div
          className={`${lang === "ar" ? "mr-4" : "ml-4"}  flex flex-1 flex-col`}
        >
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium ">{name}</h3>
                {/* <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>
                    {variants ? variants[variantActive].name : `Natural`}
                  </span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{size || "XL"}</span>
                </p> */}
              </div>
              <Prices
                price={item?.stock ? +item?.stock.price : 0}
                className="mt-0.5"
              />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            <p className="text-gray-500 dark:text-slate-400">
              {t("Qty_Added")} {qty ? qty : 1}
            </p>
            <p className="text-gray-500 dark:text-slate-400">
              {t("Total_Qty")}{" "}
              {productInState?.quantity ? +productInState?.quantity + qty : 1}
            </p>

            <div className="flex">
              <Link
                to={`/${lang}/cart`}
                className="font-medium text-primary-6000 dark:text-primary-500"
                onClick={() => toast.dismiss(toastId)}
              >
                {t("Viewcart")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );

  };

  const renderVariants = () => {
    if (!variants || !variants.length) {
      return null;
    }

    return (
      <div>
        <label htmlFor="">
          <span className="text-sm font-medium">
            Color:
            <span className="ml-1 font-semibold">
              {variants[variantActive].name}
            </span>
          </span>
        </label>
        <div className="flex mt-2.5">
          {variants.map((variant, index) => (
            <div
              key={index}
              onClick={() => setVariantActive(index)}
              className={`relative flex-1 max-w-[75px] h-10 rounded-full border-2 cursor-pointer ${variantActive === index
                ? "border-primary-6000 dark:border-primary-500"
                : "border-transparent"
                }`}
            >
              <div className="absolute inset-0.5 rounded-full overflow-hidden z-0">
                <img
                  src={variant.thumbnail}
                  alt=""
                  className="absolute w-full h-full object-cover"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderSizeList = () => {
    if (!allOfSizes || !sizes || !sizes.length) {
      return null;
    }
    return (
      <div>
        <div className="flex justify-between font-medium text-sm">
          <label htmlFor="">
            <span className="">
              Size:
              <span className="ml-1 font-semibold">{sizeSelected}</span>
            </span>
          </label>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="##"
            className="text-primary-6000 hover:text-primary-500"
          >
            See sizing chart
          </a>
        </div>
        <div className="grid grid-cols-5 sm:grid-cols-7 gap-2 mt-2.5">
          {allOfSizes.map((size, index) => {
            const isActive = size === sizeSelected;
            const sizeOutStock = !sizes.includes(size);
            return (
              <div
                key={index}
                className={`relative h-10 sm:h-11 rounded-2xl border flex items-center justify-center 
                text-sm sm:text-base uppercase font-semibold select-none overflow-hidden z-0 ${sizeOutStock
                    ? "text-opacity-20 dark:text-opacity-20 cursor-not-allowed"
                    : "cursor-pointer"
                  } ${isActive
                    ? "bg-primary-6000 border-primary-6000 text-white hover:bg-primary-6000"
                    : "border-slate-300 dark:border-slate-600 text-slate-900 dark:text-slate-200 hover:bg-neutral-50 dark:hover:bg-neutral-700"
                  }`}
                onClick={() => {
                  if (sizeOutStock) {
                    return;
                  }
                  setSizeSelected(size);
                }}
              >
                {size}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  const { qty } = usePriceRange();
  const dispatch = useDispatch();
  const cartItems: any = useSelector((state: RootState) => state.cart);
  const productInState = cartItems.cartItems.find(
    (itemm: any) =>
      Number(itemm?.stock?.product_id) === Number(item?.stock?.product_id)
  );
  const renderStatus = () => {
    // if (!status) {
    //   return null;
    // }
    const CLASSES =
      "absolute top-3 left-3 px-2.5 py-1.5 text-xs bg-white dark:bg-slate-900 nc-shadow-lg rounded-full flex items-center justify-center text-slate-700 text-slate-900 dark:text-slate-300";
    if (item.data.is_available === 1 && item.stock.quantity > 0) {
      return (
        <div className={CLASSES}>
          <SparklesIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{t("InStock")}</span>
        </div>
      );
    }
    // if (status === "50% Discount") {
    //   return (
    //     <div className={CLASSES}>
    //       <IconDiscount className="w-3.5 h-3.5" />
    //       <span className="ml-1 leading-none">{status}</span>
    //     </div>
    //   );
    // }
    if (item.data.is_available === 0 || item.stock.quantity === 0) {
      return (
        <div className={CLASSES}>
          <NoSymbolIcon className="w-3.5 h-3.5" />
          <span className="ml-1 leading-none">{t("OutOfStock")}</span>
        </div>
      );
    }
    // if (status === "limited edition") {
    //   return (
    //     <div className={CLASSES}>
    //       <ClockIcon className="w-3.5 h-3.5" />
    //       <span className="ml-1 leading-none">{status}</span>
    //     </div>
    //   );
    // }
    return null;
  };

  const renderSectionContent = () => {
    return (
      <div className="space-y-8">
        {/* ---------- 1 HEADING ----------  */}
        <div>
          <h2 className={`text-2xl font-semibold hover:text-primary-6000 transition-colors ${lang === 'ar' ? 'text-right' : ""}`}>
            <Link
              to={`/${lang}/${item?.data?.main_category?.name}/${item?.data?.link}`}
            >
              {name}
            </Link>
          </h2>

          <div className="flex items-center mt-5 space-x-4 sm:space-x-5 justify-between">
            {/* <div className="flex text-xl font-semibold">$112.00</div> */}

            <Prices
              price={item?.stock ? +item?.stock.price : 0}
              className="py-1 px-2 md:py-1.5 md:px-3 text-lg font-semibold"
            />

            {/* <div className="h-6 border-l border-slate-300 dark:border-slate-700"></div> */}

            {/* <div className="flex items-center"> */}
            {/* <Link
                to={`/${lang}/${item?.data?.main_category?.name}/${item?.data?.link}`}
                className="flex items-center text-sm font-medium"
              >
                <StarIcon className="w-5 h-5 pb-[1px] text-yellow-400" />
                <div className="ml-1.5 flex">
                  <span>4.9</span>
                  <span className="block mx-2">·</span>
                </div>
              </Link> */}
            {/* <span className="hidden sm:block mx-2.5">·</span> */}
            {/* <div className="hidden sm:flex items-center text-sm">
                <SparklesIcon className="w-3.5 h-3.5" />
                <span className="ml-1 leading-none">{status}</span>
              </div> */}
            {/* </div> */}
          </div>
        </div>

        {/* ---------- 3 VARIANTS AND SIZE LIST ----------  */}
        {/* <div className="">{renderVariants()}</div>
        <div className="">{renderSizeList()}</div> */}

        {/*  ---------- 4  QTY AND ADD TO CART BUTTON */}
        <div className="flex space-x-3.5 gap-4">
          <div className="flex items-center justify-center bg-slate-100/70 dark:bg-slate-800/70 px-2 py-3 sm:p-3.5 rounded-md">
            <NcNumber product={item} className={''} />
          </div>
          <ButtonPrimary
            style={
              item?.stock?.in_stock === 1
                ? {
                  background:
                    "linear-gradient(130.77deg, #FC5E02 33.91%, #FFFFFF 265.33%)",
                  width: "100%",
                  height: "60px",
                }
                : {
                  background: "gray",
                  width: "100%",
                  height: "60px",
                }
            }
            className="flex-1 flex-shrink-0 bg-orange-500"
            onClick={() => {
              if (
                (!productInState &&
                  item.data.is_available === 1 &&
                  item.stock.quantity > 0) ||
                (item?.stock?.quantity > productInState?.quantity &&
                  item.data.is_available === 1 &&
                  item.stock.quantity > 0)
              ) {
                notifyAddTocart();
                dispatch(
                  cartActions.addItem({
                    product: item,
                    qty: qty,
                  })
                );
              } else {
                toast.error(t("ProductLimitReached"));
              }
            }}
          >
            <BagIcon className="hidden sm:inline-block w-5 h-5 mb-0.5" />
            <span className="ml-3">{t("Add_bag")}</span>
          </ButtonPrimary>
        </div>
        <h1 className="text-center">
          {productInState
            ? ` ${t("ProductAddedPreviously")} (${t("qty")} : ${productInState.quantity ?? 0
            })`
            : ""}
        </h1>
        {/*  */}

      </div>
    );
  };

  return (
    <div className={`nc-ProductQuickView ${className}`}>
      {/* MAIn */}
      <div className="lg:flex" style={{ direction: `${lang === 'ar' ? 'rtl' : 'ltr'}` }}>
        {/* CONTENT */}
        <div className="w-full lg:w-[50%] ">
          {/* HEADING */}
          <div className="relative">
            <div className="aspect-w-16 aspect-h-16">
              <img
                src={`https://sellxa.com/backend/${item?.image[0]?.url}`}
                className="w-full rounded-xl object-contain"
                alt="product detail 1"
              />
            </div>

            {/* STATUS */}
            {renderStatus()}
            {/* META FAVORITES */}
            {/* <LikeButton className="absolute right-3 top-3 " /> */}
          </div>
        </div>

        {/* SIDEBAR */}
        <div
          className="w-full lg:w-[50%] pt-6 lg:pt-0 lg:pl-7 xl:pl-8"
          style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
        >
          {renderSectionContent()}
        </div>
      </div>
      <hr className=" border-slate-200 dark:border-slate-700 mb-5"></hr>
      {/*  */}

      {/* ---------- 5 ----------  */}
      <AccordionInfo data={item} />
    </div>
  );
};

export default ProductQuickView;
