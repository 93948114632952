import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonPrimaryProps extends ButtonProps { }

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  className = "",
  style,
  ...args
}) => {
  return (
    <Button
      style={style}
      className={`ttnc-ButtonPrimary rounded-md disabled:bg-opacity-90  text-slate-50 dark:text-slate-800 ${className}`}
      {...args}
    />
  );
};

export default ButtonPrimary;
