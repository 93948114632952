import {
  useContext,
  useState,
  createContext,
  useEffect,
  useCallback,
} from "react";
// import axios from "axios";
import { getRequest } from "components/utils/fetchMethods";
import { notify } from "components/utils/notify";
// import { Order } from './DataOrderContext';

const PriceRangeContext = createContext();

export const PriceRangeProvider = ({ children }) => {
  const [categoriesState, setCategoriesState] = useState([]);
  const [colorsState, setColorsState] = useState([]);
  const [sizesState, setSizesState] = useState([]);
  const [isOnSale, setIsIsOnSale] = useState(false);
  const [sortOrderStates, setSortOrderStates] = useState("");

  const [HardsState, sethardsState] = useState([]);
  const [selectedHard, setSelectedHard] = useState([]);
  const [capacitiesState, setCapacitiesState] = useState([]);
  const [selectedCapacities, setSelectedCapacities] = useState([]);

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredProducts, setFilteredProducts] = useState();
  const [productWithIdOne, setproductWithIdOne] = useState([]);

  const minPrice =
    products?.data &&
    products?.data?.length > 0 &&
    Math.min(...products?.data?.map((product) => +product?.stock?.price));
  const maxPrice =
    products?.data &&
    products?.data?.length > 0 &&
    Math.max(...products?.data?.map((product) => +product?.stock?.price));
  const [rangePrices, setRangePrices] = useState([minPrice, maxPrice]);

  // const [selectedMain, setSelectedMain] = useState();
  // const [selectedSub, setSelectedSub] = useState();
  const [selectedColor, setSelectedColors] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [word, setWord] = useState("");

  // console.log(selectedBrands,"categoriesState");

  useEffect(() => {
    setRangePrices([minPrice, maxPrice]);
  }, [minPrice, maxPrice]);

  const [load, setLoad] = useState(true);
  const getDatafiltered = async (params, lang) => {
    try {
      setLoading(true);
      const response = await getRequest(params, `/products/filter`, lang);
      if (!response) {
        throw new Error(response?.message);
      }
      setLoading(false);
      // if (response) {
      //   setProducts(response.filteredProducts);
      //   setLoad(false);
      //   setFilteredProducts(response.filteredProducts.data);
      // }
      return response;
    } catch (error) {
      setLoading(false);
      console.log(error);
      // notify("Error fetching data:", "error");
    }
  };
  // useEffect(() => {
  //   const fetchData = async (params) => {
  //     try {
  //       const response = await getRequest(
  //         {
  //           page: currentPage,
  //           color_id: selectedColor,
  //           display_size_id: selectedSizes,
  //           manufacturer_id: selectedBrands,
  //           sub_category_id: selectedSub,
  //           main_category_id: selectedMain,
  //           // word: word,
  //         },
  //         `/products/filter`
  //       );
  //       if (!response) {
  //         throw new Error(response?.msg);
  //       }
  //       if (response) {
  //         setProducts(response.filteredProducts);
  //         setLoad(false);
  //         setFilteredProducts(response.filteredProducts.data);
  //       }
  //     } catch (error) {
  //       setLoad(false);
  //       notify("Error fetching data:", "error");
  //     }
  //   };
  //   fetchData();
  // }, [
  //   currentPage,
  //   selectedMain,
  //   selectedSub,
  //   selectedColor,
  //   selectedSizes,
  //   selectedBrands,
  //   word,
  // ]);

  const applyFilter = useCallback(() => {
    // console.log("iam only for price <3");
    const filtered =
      products.data &&
      products.data.length > 0 &&
      products?.data?.filter(
        (product) =>
          +product?.stock?.price >= rangePrices[0] &&
          +product?.stock?.price <= rangePrices[1]
      );
    setFilteredProducts(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangePrices]);

  const [qty, setQty] = useState(1);

  // const get_sub_cat = () => mainCat && axios.get(`${baseUrl}/categories/main/${mainCat.id}`).then((res)=> {setSubCat(res.data.sub_categories); setLoadingSub(false)});

  const [mainCategories, setMainCategories] = useState([]);
  const [selectedMainId, setSelectedMainId] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubId, setSelectedSubId] = useState(null);

  // useEffect(() => {
  //   setSelectedColors([]);
  //   setSelectedSizes([]);
  //   setSelectedBrands([]);
  //   setCurrentPage(1);
  //   setSelectedMainId(null);
  //   setSelectedSubId(null);
  //   setColorsState([]);
  //   setSizesState([]);
  //   setCategoriesState([]);
  //   setWord("");
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const getMainCats = async (params, lang) => {
    try {
      const response = await getRequest(params, `/categories/main`, lang);
      if (!response) {
        throw new Error(response?.message);
      }
      // setSelectedSub(response.sub_categories)
      // setLoadingSub(false)
      setMainCategories(response);
    } catch (error) {
      console.log(error);
      // notify("Error fetching data:", "error");
    }
  };
  const getSubCats = async (params, lang) => {
    try {
      const response = await getRequest(
        params,
        `/categories/main/${selectedMainId}`,
        lang
      );
      if (!response) {
        throw new Error(response?.message);
      }
      // setSelectedSub(response.sub_categories)
      // setLoadingSub(false)
      setSubCategories(response.sub_categories);
    } catch (error) {
      console.log(error);
      // notify("Error fetching data:", "error");
    }
  };

  // const get_sub_cat = async (params) => {
  //   try {
  //     const response = await getRequest(params, `/categories/main`);
  //     if (!response) {
  //       throw new Error(response?.message);
  //     }
  //     setSelectedSub(response.sub_categories)
  //     setLoadingSub(false)
  //     // console.log(response);
  //     return response
  //   } catch (error) {
  //     notify("Error fetching data:", "error");
  //   }
  // };

  const contextValue = {
    getMainCats,
    mainCategories,
    setMainCategories,
    selectedMainId,
    selectedSubId,
    setSelectedMainId,
    getSubCats,
    subCategories,
    setSubCategories,
    setSelectedSubId,
    getDatafiltered,
    setProducts,
    setFilteredProducts,
    rangePrices,
    setRangePrices,
    categoriesState,
    setCategoriesState,
    colorsState,
    setColorsState,
    sizesState,
    setSizesState,
    filteredProducts,
    isOnSale,
    setIsIsOnSale,
    sortOrderStates,
    setSortOrderStates,
    applyFilter,
    productWithIdOne,
    setproductWithIdOne,
    selectedColor,
    setSelectedColors,
    selectedSizes,
    setSelectedSizes,
    selectedBrands,
    setSelectedBrands,
    word,
    setWord,
    load,
    setLoad,
    products,
    currentPage,
    setCurrentPage,
    qty,
    setQty,

    selectedHard,
    setSelectedHard,
    HardsState,
    sethardsState,
    selectedCapacities,
    setSelectedCapacities,
    capacitiesState,
    setCapacitiesState,
  };

  return (
    <PriceRangeContext.Provider value={contextValue}>
      {children}
    </PriceRangeContext.Provider>
  );
};
export const usePriceRange = () => {
  return useContext(PriceRangeContext);
};
