import Label from "components/Label/Label";
import React, { FC, useContext, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
// import Select from "shared/Select/Select";
// import Textarea from "shared/Textarea/Textarea";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet-async";
// import { avatarImgs } from "contains/fakeData";
import { AuthContext } from "components/contextApi/AuthContext";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Order } from "components/contextApi/DataOrderContext";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { PagesContext } from "components/contextApi/MetaContext";

export interface AccountPageProps {
  className?: string;
}
const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  const { userInfo, token, checkLogin } = useContext(AuthContext);

  const [name, setName] = useState(userInfo ? userInfo?.name : "Enrico Cole,");
  const [email, setEmail] = useState(
    userInfo ? userInfo?.email : "example@email.com"
  );
  const [address, setAddress] = useState(
    userInfo ? userInfo?.address : "New york, USA"
  );
  const [phone, setPhone] = useState(
    userInfo ? userInfo?.phone : "003 888 232"
  );
  // const [password,setPassword] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    userInfo &&
      // console.log(userInfo,"userInfo");
      setName(userInfo?.name);
    setEmail(userInfo?.email);
    setAddress(userInfo?.address);
    setPhone(userInfo?.phone);
  }, [userInfo, checkLogin]);
  const handleChangeName = (event: any) => {
    setName(event.target.value);
  };
  const handleChangeEmail = (event: any) => {
    setEmail(event.target.value);
  };
  const handleChangeAddress = (event: any) => {
    setAddress(event.target.value);
  };
  const handleChangePhone = (event: any) => {
    setPhone(event.target.value);
  };

  const { baseUrl } = useContext(Order);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    // console.log(token);
    try {
      setLoading(true);
      const res: any = await axios.put(
        `${baseUrl}/users/update`,
        {
          name,
          email,
          phone,
          address,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (fileImg) {
        await uploadImg();
      }
      if (res) {
        toast.success(t("InformationUpdatedSuccessfully"));
        setLoading(false);
        checkLogin();
      } else {
        toast.error(t("FailedToUpdateInformation"));
      }
    } catch (error: any) {
      console.error("Error:", error.message);
      toast.error(t("ErrorOccurred"));
    } finally {
      setLoading(false);
    }
  };

  const [fileImg, setFileImg] = useState<any>();

  const uploadImg = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("avatar", fileImg);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res = await axios.post(`${baseUrl}/users/update/image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          // Include any additional headers here, such as authorization token if needed
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      checkLogin();
    } catch (error: any) {
      // Handle errors
      console.error("Error uploading file:", error.message);
      setLoading(false);
    }
  };
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);
  const { GetPage, dataPages } = useContext(PagesContext);
  useEffect(() => {
    GetPage({ page_id: 18 }, lang);
  }, []);
  const description = dataPages?.meta_description;
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>{dataPages?.meta_title}</title>
        <meta name="description" content={description} />
        <link rel="Canonical" href={`/${lang}/account`} />
      </Helmet>
      <CommonLayout>
        <div className="space-y-10 sm:space-y-12">
          {/* HEADING */}
          <h2 className="text-2xl sm:text-3xl font-semibold">
            {t("AccountInformation")}
          </h2>
          <div className="flex flex-col md:flex-row">
            <div className="flex-shrink-0 flex items-start">
              {/* AVATAR */}
              <div className="relative rounded-full overflow-hidden flex ml-2">
                <img
                  // src={avatarImgs[2]}
                  src={
                    fileImg
                      ? URL.createObjectURL(fileImg)
                      : userInfo
                      ? `https://sellxa.com/backend/storage/app/public/${userInfo?.avatar}`
                      : ""
                  }
                  alt=""
                  className="w-32 h-32 rounded-full object-cover z-0"
                />
                <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                      stroke="currentColor"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="mt-1 text-xs">{t("ChangeImage")}</span>
                </div>
                <input
                  type="file"
                  className="absolute inset-0 opacity-0 cursor-pointer"
                  onChange={(e: any) => setFileImg(e.target.files[0])}
                />
              </div>
            </div>
            <form
              onSubmit={handleSubmit}
              className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-6"
            >
              <div
                className={`flex-grow mt-10 md:mt-0 md:${
                  lang === "ar" ? "pr-16" : "pl-16"
                } max-w-3xl space-y-6`}
              >
                <div>
                  <Label>{t("FullName")}</Label>
                  <Input
                    className="mt-1.5"
                    defaultValue={userInfo?.name}
                    required
                    onChange={handleChangeName}
                  />
                </div>

                {/* ---- */}

                {/* ---- */}
                <div>
                  <Label>{t("Email")}</Label>
                  <div className="mt-1.5 flex">
                    <span
                      className={`inline-flex items-center px-2.5 ${
                        lang === "ar" ? "rounded-r-2xl" : "rounded-l-2xl"
                      } border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm`}
                    >
                      <i className="text-2xl las la-envelope"></i>
                    </span>
                    <Input
                      type="email"
                      className={`${
                        lang === "ar" ? "!rounded-r-none" : "!rounded-l-none"
                      }`}
                      defaultValue={userInfo?.email}
                      placeholder="example@email.com"
                      required
                      onChange={handleChangeEmail}
                    />
                  </div>
                </div>

                {/* ---- */}
                {/* <div className="max-w-lg">
                  <Label>Date of birth</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-calendar"></i>
                    </span>
                    <Input
                      className="!rounded-l-none"
                      type="date"
                      defaultValue="1990-07-22"
                    />
                  </div>
                </div> */}
                {/* ---- */}
                <div>
                  <Label>{t("Address")}</Label>
                  <div className="mt-1.5 flex">
                    <span
                      className={`inline-flex items-center px-2.5 ${
                        lang === "ar" ? "rounded-r-2xl" : "rounded-l-2xl"
                      } border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm`}
                    >
                      <i className="text-2xl las la-map-signs"></i>
                    </span>
                    <Input
                      className={`${
                        lang === "ar" ? "!rounded-r-none" : "!rounded-l-none"
                      }`}
                      defaultValue={userInfo?.address}
                      onChange={handleChangeAddress}
                    />
                  </div>
                </div>

                {/* ---- */}
                {/* <div>
                  <Label>Gender</Label>
                  <Select className="mt-1.5">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Select>
                </div> */}

                {/* ---- */}
                <div>
                  <Label>{t("PhoneNumber")}</Label>
                  <div className="mt-1.5 flex">
                    <span
                      className={`inline-flex items-center px-2.5 ${
                        lang === "ar" ? "rounded-r-2xl" : "rounded-l-2xl"
                      } border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm`}
                    >
                      <i className="text-2xl las la-phone-volume"></i>
                    </span>
                    <Input
                      className={`${
                        lang === "ar" ? "!rounded-r-none" : "!rounded-l-none"
                      }`}
                      defaultValue={userInfo?.phone}
                      required
                      onChange={handleChangePhone}
                    />
                  </div>
                </div>

                {/* <div>
                  <Label>Your Password</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-lock"></i>
                    </span>
                    <input
                      type="password"
                      className="!rounded-l-none px-4 py-2 w-full border border-neutral-200 dark:border-neutral-700 focus:outline-none focus:border-primary-6000 dark:focus:border-primary-400 text-neutral-700 dark:text-neutral-300"
                      defaultValue={""}
                      required
                      onChange={handleChangePassword}
                    />
                  </div>
                </div> */}
                {/* ---- */}
                {/* <div>
                  <Label>About you</Label>
                  <Textarea className="mt-1.5" defaultValue="..." />
                </div> */}
                <div className="pt-2">
                  <ButtonPrimary
                    type="submit"
                    disabled={loading}
                    loading={loading}
                  >
                    {t("UpdateAccount")}
                  </ButtonPrimary>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CommonLayout>
    </div>
  );
};

export default AccountPage;
