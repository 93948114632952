import rightImg from "images/hero-right1.png";
import React, { FC, useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { PagesContext } from "components/contextApi/MetaContext";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { useTranslation } from "react-i18next";
import SectionHero from "containers/PageAbout/SectionHero";

export interface PagePrivacyProps {
  className?: string;
}

const PagePrivacy: FC<PagePrivacyProps> = ({ className = "" }) => {
  const { GetPage, dataPages }: any = useContext(PagesContext);
  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();
  useEffect(() => {
    GetPage({ page_id: 8 }, lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const description: any = dataPages?.description;
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>{dataPages?.meta_title ?? ""}</title>
        <meta
          name="description"
          content={
            dataPages?.meta_description ??
            "sellxa laptops and technologies ecommerce Easily select,find the correct items and add them to the cart"
          }
        />
        <link rel="Canonical" href="/about-us" />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-8 lg:pt-16 space-y-8 lg:space-y-8">
        <SectionHero
          rightImg={rightImg}
          heading={`${t("PrivacyPolicy")}`}
          btnText=""
          subHeading={t("SiteDescription")}
          // "We’re impartial and independent, and every day we create distinctive, world-class programmes and content which inform, educate and entertain millions of people in the around the world."
        />
        <div
          className="relative py-4 bg-neutral-100/70 dark:bg-black/20 px-5  w-screen xl:max-w-[1340px] 2xl:max-w-screen-2xl left-1/2 transform -translate-x-1/2 xl:rounded-[40px]"
          style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}`, fontFamily:'Arial !important' }}
        >
          {/* <h3> */}
          {description && (
            <>
              <div dangerouslySetInnerHTML={{ __html: description }}></div>
              {/* <BackgroundSection /> */}
            </>
          )}
          {/* </h3> */}
        </div>

        {/* <SectionFounder /> */}
        {/* <div className="relative py-16">
          <SectionClientSay />
          <BackgroundSection />
        </div> */}

        {/* <SectionStatistic /> */}

        {/* <SectionPromo3 /> */}
      </div>
    </div>
  );
};

export default PagePrivacy;
