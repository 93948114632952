import React, { FC, useContext, useEffect, useId, useRef } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import ProductCard from "./ProductCard";
// import { Product, LABTOP_PRODUCTS } from "data/data";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { LanguageContext } from "./contextApi/ChangeLanguage";

export interface SectionSliderProductCardProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  headingFontClassName?: string;
  headingClassName?: string;
  subHeading?: string;
  data?: any;
}

const SectionSliderProductCard: FC<SectionSliderProductCardProps> = ({
  className = "",
  itemClassName = "",
  headingFontClassName,
  headingClassName,
  heading,
  subHeading = "REY backpacks & bags",
  data,
}) => {
  const { t } = useTranslation();

  const sliderRef = useRef(null);
  const id = useId();
  // console.log(id);

  const product_id: any = useParams();

  const filterSlider = data?.filter((item: any) => {
    return item.data?.link !== product_id?.link;
  });

  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
    if (!sliderRef.current) {
      return () => { };
    }

    // @ts-ignore
    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 22,
      bound: true,
      rewind: false,
      direction: lang === "ar" ? "rtl" : "ltr",
      breakpoints: {
        1280: {
          perView: 4,
        },
        1024: {
          gap: 20,
          perView: 2,
        },
        768: {
          gap: 20,
          perView: 2,
        },
        640: {
          gap: 20,
          perView: 2,
        },
        500: {
          gap: 20,
          perView: 2,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    return () => {
      slider.destroy();
    };
  }, [sliderRef, UNIQUE_CLASS]);

  // console.log(data);
  const { lang } = useContext(LanguageContext);
  return (
    <div className={`nc-SectionSliderProductCard ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading
          className={headingClassName}
          fontClass={headingFontClassName}
          rightDescText={subHeading}
          hasNextPrev
        >
          {heading || `${t("new_arrival")}`}
        </Heading>
        <div
          className="glide__track"
          data-glide-el="track"
        // style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
        >
          <ul className="glide__slides">
            {filterSlider?.map((item: any, index: number) => (
              // Number(item?.product_id) !== Number(product_id?.id) &&
              <li key={index} style={{ width: '180px' }} className={`glide__slide w-[180px]`}>
                <ProductCard data={item} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderProductCard;
