import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
// import Radio from "shared/Radio/Radio";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import MySwitch from "components/MySwitch";
import { usePriceRange } from "./contextApi/PriceRangeProvider";
// import { LABTOP_PRODUCTS } from "data/data";
import GenericCheckboxGroup from "./GenericCheckboxGroup";
import axios from "axios";
import { Order } from "./contextApi/DataOrderContext";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  fetchColors,
  setCapacities,
  setColors,
} from "./rtk/slices/filtersSlice";
import { LanguageContext } from "./contextApi/ChangeLanguage";
import { Direction } from "react-toastify/dist/utils";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import RenderTabsCapacities from "./filteration/RenderTabsCapacities";
// import RenderTabsColor from "./filteration/RenderTabsColor";
// import Prev from "shared/NextPrev/Prev";

// DEMO DATA
// const DATA_categories = [
//   {
//     name: "DELL",
//   },
//   {
//     name: "HP",
//   },
//   {
//     name: "LENOVO",
//   },
//   {
//     name: "ACER",
//   },
//   {
//     name: "ASUS",
//   },
//   {
//     name: "RAZER",
//   },
//   {
//     name: "MAC",
//   },
// ];

// const DATA_colors = [
//   { name: "White" },
//   { name: "black" },
//   { name: "silver" },
//   { name: "red" },
//   { name: "blue" },
//   { name: "yellow" },
//   { name: "brown" },
// ];

// const DATA_sizes:any = [
//   { name: "xxl" },
//   { name: "xl" },
//   { name: "l" },
//   { name: "m" },
//   { name: "s" },
//   { name: "xs" },
// ];

// const DATA_sortOrderRadios = [
//   // { name: "Most Popular", id: "Most-Popular" },
//   // { name: "Best Rating", id: "Best-Rating" },
//   // { name: "Newest", id: "Newest" },
//   { name: "Price Low - Hight", id: "Price-low-hight" },
//   { name: "Price Hight - Low", id: "Price-hight-low" },
// ];

//
const TabFilters = () => {
  const {
    rangePrices,
    setRangePrices,
    applyFilter,
    categoriesState,
    setCategoriesState,
    colorsState,
    setColorsState,
    sizesState,
    setSizesState,
    isOnSale,
    setIsIsOnSale,
    setSortOrderStates,
    products,
    setSelectedColors,
    selectedColor,
    selectedSizes,
    setSelectedSizes,
    selectedBrands,
    setSelectedBrands,

    selectedHard,
    setSelectedHard,
    HardsState,
    sethardsState,
    selectedCapacities,
    setSelectedCapacities,
    capacitiesState,
    setCapacitiesState,

    // filteredProducts
  } = usePriceRange();
  const [allColors, setAllColors] = useState([]);
  const [allSizes, setAllSizes] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [allHards, setAllHards] = useState([]);
  const [allCapacities, setAllCapacities] = useState([]);

  const { t } = useTranslation();
  const unmount = useRef(true);
  const { baseUrl } = useContext(Order);
  const { lang } = useContext(LanguageContext);
  // const dispatch: any = useDispatch();
  useEffect(() => {
    // get all options after hard type selected
    const getAllOptiones = async () => {
      try {
        if (selectedHard && selectedHard.length > 0) {
          const res5 = await axios.get(`${baseUrl}/hard/capacities`, {
            headers: {
              "Accept-Language": lang,
            },
          });
          // dispatch(setCapacities(res5?.data?.capacities));
          setAllCapacities(res5?.data?.capacities);
        } else {
          // dispatch(setCapacities([]));
          // dispatch(setSelectedCapacities([]));
          setAllCapacities([]);
          setSelectedCapacities([]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getAllOptiones();
  }, [selectedHard]);

  // get all options of data filter
  useEffect(() => {
    if (unmount.current) {
      const getAllOptiones = async () => {
        try {
          const res = await axios.get(`${baseUrl}/categories/showColor`, {
            headers: {
              "Accept-Language": lang,
            },
          });
          // console.log(res);

          const res2 = await axios.get(`${baseUrl}/categories/display-sizes`, {
            headers: {
              "Accept-Language": lang,
            },
          });
          const res3 = await axios.get(`${baseUrl}/manufacturer`, {
            headers: {
              "Accept-Language": lang,
            },
          });
          const res4 = await axios.get(`${baseUrl}/hard/types`, {
            headers: {
              "Accept-Language": lang,
            },
          });

          // dispatch(fetchColors());

          setAllColors(res?.data);
          // console.log(res2?.data);

          setAllSizes(res2?.data);
          setAllBrands(res3?.data);

          setAllHards(res4?.data);

          unmount.current = false;
        } catch (err) {
          console.log(err);
        }
      };
      getAllOptiones();
    }

    return () => {
      // Perform cleanup if needed when the component is unmounted
    };
    // getAllSizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(allBrands);

  // console.log(allSizes,"alllall");

  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(false);
  const handleApplyClick = () => {
    closeModalMoreFilter();
    applyFilter();
  }
  //
  // const [isOnSale, setIsIsOnSale] = useState(false);

  const minPrice =
    products?.data &&
    products?.data?.length > 0 &&
    Math.min(...products?.data?.map((product: any) => product?.stock?.price));
  const maxPrice =
    products?.data &&
    products?.data.length > 0 &&
    Math.max(...products?.data?.map((product: any) => +product?.stock?.price));
  const PRICE_RANGE = [minPrice, maxPrice];

  // const [categoriesState, setCategoriesState] = useState<string[]>([]);
  // const [colorsState, setColorsState] = useState<string[]>([]);
  // const [sizesState, setSizesState] = useState<string[]>([]);
  // const [sortOrderStates, setSortOrderStates] = useState<string>("");

  //
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  const openModalMoreFilter = () => setisOpenMoreFilter(true);

  //
  const handleChangeCategories = (checked: boolean, name: string, id: any) => {
    if (checked) {
      setCategoriesState([...categoriesState, name]);
      setSelectedBrands([...selectedBrands, id]);
    } else {
      setCategoriesState(categoriesState.filter((i: any) => i !== name));
      setSelectedBrands(selectedBrands.filter((i: any) => i !== id));
    }
  };

  const handleChangeColors = (checked: boolean, name: string, id: any) => {
    if (checked) {
      setColorsState([...colorsState, name]);
      setSelectedColors([...selectedColor, id]);
    } else {
      setColorsState(colorsState.filter((i: any) => i !== name));
      setSelectedColors(selectedColor.filter((i: any) => i !== id));
    }
  };
  const handleChangeSizes = (checked: boolean, name: string) => {
    if (checked) {
      // console.log(name,"name",id,"id");
      setSizesState([...sizesState, name]);
      setSelectedSizes([...selectedSizes, name]);
    } else {
      setSizesState(sizesState.filter((i: any) => i !== name));
      setSelectedSizes(selectedSizes.filter((i: any) => i !== name));
    }
  };

  const handleChangeHard = (checked: boolean, name: string) => {
    if (checked) {
      sethardsState([...HardsState, name]);
      setSelectedHard([...selectedHard, name]);
    } else {
      sethardsState(HardsState.filter((i: any) => i !== name));
      setSelectedHard(selectedHard.filter((i: any) => i !== name));
    }
  };
  const renderTabsHard = () => {
    const handleApplyFilter = () => {
      applyFilter();
    }
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${!!HardsState.length
                  ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.35 1.94995L9.69 3.28992"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.07 11.92L17.19 11.26"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 22H16"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className={`${lang === "ar" ? "mr-2" : "ml-2"}`}>
                {t("Hards")}
              </span>
              {!HardsState.length ? (
                <ChevronDownIcon
                  className={`w-4 h-4 ${lang === "ar" ? "mr-3" : "ml-3"}`}
                />
              ) : (
                <span
                  onClick={() => {
                    sethardsState([]);
                    setSelectedHard([]);
                  }}
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-20 w-screen max-w-xs px-4 mt-3 ${lang === "ar" ? "right-0" : "left-0"
                  } sm:px-0 lg:max-w-xs`}
              >
                <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5 max-h-[300px] overflow-y-auto">
                    {allHards.map((item: any, index: number) => (
                      <div key={index} className="">
                        <Checkbox
                          name={item}
                          label={item}
                          defaultChecked={HardsState.includes(item)}
                          onChange={(checked) =>
                            handleChangeHard(checked, item)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-slate-50 dark:bg-slate-900 dark:border-t dark:border-slate-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        sethardsState([]);
                        setSelectedHard([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("Reset")}
                    </ButtonThird>
                    {/* <ButtonPrimary
                      onClick={() => { close(); handleApplyFilter() }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary> */}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  //************************************* start capacity **************************************//
  const handleChangeCapacities = (checked: boolean, name: string) => {
    if (checked) {
      setCapacitiesState([...capacitiesState, name]);
      setSelectedCapacities([...selectedCapacities, name]);
    } else {
      setCapacitiesState(capacitiesState.filter((i: any) => i !== name));
      setSelectedCapacities(selectedCapacities.filter((i: any) => i !== name));
    }
  };
  const renderTabsCapacities = () => {
    const handleApplyFilter = () => {
      applyFilter();
    }
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${!!capacitiesState.length
                  ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.35 1.94995L9.69 3.28992"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.07 11.92L17.19 11.26"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 22H16"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className={`${lang === "ar" ? "mr-2" : "ml-2"}`}>
                {t("Capacities")}
              </span>
              {!capacitiesState.length ? (
                <ChevronDownIcon
                  className={`w-4 h-4 ${lang === "ar" ? "mr-3" : "ml-3"}`}
                />
              ) : (
                <span
                  onClick={() => {
                    setCapacitiesState([]);
                    setSelectedCapacities([]);
                  }}
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-20 w-screen max-w-xs px-4 mt-3 ${lang === "ar" ? "right-0" : "left-0"
                  } sm:px-0 lg:max-w-xs`}
              >
                <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5 max-h-[300px] overflow-y-auto">
                    {allCapacities.map((item: any, index: number) => (
                      <div key={index} className="">
                        <Checkbox
                          name={item}
                          label={item}
                          defaultChecked={capacitiesState.includes(item)}
                          onChange={(checked) =>
                            handleChangeCapacities(checked, item)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-slate-50 dark:bg-slate-900 dark:border-t dark:border-slate-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setCapacitiesState([]);
                        setSelectedCapacities([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("Reset")}
                    </ButtonThird>
                    {/* <ButtonPrimary
                      onClick={() => { close(); handleApplyFilter() }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary> */}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };
  //************************************* end capacity **************************************//

  //
  useEffect(() => {
    applyFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangePrices]);

  //

  // OK
  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  // OK
  const renderTabsCategories = () => {
    // const handleApplyFilter = () => {
    //   applyFilter();}
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none select-none
               ${open
                  ? "!border-primary-500 "
                  : "border-neutral-300 dark:border-neutral-700"
                }
                ${!!categoriesState.length
                  ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 2V5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 2V5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 13H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 17H12"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16 3.5C19.33 3.68 21 4.95 21 9.65V15.83C21 19.95 20 22.01 15 22.01H9C4 22.01 3 19.95 3 15.83V9.65C3 4.95 4.67 3.69 8 3.5H16Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className={`${lang === "ar" ? "mr-2" : "ml-2"}`}>
                {t("Brands")}
              </span>
              {!categoriesState.length ? (
                <ChevronDownIcon
                  className={`w-4 h-4 ${lang === "ar" ? "mr-3" : "ml-3"}`}
                />
              ) : (
                <span
                  onClick={() => {
                    setCategoriesState([]);
                    setSelectedBrands([]);
                  }}
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-20 w-screen max-w-xs px-4 mt-3 ${lang === "ar" ? "right-0" : "left-0"
                  } sm:px-0 lg:max-w-xs`}
              >
                <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5 max-h-[300px] overflow-y-auto">
                    {allBrands?.map((item: any) => (
                      <div key={item.id} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={categoriesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeCategories(checked, item.name, item.id)
                          }
                        />
                      </div>
                    ))}
                    {/* <Checkbox
                      name="All Categories"
                      label="All Categories"
                      defaultChecked={categoriesState.includes(
                        "All Categories"
                      )}
                      onChange={(checked) =>
                        handleChangeCategories(checked, "All Categories")
                      }
                    /> */}
                    {/* <div className="w-full border-b border-neutral-200 dark:border-neutral-700" />
                    {DATA_categories.map((item) => (
                      <div key={item.name} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={categoriesState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeCategories(checked, item.name)
                          }
                        />
                      </div>
                    ))} */}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setCategoriesState([]);
                        setSelectedBrands([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("Reset")}
                    </ButtonThird>
                    {/* <ButtonPrimary
                       onClick={()=>{close(); handleApplyFilter()}}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary> */}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // OK
  // const renderTabsSortOrder = () => {
  //   return (
  //     <Popover className="relative">
  //       {({ open, close }) => (
  //         <>
  //           <Popover.Button
  //             className={`flex items-center justify-center px-4 py-2 text-sm border rounded-full focus:outline-none select-none
  //             ${open ? "!border-primary-500 " : ""}
  //               ${
  //                 !!sortOrderStates.length
  //                   ? "!border-primary-500 bg-primary-50 text-primary-900"
  //                   : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
  //               }
  //               `}
  //           >
  //             <svg className="w-4 h-4" viewBox="0 0 20 20" fill="none">
  //               <path
  //                 d="M11.5166 5.70834L14.0499 8.24168"
  //                 stroke="currentColor"
  //                 strokeWidth="1.5"
  //                 strokeMiterlimit="10"
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //               <path
  //                 d="M11.5166 14.2917V5.70834"
  //                 stroke="currentColor"
  //                 strokeWidth="1.5"
  //                 strokeMiterlimit="10"
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //               <path
  //                 d="M8.48327 14.2917L5.94995 11.7583"
  //                 stroke="currentColor"
  //                 strokeWidth="1.5"
  //                 strokeMiterlimit="10"
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //               <path
  //                 d="M8.48315 5.70834V14.2917"
  //                 stroke="currentColor"
  //                 strokeWidth="1.5"
  //                 strokeMiterlimit="10"
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //               <path
  //                 d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
  //                 stroke="currentColor"
  //                 strokeWidth="1.5"
  //                 strokeLinecap="round"
  //                 strokeLinejoin="round"
  //               />
  //             </svg>

  //             <span className="ml-2">
  //               {sortOrderStates
  //                 ? DATA_sortOrderRadios.filter(
  //                     (i) => i.id === sortOrderStates
  //                   )[0].name
  //                 : "Sort order"}
  //             </span>
  //             {!sortOrderStates.length ? (
  //               <ChevronDownIcon className="w-4 h-4 ml-3" />
  //             ) : (
  //               <span onClick={() => setSortOrderStates("")}>
  //                 {renderXClear()}
  //               </span>
  //             )}
  //           </Popover.Button>
  //           <Transition
  //             as={Fragment}
  //             enter="transition ease-out duration-200"
  //             enterFrom="opacity-0 translate-y-1"
  //             enterTo="opacity-100 translate-y-0"
  //             leave="transition ease-in duration-150"
  //             leaveFrom="opacity-100 translate-y-0"
  //             leaveTo="opacity-0 translate-y-1"
  //           >
  //             <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 right-0 sm:px-0 lg:max-w-sm">
  //               <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
  //                 <div className="relative flex flex-col px-5 py-6 space-y-5">
  //                   {DATA_sortOrderRadios.map((item) => (
  //                     <Radio
  //                       id={item.id}
  //                       key={item.id}
  //                       name="radioNameSort"
  //                       label={item.name}
  //                       defaultChecked={sortOrderStates === item.id}
  //                       onChange={setSortOrderStates}
  //                     />
  //                   ))}
  //                 </div>
  //                 <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
  //                   <ButtonThird
  //                     onClick={() => {
  //                       close();
  //                       setSortOrderStates("");
  //                     }}
  //                     sizeClass="px-4 py-2 sm:px-5"
  //                   >
  //                     Clear
  //                   </ButtonThird>
  //                   <ButtonPrimary
  //                     onClick={close}
  //                     sizeClass="px-4 py-2 sm:px-5"
  //                   >
  //                     Apply
  //                   </ButtonPrimary>
  //                 </div>
  //               </div>
  //             </Popover.Panel>
  //           </Transition>
  //         </>
  //       )}
  //     </Popover>
  //   );
  // };

  // OK
  const renderTabsColor = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${!!colorsState.length
                  ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.01 18.0001L3 13.9901C1.66 12.6501 1.66 11.32 3 9.98004L9.68 3.30005L17.03 10.6501C17.4 11.0201 17.4 11.6201 17.03 11.9901L11.01 18.0101C9.69 19.3301 8.35 19.3301 7.01 18.0001Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.35 1.94995L9.69 3.28992"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.07 11.92L17.19 11.26"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 22H16"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.85 15C18.85 15 17 17.01 17 18.24C17 19.26 17.83 20.09 18.85 20.09C19.87 20.09 20.7 19.26 20.7 18.24C20.7 17.01 18.85 15 18.85 15Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className={`${lang === "ar" ? "mr-2" : "ml-2"}`}>
                {t("Colors")}
              </span>
              {!colorsState.length ? (
                <ChevronDownIcon
                  className={`w-4 h-4 ${lang === "ar" ? "mr-3" : "ml-3"}`}
                />
              ) : (
                <span
                  onClick={() => {
                    setColorsState([]);
                    setSelectedColors([]);
                  }}
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-20 mb-4 w-screen max-w-xs px-4 mt-3 ${lang === "ar" ? "right-0" : "left-0"
                  } sm:px-0 lg:max-w-xs`}
              >
                <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5 max-h-[300px] overflow-y-auto">
                    {allColors.map((item: any) => (
                      <div key={item.id} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          defaultChecked={colorsState.includes(item.name)}
                          onChange={(checked) =>
                            handleChangeColors(checked, item.name, item.id)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-slate-50 dark:bg-slate-900 dark:border-t dark:border-slate-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setColorsState([]);
                        setSelectedColors([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("Reset")}
                    </ButtonThird>
                    {/* <ButtonPrimary
                      onClick={()=>{close(); handleApplyFilter()}}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary> */}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // OK
  const renderTabsSize = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${!!sizesState.length
                  ? "!border-primary-500 bg-primary-50 text-primary-900"
                  : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 9V3H15"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 15V21H9"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21 3L13.5 10.5"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.5 13.5L3 21"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className={`${lang === "ar" ? "mr-2" : "ml-2"}`}>
                {t("Sizes")}
              </span>
              {!sizesState.length ? (
                <ChevronDownIcon
                  className={`w-4 h-4 ${lang === "ar" ? "mr-3" : "ml-3"}`}
                />
              ) : (
                <span
                  onClick={() => {
                    setSizesState([]);
                    setSelectedSizes([]);
                  }}
                >
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-20 w-screen max-w-xs px-4 mt-3 ${lang === "ar" ? "right-0" : "left-0"
                  } sm:px-0 lg:max-w-xs`}
              >
                <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5 max-h-[300px] overflow-y-auto">
                    {allSizes?.map((product: any, index: number) => (
                      <div key={product.id ?? index} className="">
                        <Checkbox
                          name={product.size}
                          label={product.size}
                          defaultChecked={sizesState.includes(product.size)}
                          onChange={(checked) =>
                            handleChangeSizes(
                              checked,
                              product.size
                              // product.display_size
                            )
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-slate-50 dark:bg-slate-900 dark:border-t dark:border-slate-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setSizesState([]);
                        setSelectedSizes([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("Reset")}
                    </ButtonThird>
                    {/* <ButtonPrimary
                      onClick={close}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary> */}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // OK
  const renderTabsPriceRage = () => {
    // const handleApplyFilter = () => {
    //   applyFilter();}
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-900 focus:outline-none `}
            >
              {/* <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.67188 14.3298C8.67188 15.6198 9.66188 16.6598 10.8919 16.6598H13.4019C14.4719 16.6598 15.3419 15.7498 15.3419 14.6298C15.3419 13.4098 14.8119 12.9798 14.0219 12.6998L9.99187 11.2998C9.20187 11.0198 8.67188 10.5898 8.67188 9.36984C8.67188 8.24984 9.54187 7.33984 10.6119 7.33984H13.1219C14.3519 7.33984 15.3419 8.37984 15.3419 9.66984"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 6V18"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}

              <span className="ml-2 min-w-[90px]">{`${+rangePrices[0]} ${t(
                "EGP"
              )} - ${+rangePrices[1]} ${t("EGP")}`}</span>
              {rangePrices[0] === PRICE_RANGE[0] &&
                rangePrices[1] === PRICE_RANGE[1] ? null : (
                <span onClick={() => setRangePrices(PRICE_RANGE)}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className={`absolute z-20 w-screen max-w-sm px-4 mt-3 ${lang === "ar" ? "right-0" : "left-0"
                  }  sm:px-0`}
              >
                <div className="rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div
                    className="relative flex flex-col px-5 py-6 space-y-8"
                    style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
                  >
                    <div className="space-y-5">
                      <span className="font-medium">{t("RangePrices")}</span>
                      <Slider
                        range
                        min={PRICE_RANGE[0]}
                        max={PRICE_RANGE[1]}
                        step={1}
                        defaultValue={[rangePrices[0], rangePrices[1]]}
                        value={[rangePrices[0], rangePrices[1]]}
                        allowCross={false}
                        onChange={(_input: number | number[]) =>
                          setRangePrices(_input as number[])
                        }
                      />
                    </div>

                    <div
                      className="flex justify-between space-x-5"
                      style={{ direction: `${lang === "ar" ? "ltr" : "ltr"}` }}
                    >
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          {t("MinPrice")}
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <span
                            className={`absolute inset-y-0 
                              ${lang === "ar" ? "left-4" : "right-4"} 
                            flex items-center pointer-events-none text-neutral-500 sm:text-sm`}
                          >
                            {t("EGP")}{" "}
                          </span>
                          <input
                            type="text"
                            name="minPrice"
                            id="minPrice"
                            className={`block w-32 ${lang === "ar" ? "pl-16 pr-4" : "pr-10 pl-4"
                              }   sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent`}
                            value={rangePrices[0]}
                            onChange={(e) => setRangePrices([e.target.value, rangePrices[1]])}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          {t("MaxPrice")}{" "}
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <span
                            className={`absolute inset-y-0 ${lang === "ar" ? "left-4" : "right-4"
                              } flex items-center pointer-events-none text-neutral-500 sm:text-sm`}
                          >
                            {t("EGP")}
                          </span>
                          <input
                            type="text"
                            name="maxPrice"
                            id="maxPrice"
                            className={`block w-32 ${lang === "ar" ? "pl-16 pr-4" : "pr-10 pl-4"
                              }   sm:text-sm border-neutral-200 dark:border-neutral-700 rounded-full bg-transparent`}
                            value={rangePrices[1]}
                            onChange={(e) => {
                              setRangePrices([rangePrices[0], e.target.value]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        setRangePrices(PRICE_RANGE);
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("Reset")}
                    </ButtonThird>
                    {/* <ButtonPrimary
                      onClick={()=>{close(); handleApplyFilter()}}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary> */}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // OK
  // const renderTabIsOnsale = () => {
  //   return (
  //     <div
  //       className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer select-none ${
  //         isOnSale
  //           ? "border-primary-500 bg-primary-50 text-primary-900"
  //           : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
  //       }`}
  //       onClick={() => setIsIsOnSale(!isOnSale)}
  //     >
  //       <svg
  //         className="w-4 h-4"
  //         viewBox="0 0 24 24"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //       >
  //         <path
  //           d="M3.9889 14.6604L2.46891 13.1404C1.84891 12.5204 1.84891 11.5004 2.46891 10.8804L3.9889 9.36039C4.2489 9.10039 4.4589 8.59038 4.4589 8.23038V6.08036C4.4589 5.20036 5.1789 4.48038 6.0589 4.48038H8.2089C8.5689 4.48038 9.0789 4.27041 9.3389 4.01041L10.8589 2.49039C11.4789 1.87039 12.4989 1.87039 13.1189 2.49039L14.6389 4.01041C14.8989 4.27041 15.4089 4.48038 15.7689 4.48038H17.9189C18.7989 4.48038 19.5189 5.20036 19.5189 6.08036V8.23038C19.5189 8.59038 19.7289 9.10039 19.9889 9.36039L21.5089 10.8804C22.1289 11.5004 22.1289 12.5204 21.5089 13.1404L19.9889 14.6604C19.7289 14.9204 19.5189 15.4304 19.5189 15.7904V17.9403C19.5189 18.8203 18.7989 19.5404 17.9189 19.5404H15.7689C15.4089 19.5404 14.8989 19.7504 14.6389 20.0104L13.1189 21.5304C12.4989 22.1504 11.4789 22.1504 10.8589 21.5304L9.3389 20.0104C9.0789 19.7504 8.5689 19.5404 8.2089 19.5404H6.0589C5.1789 19.5404 4.4589 18.8203 4.4589 17.9403V15.7904C4.4589 15.4204 4.2489 14.9104 3.9889 14.6604Z"
  //           stroke="currentColor"
  //           strokeWidth="1.5"
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //         />
  //         <path
  //           d="M9 15L15 9"
  //           stroke="currentColor"
  //           strokeWidth="1.5"
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //         />
  //         <path
  //           d="M14.4945 14.5H14.5035"
  //           stroke="currentColor"
  //           strokeWidth="2"
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //         />
  //         <path
  //           d="M9.49451 9.5H9.50349"
  //           stroke="currentColor"
  //           strokeWidth="2"
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //         />
  //       </svg>

  //       <span className="line-clamp-1 ml-2">used</span>
  //       {isOnSale && renderXClear()}
  //     </div>
  //   );
  // };

  // OK
  // const renderMoreFilterItem = (
  //   data: {
  //     name: string;
  //     description?: string;
  //     defaultChecked?: boolean;
  //   }[]
  // ) => {

  //   const list1 = data.filter((_, i) => i < data.length / 2);
  //   const list2 = data.filter((_, i) => i >= data.length / 2);
  //   return (
  //     <div className="grid grid-cols-2 gap-x-4 sm:gap-x-8 gap-8">
  //       <div className="flex flex-col space-y-5">
  //         {list1.map((item) => (
  //           <Checkbox
  //             key={item.name}
  //             name={item.name}
  //             subLabel={item.description}
  //             label={item.name}
  //             defaultChecked={!!item.defaultChecked}
  //           />
  //         ))}
  //       </div>
  //       <div className="flex flex-col space-y-5">
  //         {list2.map((item) => (
  //           <Checkbox
  //             key={item.name}
  //             name={item.name}
  //             subLabel={item.description}
  //             label={item.name}
  //             defaultChecked={!!item.defaultChecked}
  //           />
  //         ))}
  //       </div>
  //     </div>
  //   );
  // };

  // FOR RESPONSIVE MOBILE
  const renderTabMobileFilter = () => {
    const handleInputChange = (index: any, value: any) => {
      const newValue = parseInt(value, 10);
      if (!isNaN(newValue)) {
        const newRangePrices = [...rangePrices];
        newRangePrices[index] = Math.max(PRICE_RANGE[0], Math.min(PRICE_RANGE[1], newValue));
        setRangePrices(newRangePrices);
      }
    };
    const handleApplyFilter = () => {
      applyFilter();
    }
    const handleCheckboxChange = (
      checked: boolean,
      name: string,
      setState: any,
      setSelected: any,
      id: any
    ) => {
      setState((prev: any) =>
        checked ? [...prev, name] : prev.filter((i: any) => i !== name)
      );
      setSelected((prev: any) =>
        checked ? [...prev, id] : prev.filter((i: any) => i !== id)
      );
    };

    return (
      <div className="flex-shrink-0">
        <div
          className={`flex flex-shrink-0 items-center justify-center px-4 py-2 text-sm rounded-full border border-primary-500 bg-primary-50 text-primary-900 focus:outline-none cursor-pointer select-none`}
          onClick={openModalMoreFilter}
        >
          <svg
            className="w-4 h-4"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22 6.5H16"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 6.5H2"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 10C11.933 10 13.5 8.433 13.5 6.5C13.5 4.567 11.933 3 10 3C8.067 3 6.5 4.567 6.5 6.5C6.5 8.433 8.067 10 10 10Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22 17.5H18"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 17.5H2"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14 21C15.933 21 17.5 19.433 17.5 17.5C17.5 15.567 15.933 14 14 14C12.067 14 10.5 15.567 10.5 17.5C10.5 19.433 12.067 21 14 21Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className={`${lang === "ar" ? "mr-2" : "ml-2"}`}>
            {t("ProductsFilters")}
          </span>
          {/* {renderXClear()} */}
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div
              className="min-h-screen text-center"
            // style={{direction:'rtl'}}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full text-left align-middle transition-all transform bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      {/* Products filters */}
                      {t("ProductsFilters")}
                    </Dialog.Title>
                    <span className="absolute pt-1 left-3 top-3">
                      <span
                        className="text-3xl  font-bold"
                        onClick={closeModalMoreFilter}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 6L6 18"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6 6L18 18"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                      </span>
                      {/* <ButtonClose onClick={closeModalMoreFilter} /> */}
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div
                      className="px-6 sm:px-8 md:px-10 divide-y divide-neutral-200 dark:divide-neutral-800"
                      style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
                    >
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3
                          className={`text-xl font-medium ${lang === "ar" ? "text-right" : "text-left"
                            }`}
                        >
                          {t("Brands")}
                        </h3>
                        <div className="mt-6 relative ">
                          <GenericCheckboxGroup
                            options={allBrands}
                            onChange={(
                              checked: boolean,
                              name: string,
                              id: any
                            ) =>
                              handleCheckboxChange(
                                checked,
                                name,
                                setCategoriesState,
                                setSelectedBrands,
                                id
                              )
                            }
                          />
                          {/* {renderMoreFilterItem(DATA_categories)} */}
                        </div>
                      </div>
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3
                          className={`text-xl font-medium ${lang === "ar" ? "text-right" : "text-left"
                            }`}
                        >
                          {t("Colors")}
                        </h3>
                        <div className="mt-6 relative ">
                          <GenericCheckboxGroup
                            options={allColors}
                            onChange={(
                              checked: boolean,
                              name: string,
                              id: any
                            ) =>
                              handleCheckboxChange(
                                checked,
                                name,
                                setColorsState,
                                setSelectedColors,
                                id
                              )
                            }
                          />
                          {/* {renderMoreFilterItem(DATA_colors)} */}
                        </div>
                      </div>
                      <div className="py-7">
                        <h3
                          className={`text-xl font-medium ${lang === "ar" ? "text-right" : "text-left"
                            }`}
                        >
                          {t("Sizes")}
                        </h3>
                        <div className="mt-6 relative ">
                          <GenericCheckboxGroup
                            options={allSizes}
                            onChange={(
                              checked: boolean,
                              display_size: string,
                              id: any
                            ) =>
                              handleCheckboxChange(
                                checked,
                                display_size,
                                setSizesState,
                                setSelectedSizes,
                                id
                              )
                            }
                          />
                          {/* {renderMoreFilterItem(DATA_colors)} */}
                        </div>
                      </div>
                      <div className="py-7">
                        <h3
                          className={`text-xl font-medium ${lang === "ar" ? "text-right" : "text-left"
                            }`}
                        >
                          {t("Hards")}
                        </h3>
                        <div className="mt-6 relative ">
                          <GenericCheckboxGroup
                            options={allHards}
                            onChange={(
                              checked: boolean,
                              display_size: string,
                              id: any
                            ) =>
                              handleCheckboxChange(
                                checked,
                                display_size,
                                sethardsState,
                                setSelectedHard,
                                id
                              )
                            }
                          />
                          {/* {renderMoreFilterItem(DATA_colors)} */}
                        </div>
                      </div>
                      {selectedHard && selectedHard.length > 0 && (
                        <div className="py-7">
                          <h3
                            className={`text-xl font-medium ${lang === "ar" ? "text-right" : "text-left"
                              }`}
                          >
                            {t("Capacities")}
                          </h3>
                          <div className="mt-6 relative ">
                            <GenericCheckboxGroup
                              options={allCapacities}
                              onChange={(
                                checked: boolean,
                                display_size: string,
                                id: any
                              ) =>
                                handleCheckboxChange(
                                  checked,
                                  display_size,
                                  setCapacitiesState,
                                  setSelectedCapacities,
                                  id
                                )
                              }
                            />
                            {/* {renderMoreFilterItem(DATA_colors)} */}
                          </div>
                        </div>
                      )}
                      {/* --------- */}
                      {/* ----  mobile filter sizes*/}
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">Size</h3>
                        <div className="mt-6 relative ">
                        <div className="grid grid-cols-2 gap-x-4 sm:gap-x-8 gap-8">
                          <div className="flex flex-col space-y-5">
                            {
                          LABTOP_PRODUCTS.filter((_, i) => i < LABTOP_PRODUCTS.length / 2).map((product:any) =>(
                            <div key={product.id} className="">
                              <Checkbox
                                name={product.size}
                                label={product.size}
                                defaultChecked={sizesState.includes(product.size)}
                                onChange={(checked) =>
                                  handleChangeSizes(checked, product.size)
                                }
                              />
                            </div>
                          ))
                        }
                          </div>
                          <div className="flex flex-col space-y-5">
                            {
                          LABTOP_PRODUCTS.filter((_, i) => i >= LABTOP_PRODUCTS.length / 2).map((product:any) =>(
                            <div key={product.id} className="">
                              <Checkbox
                                name={product.size}
                                label={product.size}
                                defaultChecked={sizesState.includes(product.size)}
                                onChange={(checked) =>
                                  handleChangeSizes(checked, product.size)
                                }
                              />
                            </div>
                          ))
                        }
                          </div>
                        </div>
                         // <GenericCheckboxGroup options={DATA_sizes} onChange={(checked:boolean, name:string) => handleCheckboxChange(checked, name, setSizesState)} />
                        </div>
                      </div> */}

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3
                          className={`text-xl font-medium ${lang === "ar" ? "text-right" : "text-left"
                            }`}
                        >
                          {t("RangePrices")}
                        </h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Slider
                                range
                                className="text-red-400"
                                min={PRICE_RANGE[0]}
                                max={PRICE_RANGE[1]}
                                defaultValue={rangePrices}
                                value={[rangePrices[0], rangePrices[1]]}
                                allowCross={false}
                                onChange={(_input: number | number[]) =>
                                  setRangePrices(_input as number[])
                                }
                              />
                            </div>
                            <div className="flex justify-between gap-4">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  {t("MinPrice")}
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      {t("EGP")}
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="minPrice"

                                    id="minPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[0]}
                                    onChange={(e) => setRangePrices([e.target.value, rangePrices[1]])}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  {t("MaxPrice")}
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      {t("EGP")}
                                    </span>
                                  </div>
                                  <input
                                    type="text"

                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={rangePrices[1]}
                                    onChange={(e) => setRangePrices([rangePrices[0], e.target.value])}

                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">Sort Order</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-5">
                            {DATA_sortOrderRadios.map((item) => (
                              <Radio
                                id={item.id}
                                key={item.id}
                                name="radioNameSort"
                                label={item.name}
                                defaultChecked={sortOrderStates === item.id}
                                onChange={setSortOrderStates}
                              />
                            ))}
                          </div>
                        </div>
                      </div> */}

                      {/* --------- */}
                      {/* ---- */}
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">used!</h3>
                        <div className="mt-6 relative ">
                          <MySwitch
                            label="On sale!"
                            desc="Products currently on sale"
                            enabled={isOnSale}
                            onChange={setIsIsOnSale}
                          />
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div style={{ direction: lang === "ar" ? "rtl" : "ltr" }} className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        setRangePrices(PRICE_RANGE);
                        setCategoriesState([]);
                        setColorsState([]);
                        setSortOrderStates("");
                        closeModalMoreFilter();
                        setSizesState([]);
                        setSelectedBrands([]);
                        setSelectedColors([]);
                        setSelectedSizes([]);
                        setSelectedCapacities([]);
                        sethardsState([]);
                        setSelectedHard([]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {t("Reset")}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={handleApplyClick}
                      sizeClass="px-6 py-2 sm:px-5"
                      className="!bg-[#FC5E02] "
                    >
                      {t("apply")}
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="flex lg:space-x-4">
      {/* FOR DESKTOP */}
      <div className="hidden lg:flex flex-1 gap-3">
        {renderTabsPriceRage()}
        {renderTabsCategories()}
        {renderTabsColor()}
        {/* <RenderTabsColor/> */}
        {renderTabsSize()}
        {renderTabsHard()}
        {selectedHard && selectedHard.length > 0 && renderTabsCapacities()}
        {/* {selectedHard && selectedHard.length > 0 && <RenderTabsCapacities />} */}
        {/* {renderTabIsOnsale()} */}
        {/* <div className="!ml-auto">{renderTabsSortOrder()}</div> */}
      </div>

      {/* FOR RESPONSIVE MOBILE */}
      <div className="flex overflow-x-auto lg:hidden space-x-4">
        {renderTabMobileFilter()}
      </div>
    </div>
  );
};

export default TabFilters;
