/* eslint-disable no-unused-vars */
import { getRequest } from "components/utils/fetchMethods";
import { notify } from "components/utils/notify";
import { useState } from "react";
import { createContext } from "react";

export const PagesContext = createContext();

// eslint-disable-next-line react/prop-types
export const PagesProvider = ({ children }) => {
  const [dataPages, setDataPages] = useState();
  const [metaLoaded, setMetaLoaded] = useState(false);

  const GetPage = async (params,lang) => {
    try {
      setMetaLoaded(false)
      const res = await getRequest(params, `/page`,lang);
      if (!res) {
        throw new Error(res?.msg);
      }
      // console.log(res);
      setDataPages(res[0]);
      setMetaLoaded(true);
    } catch (error) {
      // notify(error.message, "error");
      setMetaLoaded(true)
    }
  };

  // eslint-disable-next-line react/react-in-jsx-scope
  return (
    <PagesContext.Provider value={{ GetPage, dataPages,metaLoaded,setMetaLoaded  }}>
      {children}
    </PagesContext.Provider>
  );
};
