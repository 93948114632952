import React, { useContext, useEffect, useState } from "react";
import Logo from "shared/Logo/Logo";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { useTranslation } from "react-i18next";
import SearchDropdown from "./searchModal/SearchDropDown";
import SearchDropdownMobile from "./SearchDropdown";
import CartDropdown from "./CartDropdown";
import LangDropdown from "./LangDropdown";
import MenuBar from "shared/MenuBar/MenuBar";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSubCategory } from "components/rtk/slices/categoriesSlice";
import { AuthContext } from "components/contextApi/AuthContext";
import AvatarDropdown from "./AvatarDropdown";
import LoginDropDown from "./LoginDropDown/LoginDropDown";

const MainNav1 = ({ isTop }) => {
  const { isAuth } = useContext(AuthContext);
  // const { userInfo } = useContext(AuthContext);

  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const dispatch = useDispatch();
  const subCategories = useSelector((state) => state.categories.subCategories);
  useEffect(() => {

    if (subCategories && lang) {
      dispatch(getSubCategory({ lang: lang }));
    }
  }, [lang]);

  useEffect(() => {
    if (localStorage.theme === "dark") {
      toDark();
      setIsDarkMode(true);
    } else {
      toLight();
      setIsDarkMode(false);
    }
  }, []);

  const toDark = () => {
    setIsDarkMode(true);
    const root = document.querySelector("html");
    if (!root) return;
    !root.classList.contains("dark") && root.classList.add("dark");
    localStorage.theme = "dark";
  };

  const toLight = () => {
    setIsDarkMode(false);
    const root = document.querySelector("html");
    if (!root) return;
    root.classList.remove("dark");
    localStorage.theme = "light";
  };

  function _toogleDarkMode() {
    if (localStorage.theme === "light") {
      toDark();
    } else {
      toLight();
    }
  }
  return (
    <div
      className={`nc-MainNav1 relative z-10 ${isTop ? "onTop " : "notOnTop backdrop-filter"
        }`}
    >
      <div className="mainHead dark:bg-transparent bg-white">
        <div className="md:container py-1 md:py-2 relative flex items-center lg:gap-3">
          <div className="flex-[0] md:flex-[1] justify-start flex flex-grow md:justify-start items-center gap-2 md:gap-2">
            <div className="flex items-center xl:hidden">
              <div className="px-1" />
              <MenuBar />
            </div>
            <div className=" lg:flex flex items-center">
              <Logo className="flex-shrink-0" />
            </div>

            <div className="flex-[1] hidden lg:flex justify-center mx-2">
              <SearchDropdown />
            </div>
          </div>
          <div className="flex-shrink-0 flex items-center justify-between lg:gap-5 gap-0 text-neutral-700 dark:text-neutral-100">
            <div className="language hidden md:flex flex-col justify-start border-l-2 border-gray-300 pl-5 ">
              <span className="text-black dark:text-neutral-200 text-xs">
                {lang === "ar" ? "اللغة" : "Language"}
              </span>
              <LangDropdown />
            </div>
            {/* <ButtonPrimary href={`/${lang}/Signup`}>
                {t("Signup")}
                </ButtonPrimary> */}
            {
              !isAuth ? (
                <div className="language hidden md:flex flex-col justify-start border-l-2 border-gray-300 sm:px-5 lg:px-0 pl-0 lg:pl-5 ">
                  <span className="text-black dark:text-neutral-200 text-xs">
                    {lang === "ar"
                      ? "تسجيل الدخول / حساب جديد"
                      : "Create Account / login"}
                  </span>
                  <LoginDropDown />
                </div>
              ) : (
                // <Link to={`/${lang}/Signup`} className="hidden md:block">
                //   <div className="SignUp flex flex-col justify-start border-l-2 border-slate-300 sm:px-5 pl-0 lg:pl-5">
                //     <span className="text-black dark:text-neutral-200 text-xs">
                //       {lang === "ar"
                //         ? "تسجيل الدخول / حساب جديد"
                //         : "Create Account / login"}
                //     </span>
                //     <span className=" text-[12px] text-black dark:text-neutral-200 font-semibold hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                //       {lang === "ar" ? "حسابي" : "Account"}
                //     </span>
                //   </div>
                // </Link>
                <AvatarDropdown />
              )
              // <Link to={`/${lang}/account`} className="hidden md:block">
              //   <div className="SignUp hidden xl:flex flex-col justify-start border-l-2 border-slate-300 pl-5">
              //     <span className="text-slate-300">
              //       {lang === "ar"
              //         ? "تسجيل الدخول / حساب جديد"
              //         : "Create Account / login"}
              //     </span>
              //     <span className="py-1 text-lg text-slate-100 dark:text-neutral-200 font-semibold hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              //       {lang === "ar" ? "حسابي" : "Account"}
              //     </span>
              //     {/* <ButtonPrimary href={`/${lang}/Signup`}>
              //     {t("Signup")}
              //     </ButtonPrimary> */}
              //   </div>
              // </Link>
            }
          </div>
          <div className="buttoncartdark flex justify-center items-center gap-3 md:gap-1 px-2">
            <div className="flex-[0] flext lg:hidden justify-center">
              <SearchDropdownMobile />
            </div>
            <div className="cartDropDown">
              <CartDropdown />
            </div>

            <div className="switch hidden items-center xl:flex">
              <SwitchDarkMode
                _toogleDarkMode={_toogleDarkMode}
                toDark={toDark}
                toLight={toLight}
                isDarkMode={isDarkMode}
                setIsDarkMode={setIsDarkMode}
              />
              <div className="" />
            </div>
          </div>
        </div>
      </div>
      {
        <div
          className="bg-[#D9E8FB] text-slate-700 py-1 rounded relative"
          role="alert"
        >
          <div
            className={`container flex justify-between`}
            style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
          >
            {/* <span className="absolute top-0 bottom-0 right-20 px-4 py-3"> */}
            <div
              className={`flex items-center justify-start gap-2 md:gap-3 flex-wrap`}
            >
              {subCategories?.map((item) => (
                <Link
                  key={item?.sub_categories?.name}
                  to={`/${lang}/${item?.sub_categories?.link}`}
                  className="text-[10px] md:text-[14px] font-semibold"
                >
                  {item?.sub_categories?.name}
                </Link>
              ))}
            </div>
            <strong className="font-bold">
              <Link
                to={`/${lang}/shop`}
                className="text-[11px] md:text-[14px] font-semibold"
              >
                {t("BestOffers")}
              </Link>
            </strong>
            {/* <svg className="fill-current h-6 w-6 text-orange-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg> */}
            {/* </span> */}
          </div>
        </div>
      }
    </div>
  );
};

export default MainNav1;

// import React, { FC, useContext, useEffect, useState } from "react";
// import Logo from "shared/Logo/Logo";
// import Navigation from "shared/Navigation/Navigation";
// // import SearchDropdown from "./SearchDropdown";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import MenuBar from "shared/MenuBar/MenuBar";
// import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
// import { LanguageContext } from "components/contextApi/ChangeLanguage";
// import { useTranslation } from "react-i18next";
// import SearchDropdown from "./SearchDropdown";
// import CartDropdown from "./CartDropdown";
// import LangDropdown from "./LangDropdown";

// export interface MainNav1Props {
//   isTop?: boolean;
// }

// const MainNav1: FC<MainNav1Props> = ({ isTop }) => {
//   const { lang }: any = useContext(LanguageContext);
//   const { t }: any = useTranslation();
//   const [isDarkMode, setIsDarkMode] = useState(false);

//   useEffect(() => {
//     if (localStorage.theme === "dark") {
//       toDark();
//       setIsDarkMode(true);
//     } else {
//       toLight();
//       setIsDarkMode(false);
//     }
//   }, []);

//   const toDark = () => {
//     setIsDarkMode(true);
//     const root = document.querySelector("html");
//     if (!root) return;
//     !root.classList.contains("dark") && root.classList.add("dark");
//     localStorage.theme = "dark";
//   };

//   const toLight = () => {
//     setIsDarkMode(false);
//     const root = document.querySelector("html");
//     if (!root) return;
//     root.classList.remove("dark");
//     localStorage.theme = "light";
//   };

//   function _toogleDarkMode() {
//     if (localStorage.theme === "light") {
//       toDark();
//     } else {
//       toLight();
//     }
//   }
//   return (
//     <div
//       className={`nc-MainNav1 relative z-10 ${
//         isTop ? "onTop " : "notOnTop backdrop-filter"
//       }`}
//     >
//       <div className="container py-5 relative flex justify-between items-center ">
//         <div className="flex justify-start flex-grow items-center 2xl:space-x-14">
//           <div className="lg:flex flex items-center">
//             <Logo className="flex-shrink-0" />
//           </div>

//           <div className="flex-[2] hidden lg:flex justify-center mx-4">
//             <Navigation />
//           </div>
//         </div>
//         <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100">
//         <SearchDropdown />

//           <div
//             className="hidden items-center xl:flex"
//             // onClick={()=>_toogleDarkMode()}
//           >

//             <ButtonPrimary href={`/${lang}/Signup`}>
//               {t("Signup")}
//             </ButtonPrimary>
//             <SwitchDarkMode
//               _toogleDarkMode={_toogleDarkMode}
//               toDark={toDark}
//               toLight={toLight}
//               isDarkMode={isDarkMode}
//               setIsDarkMode={setIsDarkMode}
//             />
//             <div className="" />
//           </div>
//           {/* <SearchDropdown /> */}

//           <LangDropdown />
//           <CartDropdown />
//           <div className="flex items-center xl:hidden">
//             {/* <SwitchDarkMode /> */}

//             <div className="px-1" />
//             <MenuBar />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MainNav1;
