import React, { useContext, useEffect } from "react";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { useDispatch, useSelector } from "react-redux";
import { getBrand } from "components/rtk/slices/BrandSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./ShopCat.css";
import { Link } from "react-router-dom";

const ShoPByCategory = () => {
  const dispatch = useDispatch();
  const Brands = useSelector((state) => state.Brands.Brands);
  const { lang } = useContext(LanguageContext);

  useEffect(() => {
    dispatch(getBrand({ lang: lang }));
  }, [dispatch, lang]);

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={5}
      loop={true}
      speed={1000}
      modules={[Navigation]}
      className="w-full shopCat h-32"
    >
      {Brands?.map((item) => (
        <SwiperSlide
          key={item?.id}
          className="flex items-center justify-center"
        >
          <Link
            to={`/${lang}/brand/${item.name_en}`}
            className="itemImg flex-shrink-0 hover:scale-110 transition-all duration-300 flex items-center justify-center"
          >
            <img
              src={`https://sellxa.com/backend/${item?.imag}`}
              alt={item?.name_en}
              className="w-[60px] h-[60px] md:h-[100px] md:w-[100px] rounded-full object-contain"
              width={"60"}
              height={"60"}
            />
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ShoPByCategory;
