import NcInputNumber from "components/NcInputNumber";
import Prices from "components/Prices";
import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import PaymentMethod from "./PaymentMethod.js";
import ShippingAddress from "./ShippingAddress.js";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "components/rtk/store";
import { cartActions } from "components/rtk/slices/CartSlice";
import { Order } from "components/contextApi/DataOrderContext";
import toast from "react-hot-toast";
import { LanguageContext } from "components/contextApi/ChangeLanguage.js";
import { useTranslation } from "react-i18next";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import {
  checkCoupon,
  removeCoupon,
} from "components/rtk/slices/couponApiSlice";
import { PagesContext } from "components/contextApi/MetaContext.js";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CartView from "./CartView";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import cartEmpty from "images/cartEmpty.png";
import { usePriceRange } from "components/contextApi/PriceRangeProvider.js";

// import RenderProductMobile from "./renderMoblieProducts.js";


const CheckoutPage = () => {


  useEffect(() => {
    // Function to send the API request
    const sendApiRequest = async () => {
      try {
        await fetch(
          'https://sellxa.com/backend/api/log-tracking/?event_name=checkout',
          {
            method: 'GET',
            mode: 'cors', // This enables CORS
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*', // Allow all origins
            },
            credentials: 'include', // Include credentials if needed
          }
        );
      } catch (error) {
        console.error('Error sending API request:', error);
      }
    };

    // Call the function when the component mounts
    sendApiRequest();
  }, []);

  const [loading, setLoading] = useState(true);
  const [last, setLast] = useState([]);
  const { getDatafiltered } = usePriceRange();









  const { lang } = useContext(LanguageContext);
  const { t } = useTranslation();
  const y = useRef(true);


  const cartItems = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch();

  const { address, suite, countryId, cityId } = useContext(Order);
  const [shipping, setShipping] = useState(0);
  const [childConfirmClick, setChildConfirmClick] = useState<(() => void) | null>(null);
  // Example onClick handler
  const handleParentClick = () => {
    if (childConfirmClick) {
      childConfirmClick();
    }
  };

  useEffect(() => {
    const getLast = async () => {
      try {
        const res = await getDatafiltered({ paginate: 8 }, lang);
        setLast(res.data);
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        console.log(error.message);
        // notify(error.message, "error");
      }
    };
    if (y.current === true) {
      getLast();
      y.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);


  const [tabActive, setTabActive] = useState<
    "ContactInfo" | "ShippingAddress" | "PaymentMethod"
  >("ShippingAddress");

  const handleScrollToEl = (id: string) => {
    const element = document.getElementById(id);
    setTimeout(() => {
      element?.scrollIntoView({ behavior: "smooth" });
    }, 80);
  };

  const renderProduct = (item: any, index: number) => {
    const { totalPrice: price, product_id } = item;

    let optionName = "";
    item?.optionsSelectd?.forEach((item: any) => {
      optionName += "," + item.name + ": " + item.value || "";
    });
    const name = item?.data?.name;

    return (
      <div
        key={index}
        className="relative flex py-7 first:pt-0 last:pb-0"
        style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
      >
        <div className="relative h-24  flex-shrink-0 overflow-hidden rounded-xl">
          <img
            src={`https://sellxa.com/backend/${item?.image[0]?.url}`}
            alt={name}
            className="h-24 w-24 object-contain object-center"
          />
          <Link
            to={`/${lang}/${item?.data?.main_category?.name}/${item?.data?.link}`}
            className="absolute inset-0"
          ></Link>
        </div>

        <div
          className={`${lang === "en" ? "ml-3 sm:ml-6" : "mx-3 sm:mx-6"
            } flex flex-1 flex-col`}
        >
          <div>
            <div className="flex justify-between ">
              <div className="flex-[1.5] ">
                <h3 className="font-bold truncate-2-lines text-[#033341] text-wrap ">
                  <Link
                    to={`/${lang}/${item?.data?.main_category?.name}/${item?.data?.link}`}
                  >
                    {name}
                  </Link>
                </h3>

                <div className="mt-3 flex justify-between w-full sm:hidden relative">
                  <div className=" sm:block text-center relative">
                    <NcInputNumber className="relative z-10" item={item} />
                  </div>
                  <Prices
                    contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                    price={price}
                  />
                </div>
              </div>

              <div className="hidden  sm:flex justify-end">
                {/* <Prices price={price} className="mt-0.5 sm:mt-0 text-xs font-medium text-[#033341] " /> */}
                <span className="text-sm font-medium !leading-none mt-1" style={{ direction: `${lang === 'ar' ? 'rtl' : 'ltr'}` }}>
                  {price.toFixed(0)}
                </span>
                <span className="text-sm font-medium !leading-none mt-1" style={{ direction: `${lang === 'ar' ? 'rtl' : 'ltr'}` }}>
                  {t("EGP")}
                </span>
              </div>
            </div>
          </div>

          <div className="flex mt-auto pt-4 items-end justify-between text-sm">
            <div className="hidden sm:block text-center relative">
              <NcInputNumber className="relative z-10" item={item} />
            </div>

            <a
              href="##"
              className="relative z-10 flex items-center justify-center p-2 text-red-600 hover:text-red-800 text-sm"
              onClick={() => dispatch(cartActions.deleteItem(item))}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-5 h-5"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>

          </div>
        </div>
      </div>
    );
  };
  const renderProductMobile = (item: any, index: number) => {
    const { totalPrice } = item;
    const name = item?.data?.name ?? "";
    let optionName = "";
    item?.optionsSelectd?.forEach((item: any) => {
      optionName += "," + (item.name ?? "") + ": " + item.value || "";
    });

    return (
      <div
        key={index}
        className="bg-white dark:bg-slate-800 pt-3 p-1 flex items-start border-b border-slate-300 dark:border-slate-700"
      >
        {/* Product Image */}
        <div className="relative h-[114px] w-24  flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={`https://sellxa.com/backend/${item?.image[0]?.url}`}
            alt="productImg"
            className="h-full w-full object-contain object-center"
          />
          <Link
            to={`/${lang}/${item?.data?.main_category?.name}/${item?.data?.link}`}
            className="absolute inset-0"
          ></Link>
        </div>

        {/* Product Details */}
        <div className="flex-1 mr-2">
          <p style={{ fontSize: "11px" }} className={` font-bold text-xs ${lang === "en" ? "text-left" : "text-right"}`}>
            <Link to={`/${lang}/${item?.data?.main_category?.name}/${item?.data?.link}`}>
              {name + optionName}
            </Link>
          </p>

          {/* Quantity Control */}
          <div className="flex items-center mt-2 justify-between">

            <div className="mt-2">
              <NcInputNumber item={item} />
            </div>

            {/* Price Info */}
            <div className="flex justify-between mt-2">
              <span className="text-lg font-bold" style={{ marginLeft: "-35px" }}>
                {totalPrice.toFixed(2).replace(/\.00$/, "")} {lang === "en" ? "EGP" : "ج.م"}
              </span>
            </div>
          </div>
        </div>

        {/* Remove Button */}
        <button
          onClick={() => dispatch(cartActions.deleteItem(item))}
          className="-mt-[10px] text-red-600 hover:text-red-800 flex items-center justify-center p-2"

        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-5 h-5"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    );
  };



  const renderLeft = () => {
    return (
      <div className="space-y-8">
        <div id="ShippingAddress" className="scroll-mt-24">
          <ShippingAddress
            code={code}
            shipping={shipping}
            setChildConfirmClick={setChildConfirmClick}

            isActive={tabActive === "ShippingAddress"}
            setShipping={setShipping}
            onOpenActive={() => {
              setTabActive("ShippingAddress");
              handleScrollToEl("ShippingAddress");
            }}
            onCloseActive={() => {
              if (!address || !suite || !cityId) {
                toast.error(t("CompleteYourInformation"));
              } else {
                setTabActive("PaymentMethod");
                handleScrollToEl("PaymentMethod");
              }
            }}
          />
        </div>


      </div>
    );
  };

  const [code, setCode] = useState<any>("");
  const { data, status, error } = useSelector((state: any) => state.coupon);

  useEffect(() => {
    if (data && data.discount_code) {
      //@ts-ignore
      dispatch(checkCoupon({ code: data.discount_code }));

    }
  }, [data]);
  useEffect(() => {
    if (data) {
      setCode(data?.data?.code);
    } else {
      setCode(data?.discount_code);
    }
  }, [data]);

  const handleApply = () => {
    if (code) {
      //@ts-ignore
      dispatch(checkCoupon({ code }));
    }
  };
  const handleDelete = () => {
    if (data) {
      //@ts-ignore
      dispatch(removeCoupon());
      setCode("");
    }
  };


  const { GetPage, dataPages } = useContext(PagesContext);
  useEffect(() => {
    GetPage({ page_id: 11 }, lang);
  }, []);
  const description = dataPages?.description;
  return (
    <div className="nc-CheckoutPage">
      <Helmet>
        <title>{dataPages?.meta_title ?? ""}</title>
        <meta name="description" content={description} />
        <link rel="Canonical" href="/checkout" />
      </Helmet>
      <div className={`block container w-full mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400 ${lang === "en" ? "text-left" : "text-right"}`}>
        <Link to={`/${lang}`} className="">
          {t("Homepage")}
        </Link>
        <span className="text-xs mx-1 sm:mx-1.5">/</span>
        <span>{t("Cart")}</span>
      </div>

      {!cartItems || cartItems?.cartItems?.length === 0 ? (
        !loading && last.length > 0 ? (
          <main className="container lg:pb-28">
            <div className="emptyCart flex flex-col justify-center items-center pb-4">
              <img
                src={cartEmpty}
                alt="cart is empty"
                className="max-w-[300px]"
              />
              <div className="emptyCart_desc text-center flex flex-col justify-center items-center">
                <h2 className="text-3xl font-bold mb-4">
                  {t("EmptyCartMessage")}
                </h2>
                <p className="mb-4 text-xl w-[100%] md:w-[80%]">
                  {t("FillCartOpportunity")}
                </p>
              </div>
              <ButtonPrimary
                className="font-semibold rounded-md"
                href={`/${lang}/shop`}
              >
                {t("ShopNow")}
              </ButtonPrimary>
            </div>
            <SectionSliderProductCard
              heading={t("uCanBuy")}
              data={last?.filter((_, i) => i < 8)}
              subHeading={""}
            />
          </main>
        ) : (
          // <h3>loading...</h3>
          <main
            className="container py-16 lg:pb-28 lg:pt-20"
            style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
          >
            <div className="flex items-center justify-between gap-3">
              <div
                role="status"
                className="w-full p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-700"
              >
                <div className="flex items-center justify-center h-48 mb-4 bg-gray-300 rounded dark:bg-gray-700">
                  <svg
                    className="w-10 h-10 text-gray-200 dark:text-gray-600"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 16 20"
                  >
                    <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z" />
                    <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                  </svg>
                </div>
                <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                <div className="flex items-center mt-4">
                  <div>
                    <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
                    <div className="w-48 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                </div>
                <span className="sr-only">Loading...</span>
              </div>
              {/* Repeat the above div three more times as needed */}
            </div>
          </main>
        )
      ) : (
        <main
          className="container py-8 lg:pb-10 lg:pt-10 "
          style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
        >
          <div className="mb-8">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">
              {t("Checkout")}
            </h2>
            <div className="hidden max-sm:block">
              <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold  text-center mt-5">{t('تفاصيل الطلب')}</h2>
            </div>

            <div className="hidden md:flex w-full mx-auto justify-cnter gap-20 mt-3  text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
              <div className="mx-auto flex items-center gap-5 mt-8">

                <Link to={"/cart"}>

                  <div className={`${lang === "ar" ? "pl-12" : "pr-12"} flex items-center justify-start w-[280px] gap-2 border-b-2 border-green-500 pb-6`}>
                    <div className="w-10 h-10 flex items-center justify-center bg-green-500 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="w-6 h-6 text-white"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                      </svg>
                    </div>
                    <span className="text-lg font-medium text-green-500">{t("shoppingCart")}</span>
                  </div>
                </Link>

                <div className={`${lang === "ar" ? "pl-12" : "pr-12"} flex items-center justify-start gap-2 w-[280px] border-b-2 border-[#033341] pb-6 `}>
                  <div className="w-10 h-10 flex items-center justify-center bg-[#033341] rounded-full">
                    <span className="text-white text-lg">2</span>
                  </div>
                  <span className="text-lg text-[#033341] font-medium">{t("order_details")}</span>
                </div>
                <div className="flex w-[280px] items-center justify-start gap-2 pb-6 ">
                  <div className="w-10 h-10 flex items-center justify-center bg-gray-300 rounded-full">
                    <span className="text-white text-lg">3</span>
                  </div>
                  <span className="text-lg text-gray-300 font-medium">{t("done")}</span>
                </div>
              </div>
            </div>

          </div>

          <div className="flex flex-col gap-8 justify-center lg:flex-row">
            <div className=" w-full lg:w-[50%]">{renderLeft()}</div>

            <>

              <CartView className="hidden lg:block" cartItems={cartItems} code={code} data={data} shipping={shipping} />
            </>


          </div>

          {/* <div className="hidden max-sm:block mt-8">
          <ButtonPrimary
          onClick={handleParentClick}
          
          className="w-full bg-[#FC5E02]"
          >
          {t("confirm")}
          </ButtonPrimary>
          </div> */}
        </main>
      )}
    </div>
  );
};

export default CheckoutPage;
