// GenericCheckboxGroup.js

import { useContext } from "react";
import Checkbox from "shared/Checkbox/Checkbox";
import { LanguageContext } from "./contextApi/ChangeLanguage";

const GenericCheckboxGroup = ({ options, onChange }) => {
    const list1 = options.filter((_, i) => i < options.length / 2);
    const list2 = options.filter((_, i) => i >= options.length / 2);
const {lang} = useContext(LanguageContext);
  return (
    <div className="grid grid-cols-2 gap-x-10 sm:gap-x-8 gap-12">
      <div className={`flex flex-col space-y-5 ${lang === 'ar' ? 'text-right' : "text-left"}`}>
            {list1.map((item,index) => (
                <Checkbox
                    key={item?.id ?? index}
                    name={item.name ? item.name : item.size ? item.size : item }
                    subLabel={item.description}
                    label={item.name ? item.name : item.size ? item.size : item}
                    defaultChecked={!!item.defaultChecked}
                    onChange={(checked) => onChange(checked, item.name ?? item,item.id ?? item.size )}
                />
                ))}
        </div>
        <div className={`flex flex-col space-y-5 ${lang === 'ar' ? 'text-right' : "text-left"}`}>
        {list2.map((item,index) => (
          <Checkbox
            key={item?.id ?? index}
            name={item.name ? item.name : item.size? item.size : item}
            subLabel={item.description}
            label={item.name ? item.name : item.size? item.size : item}
            defaultChecked={!!item.defaultChecked}
            onChange={(checked) => onChange(checked, item.name ?? item ,item.id)}
          />
        ))}
        </div>
    </div>
  );
};

export default GenericCheckboxGroup;
