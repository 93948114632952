import ButtonPrimary from "shared/Button/ButtonPrimary";
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";
import { useTranslation } from "react-i18next";
import { PagesContext } from "components/contextApi/MetaContext";
import { LanguageContext } from "components/contextApi/ChangeLanguage";

const Page404: React.FC = () => {
  const { t } = useTranslation();
  const { lang } = useContext(LanguageContext);
  const { GetPage, dataPages } = useContext(PagesContext);
  useEffect(() => {
    GetPage({ page_id: 14 }, lang);
  }, []);
  const description = dataPages?.description;
  return (
    <div className="nc-Page404">
      <Helmet>
        <title>{dataPages?.meta_title ?? ""}</title>
        <meta name="description" content={description} />
      </Helmet>

      <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
        {/* HEADER */}
        <header className="text-center max-w-2xl mx-auto space-y-2">
          <NcImage src={I404Png} />
          <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
            {t("PageNotFound")}
          </span>
          <div className="pt-8">
            <ButtonPrimary href="/">{t("ReturnHomePage")}</ButtonPrimary>
          </div>
        </header>
      </div>
    </div>
  );
};
export default Page404;
