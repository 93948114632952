import React, { useContext, useEffect, useRef } from "react";
// import facebookSvg from "images/Facebook.svg";
// import twitterSvg from "images/Twitter.svg";
// import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet-async";
import Input from "shared/Input/Input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { AuthContext } from "components/contextApi/AuthContext";
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LanguageContext } from "components/contextApi/ChangeLanguage";
import { useTranslation } from "react-i18next";
import { PagesContext } from "components/contextApi/MetaContext";

// const loginSocials = [
//   {
//     name: "Continue with Facebook",
//     href: "#",
//     icon: facebookSvg,
//   },
//   {
//     name: "Continue with Twitter",
//     href: "#",
//     icon: twitterSvg,
//   },
//   {
//     name: "Continue with Google",
//     href: "#",
//     icon: googleSvg,
//   },
// ];

const PageLogin = ({ className = "" }) => {
  const { login } = useContext(AuthContext);

  const navigate = useNavigate();
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const email = emailRef.current?.value;
  //   const password = passwordRef.current?.value;
  //   // const body = {login:email,password}
  //   // console.log(body);

  //   try {
  //     let dataSendApi = new FormData();
  //     dataSendApi.append("login", email);
  //     dataSendApi.append("password", password);
  //     await login(dataSendApi);
  //     // console.log("Response from login:", login(dataSendApi));
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };
  const { t } = useTranslation();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required(t("EmailRequired")),

    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required(t("PasswordRequired")),
  });

  const initialValues = {
    email: "",
    password: "",
  };
  const location = useLocation();

  const onSubmit = (values, { setSubmitting }) => {
    const response = async () => {
      try {
        const res = await login({
          login: values.email,
          password: values.password,
        });
        if (res.data) {
          const fromPath = location.state?.from || `/${lang}/`;
          if (fromPath === `/${lang}/checkout`) {
            navigate(fromPath);
          } else {
            navigate(-1); // يروح للصفحة اللي كان فيها قبل صفحة اللوجين
          }
          // navigate('/checkout', { replace: true });
        }
      } catch (error) {
        // toast.error(error.message);
      }
    };
    response();
    // You can handle form submission here (e.g., call an API)
    setSubmitting(false);
  };
  const { lang } = useContext(LanguageContext);
  const { GetPage, dataPages } = useContext(PagesContext);
  useEffect(() => {
    GetPage({ page_id: 12 }, lang);
  }, []);
  const description = dataPages?.meta_description;
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>{dataPages?.meta_title}</title>
        <meta name="description" content={description} />
        <link rel="Canonical" href={`/${lang}/login`} />
      </Helmet>
      <div
        className="container mb-24 lg:mb-32"
        style={{ direction: `${lang === "ar" ? "rtl" : "ltr"}` }}
      >
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t("login")}
        </h2>
        <div className="max-w-xs md:max-w-md mx-auto space-y-6">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}
          {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
          {/* FORM */}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="grid grid-cols-1 gap-6">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    {t("EmailAddress")}
                  </span>
                  <Field
                    as={Input}
                    type="email"
                    id="email"
                    name="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    // ref={emailRef}
                  />
                </label>
                <ErrorMessage name="email" component="div" />
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    {t("Password")}
                    <Link
                      to={`/${lang}/forgot-pass`}
                      className="text-sm text-green-600"
                    >
                      {t("Forgot")}
                    </Link>
                  </span>
                  <Field
                    as={Input}
                    className="mt-1"
                    type="password"
                    id="password"
                    name="password"
                    // ref={passwordRef}
                  />
                </label>
                <ErrorMessage name="password" component="div" />

                <ButtonPrimary type="submit" disabled={isSubmitting}>
                  {t("Continue")}
                </ButtonPrimary>
              </Form>
            )}
          </Formik>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            {t("newUser")} {` `}
            <Link className="text-green-600" to={`/${lang}/signup`}>
              {t("Createaccount")}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
