import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchMain_Categories = createAsyncThunk("mainCategoriesSlice/fetchMain_Categories" , async()=>{
    const res = await fetch("https://sellxa.com/backend/api/categories/main");
    const data = await res.json();
    // console.log(data);
    return data;
});

const initialState = 
{
    data : [],
    loading: true
};

const mainCategoriesSlice =  createSlice({
    initialState,
    name: "productSlice",
    reducers:{},

    extraReducers: (builder)=>{
        
        builder.addCase(fetchMain_Categories.pending, (state,action)=>{
            state.loading = true;
        })

        builder.addCase(fetchMain_Categories.fulfilled, (state,action)=>{
            state.loading = false;
            state.data = action.payload;
        })

        builder.addCase(fetchMain_Categories.rejected, (state,action)=>{
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        })
    }
})

// eslint-disable-next-line no-empty-pattern
export const {} = mainCategoriesSlice.actions;
export default mainCategoriesSlice.reducer;