import {
  NoSymbolIcon,
  ClockIcon,
  SparklesIcon,
} from "@heroicons/react/24/outline";
import { Product } from "data/data";
import React, { FC } from "react";
import IconDiscount from "./IconDiscount";
import { useTranslation } from "react-i18next";

interface Props {
  status?: any;
  className?: string;
}

const ProductStatus: FC<Props> = ({
  status,
  // bg-white dark:bg-slate-900
  // text-slate-700 dark:text-slate-300
  className = "absolute top-3 right-3 px-2.5 py-1.5 text-xs  ",
}) => {
  const { t } = useTranslation();

  const renderStatus = () => {
    if (!status?.data) {
      return null;
    }
    const CLASSES = `nc-shadow-lg rounded-lg flex items-center justify-center ${className}`;
    if (status?.data?.is_available === 1 && status?.stock?.quantity > 0) {
      return (
        <div className={`${CLASSES} bg-green-500 text-white`} >
          {/* <SparklesIcon className="w-3.5 h-3.5" /> */}
          <span className="ml-1 leading-none font-bold text-white">{t("InStock")}</span>
        </div>
      );
    } else if (
      status?.data?.is_available === 0 ||
      status?.stock?.quantity === 0
    ) {
      return (
        <div style={{ marginTop: "-5px" }} className={`${CLASSES} bg-red-500 text-white`}>
          {/* <NoSymbolIcon className="w-3.5 h-3.5" /> */}
          <span className="ml-1 leading-none">{t("OutOfStock")}</span>
        </div>
      );
    }
    // if (status === "Sold Out") {
    //   return (
    //     <div className={CLASSES}>
    //       <NoSymbolIcon className="w-3.5 h-3.5" />
    //       <span className="ml-1 leading-none">{status}</span>
    //     </div>
    //   );
    // }
    // if (status === "limited edition") {
    //   return (
    //     <div className={CLASSES}>
    //       <ClockIcon className="w-3.5 h-3.5" />
    //       <span className="ml-1 leading-none">{status}</span>
    //     </div>
    //   );
    // }
    return null;
  };

  return renderStatus();
};

export default ProductStatus;
